import React, { useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import SelectionBar from '../components/SelectionBar'
import { sendRequest } from '../hooks/http-hook'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR, SET_DASHBOARD_STATS } from '../container/home/types'

const DashboardOmologatore = ({
  infoUtente,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
  dashboardStats,
}) => {
  const { codiceUtente } = infoUtente

  const dispatch = useDispatch()

  document.title = 'PGS Milano - Home'

  const fetchStats = useCallback(
    async (url, errorText, key, onlyLength) => {
      fetchAndDispatch({
        url,
        errorText: `le statistiche ${errorText}`,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
        dispatchFunction: x =>
          dispatch({
            type: SET_DASHBOARD_STATS,
            payload: { [key]: onlyLength ? x?.length : x },
          }),
      })
    },
    [codiceUtente, dispatch]
  )

  // fetch all stats
  useEffect(() => {
    if (codiceUtente && selectedCampionato) {
      fetchStats(
        `calendari/stats/campionato/${selectedCampionato}`,
        'sulle partite',
        'partiteStats'
      )
    }
  }, [codiceUtente, fetchStats, selectedCampionato])

  return (
    <Grid
      container
      item
      xs={12}
      marginY={2}
      rowSpacing={5}
      alignItems='stretch'
    >
      <SelectionBar
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      <p style={{ margin: 20, width: '100%' }}>
        Benvenuto nel profilo di omologazione di PGS Milano.
        <br />
        Dalla pagina <b>Calendari</b> puoi omologare e correggere i risultati
        delle gare.
        <br />
        Buon lavoro!
      </p>
      <GridCard
        title='Calendari'
        subtitle1={`${dashboardStats?.partiteStats?.all || 0} ${
          dashboardStats?.partiteStats?.all === 1 ? 'partita' : 'partite'
        }`}
        subtitle2={`${
          selectedCampionatoCalendariPubblicati
            ? `${dashboardStats?.partiteStats?.withHomologation || 0} ${
                dashboardStats?.partiteStats?.withHomologation === 1
                  ? 'omologata'
                  : 'omologate'
              }`
            : 'Calendari non ancora pubblicati'
        }`}
        linkTo='/calendari'
        isError={
          !selectedCampionatoCalendariPubblicati ||
          dashboardStats?.partiteStats?.withHomologation
        }
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
  dashboardStats: state.home.dashboardStats,
})

const ConnectedDashboardOmologatore =
  connect(mapStateToProps)(DashboardOmologatore)

export default ConnectedDashboardOmologatore
