import { sendRequest } from '../hooks/http-hook'
import { downloadFile } from './download-functions'
import { checkBackendR } from './utils-functions'

const runAlgorithm = async params => {
  const {
    algorithm,
    triggerDownload,
    exactlyOne,
    type,
    selectedStagione,
    selectedCampionato,
    availableCategorie,
    selectedCategoria,
    availableGironi,
    selectedGirone,
    selectedGroup,
    selectedTeam,
    selectedTeams,
    selectedNumeriGara,
    selectedRimborso,
    selectedRendiconto,
    codiceUtente,
    idArbitro,
  } = params

  let { setFeedback, setBackendR } = params

  if (!setFeedback) {
    setFeedback = (phrase, error = true) =>
      error ? console.error(phrase) : console.log(phrase)
  }

  if (!setBackendR) {
    setBackendR = () => {}
  }

  if (!algorithm) {
    setFeedback('Algoritmo non definito!')
    return
  }

  if (algorithm === '01_GenerateTable') {
    if (!selectedCampionato) {
      setFeedback('Campionato non definito!')
      return
    }
  }

  if (algorithm === '07_GenerateExcelGironi') {
    if (!selectedCampionato) {
      setFeedback('Campionato non definito!')
      return
    }
    if (!selectedCategoria) {
      setFeedback('Categoria non definita!')
      return
    }
  }

  if (algorithm === '08_GenerateExcelCalendari') {
    if (!selectedCampionato) {
      setFeedback('Campionato non definito!')
      return
    }
    if (!selectedCategoria) {
      setFeedback('Categoria non definita!')
      return
    }
    if (!selectedGirone) {
      setFeedback('Girone non definito!')
      return
    }
  }

  if (algorithm === '09_GenerateExcelProvvedimenti') {
    if (!selectedCampionato) {
      setFeedback('Campionato non definito!')
      return
    }
    if (!selectedGroup) {
      setFeedback('Tipologia non definita!')
      return
    }
  }

  if (algorithm === '12_GenerateExcelDesignazioni') {
    if (!selectedCampionato) {
      setFeedback('Campionato non definito!')
      return
    }
  }

  if (algorithm === '13_GenerateExcelRimborso') {
    if (!selectedRimborso) {
      setFeedback('Rimborso non definito!')
      return
    }
  }

  if (algorithm === '15_GenerateExcelRendiconto') {
    if (!selectedRendiconto && !selectedStagione) {
      setFeedback('Rendiconto e stagione non definiti!')
      return
    }
  }

  if (algorithm === '16_GenerateExcelAutocertificazione') {
    if (!idArbitro) {
      setFeedback('Arbitro non definito!')
      return
    }
  }

  const getRequest = async url =>
    await sendRequest(url, 'GET', null, { Authorization: codiceUtente })

  const postRequest = async (url, body) =>
    await sendRequest(url, 'POST', body, {
      'Content-Type': 'application/json',
      Authorization: codiceUtente,
    })

  // check if backendR is available
  try {
    await checkBackendR(sendRequest, codiceUtente)
  } catch (err) {
    setFeedback(err.message)
    return
  }

  try {
    if (algorithm === '01_GenerateTable') {
      setBackendR()
      const urlCategoria =
        selectedCategoria && selectedCategoria !== availableCategorie[0]
          ? `/${selectedCategoria}`
          : ''
      await getRequest(
        `algoritmi/run/${algorithm}/${selectedCampionato}${urlCategoria}`
      )
    }

    if (algorithm === '02_GenerateGironi') {
      setBackendR()
      const urlCampionato = selectedCampionato ? `/${selectedCampionato}` : ''
      await getRequest(`algoritmi/run/${algorithm}${urlCampionato}`)
    }

    if (algorithm === '03_GenerateCalendari') {
      setBackendR()
      const urlCampionato = selectedCampionato ? `/${selectedCampionato}` : ''
      await getRequest(`algoritmi/run/${algorithm}${urlCampionato}`)
    }

    if (algorithm === '07_GenerateExcelGironi') {
      if (selectedTeams) {
        await postRequest(
          `algoritmi/excel/gironi/${selectedCampionato}/${selectedCategoria}`,
          JSON.stringify({ selectedTeams })
        )
      } else {
        await getRequest(
          `algoritmi/excel/gironi/${selectedCampionato}/${selectedCategoria}`
        )
      }
    }

    if (algorithm === '08_GenerateExcelCalendari') {
      const urlGirone =
        selectedGirone === availableGironi[0] ? 'all' : selectedGirone
      const urlTeam = selectedTeam ? `/${selectedTeam}` : ''
      await getRequest(
        `algoritmi/excel/calendari/${selectedCampionato}/${selectedCategoria}/${urlGirone}${urlTeam}`
      )
    }

    if (algorithm === '09_GenerateExcelProvvedimenti') {
      await postRequest(
        `algoritmi/excel/provvedimenti/${selectedCampionato}/${selectedGroup}`,
        JSON.stringify({ numeriGara: selectedNumeriGara })
      )
    }

    if (algorithm === '10_GenerateExcelArbitri') {
      await getRequest('algoritmi/excel/arbitri')
    }

    if (algorithm === '12_GenerateExcelDesignazioni') {
      await postRequest(
        `algoritmi/excel/designazioni/${selectedCampionato}`,
        JSON.stringify({ numeriGara: selectedNumeriGara })
      )
    }

    if (algorithm === '13_GenerateExcelRimborso') {
      await getRequest(`algoritmi/excel/rimborsi/${selectedRimborso}`)
    }

    if (algorithm === '14_GenerateExcelAffiliazioni') {
      await getRequest('algoritmi/excel/affiliazioni')
    }

    if (algorithm === '15_GenerateExcelRendiconto') {
      if (selectedRendiconto) {
        await getRequest(`algoritmi/excel/rendiconti/${selectedRendiconto}`)
      } else {
        await getRequest(
          `algoritmi/excel/rendiconti/stagione/${selectedStagione}`
        )
      }
    }

    if (algorithm === '16_GenerateExcelAutocertificazione') {
      await getRequest(
        `algoritmi/excel/rimborsi/autocertificazione/arbitro/${idArbitro}`
      )
    }

    if (triggerDownload) downloadFile(algorithm, type, setFeedback, exactlyOne)
  } catch (err) {
    console.log(err)
    setFeedback(err.message)
  }
}

export { runAlgorithm }
