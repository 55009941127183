import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'

import Button from '../components/Button'
import GridCard from '../components/GridCard'
import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import {
  titlesIscrizioni as titles,
  phrasesIscrizioni as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { sortNumeriGara } from '../hooks/sort-functions'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import { SET_ISCRIZIONI, SET_ISCRIZIONE } from '../container/iscrizioni/types'
import { SET_AVAILABLE_GARE as SET_GARE_RISULTATO } from '../container/risultato/types'

const DashboardSocieta = ({
  infoUtente,
  iscrizioni,
  gareRisultato,
  dummyUpdate,
  selectedCampionatoIscrizioniAperte,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
  selectedGare,
  presso,
  indirizzo,
  citta,
  spostamenti,
  spostamento,
  selectedRisultato,
  risultato,
  arbitraggi,
  selectedGareArb,
  checkPaymentArb,
}) => {
  const { codiceUtente } = infoUtente

  const dispatch = useDispatch()

  document.title = 'PGS Milano - Home'

  // state to manage dialogs
  const [openIndirizzo, setOpenIndirizzo] = useState(false)
  const [openSpostamento, setOpenSpostamento] = useState(false)
  const [openRisultato, setOpenRisultato] = useState(false)
  const [openArbitraggio, setOpenArbitraggio] = useState(false)

  const handleClickOpenIndirizzo = () => setOpenIndirizzo(true)
  const handleClickOpenSpostamento = () => setOpenSpostamento(true)
  const handleClickOpenRisultato = () => setOpenRisultato(true)
  const handleClickOpenArbitraggio = () => setOpenArbitraggio(true)

  const handleCloseIndirizzo = () => setOpenIndirizzo(false)
  const handleCloseSpostamento = () => setOpenSpostamento(false)
  const handleCloseRisultato = () => setOpenRisultato(false)
  const handleCloseArbitraggio = () => setOpenArbitraggio(false)

  const { setFeedback } = useFeedback()

  // update address
  const updateIndirizzo = async () => {
    if (!codiceUtente) {
      setFeedback('Utente non trovato!')
      return
    }

    if (!selectedGare?.length) {
      setFeedback('Nessuna gara selezionata!')
      return
    }

    if (!indirizzo) {
      setFeedback('Indirizzo non trovato!')
      return
    }

    if (!citta) {
      setFeedback('Città non trovata!')
      return
    }

    try {
      for (let idGara of selectedGare) {
        await sendRequest(
          `calendari/${idGara}/indirizzo`,
          'PATCH',
          JSON.stringify({ presso, indirizzo, citta }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
      }
      handleCloseIndirizzo()
      setFeedback(
        phrases.phrasesIndirizzoSuccess,
        false,
        titles.titleIndirizzo,
        'calendari'
      )
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  // add richiesta spostamento
  const performSpostamento = async () => {
    if (!spostamento?.selectedGara) {
      setFeedback('Nessuna gara selezionata!')
      return
    }

    try {
      await sendRequest(
        'spostamenti/add',
        'POST',
        JSON.stringify({
          codiceUtente,
          idGara: spostamento?.selectedGara,
          tipologia: spostamento?.selectedFunction,
          nuovaData: spostamento?.newData,
          nuovaOra: spostamento?.newOra,
          checkPayment: spostamento?.checkPayment,
        }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleCloseSpostamento()
      setFeedback(
        phrases.phraseSpostamentoDomandaSuccess,
        false,
        titles.titleSpostamentoDomanda,
        'spostamenti'
      )
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  // update risultato
  const updateRisultato = async () => {
    if (!selectedRisultato) {
      setFeedback('Nessuna gara selezionata!')
      return
    }

    try {
      await sendRequest(
        `calendari/risultato/${selectedRisultato}`,
        'PATCH',
        JSON.stringify(risultato),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      handleCloseRisultato()
      setFeedback(
        phrases.phraseRisultatoSuccess,
        false,
        titles.titleRisultato,
        'calendari'
      )
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  // add richiesta arbitraggio
  const addArbitraggio = async () => {
    if (!selectedGareArb?.length) {
      setFeedback('Nessuna gara selezionata!')
      return
    }

    try {
      for (let idGara of selectedGareArb) {
        await sendRequest(
          'arbitraggi/add',
          'POST',
          JSON.stringify({ idGara, checkPayment: !!checkPaymentArb }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
      }
      handleCloseArbitraggio()
      setFeedback(
        phrases.phraseArbitraggioSuccess,
        false,
        titles.titleArbitraggio,
        'arbitraggi'
      )
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch iscrizioni
  useEffect(() => {
    const dispatchFunction = x => dispatch({ type: SET_ISCRIZIONI, payload: x })

    if (codiceUtente && selectedCampionato) {
      fetchAndDispatchFunction({
        url: `iscrizioni/campionato/${selectedCampionato}/societa/${codiceUtente}`,
        errorText: 'le squadre iscritte',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    codiceUtente,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
    selectedCampionato,
  ])

  // fetch available gare risultato
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({
        type: SET_GARE_RISULTATO,
        payload: x?.data?.sort((a, b) =>
          sortNumeriGara(a.numeroGara, b.numeroGara)
        ),
      })

    if (selectedCampionato && codiceUtente) {
      fetchAndDispatchFunction({
        url: `calendari/campionato/${selectedCampionato}/societa/${codiceUtente}/risultato`,
        errorText: 'le partite di cui inviare il risultato',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [codiceUtente, dispatch, fetchAndDispatchFunction, selectedCampionato])

  const GridButton = ({ text, link, onClick }) => {
    const cButton = <Button onClick={link ? () => {} : onClick}>{text}</Button>

    const cLink = link ? (
      <Link to={link} onClick={onClick} style={{ textDecoration: 'none' }}>
        {cButton}
      </Link>
    ) : (
      cButton
    )

    return (
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <center>{cLink}</center>
      </Grid>
    )
  }

  return (
    <Grid container item xs={12} marginY={3}>
      <SelectionBar
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      &nbsp;
      <Grid container item spacing={3} alignItems='center'>
        {selectedCampionatoIscrizioniAperte && (
          <GridButton
            text='Nuova iscrizione'
            link='/iscrizione'
            onClick={() => dispatch({ type: SET_ISCRIZIONE })}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Cambio indirizzo'
            onClick={handleClickOpenIndirizzo}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Richiesta spostamento'
            onClick={handleClickOpenSpostamento}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Invio risultato'
            onClick={handleClickOpenRisultato}
          />
        )}
        {selectedCampionatoCalendariPubblicati && (
          <GridButton
            text='Richiesta arbitraggio'
            onClick={handleClickOpenArbitraggio}
          />
        )}
      </Grid>
      <Grid container item marginY={2} rowSpacing={5} alignItems='stretch'>
        <GridCard
          title='Iscrizioni'
          subtitle1={`${
            iscrizioni?.filter(i => i.status === 'complete')?.length || 0
          } ${
            iscrizioni?.filter(i => i.status === 'complete')?.length === 1
              ? 'completa'
              : 'complete'
          }`}
          subtitle2={`${
            iscrizioni?.filter(i => i.status === 'incomplete')?.length || 0
          } ${
            iscrizioni?.filter(i => i.status === 'incomplete')?.length === 1
              ? 'da completare'
              : 'da completare'
          }`}
          linkTo='/iscrizioni'
          isError={
            iscrizioni?.filter(i => i.status === 'incomplete')?.length > 0
          }
        />
        <GridCard
          title='Spostamenti'
          subtitle1={`${
            spostamenti?.filter(
              s =>
                s.codiceSocietaRisposta === codiceUtente &&
                typeof s.acceptedBySocietaRisposta !== 'boolean'
            )?.length || 0
          } da confermare`}
          subtitle2={`${
            spostamenti?.filter(
              s =>
                s.codiceSocietaDomanda === codiceUtente &&
                typeof s.acceptedBySocietaRisposta !== 'boolean'
            )?.length || 0
          } in attesa`}
          linkTo='/spostamenti'
          isError={
            spostamenti?.filter(
              s =>
                s.codiceSocietaRisposta === codiceUtente &&
                typeof s.acceptedBySocietaRisposta !== 'boolean'
            )?.length > 0
          }
        />
        <GridCard
          title='Risultati'
          subtitle1={`${gareRisultato?.length || 0} da inviare`}
          isError={gareRisultato?.length > 0}
        />
        <GridCard
          title='Arbitraggi'
          subtitle1={`${arbitraggi?.length || 0} ${
            arbitraggi?.length === 1 ? 'richiesta' : 'richieste'
          }`}
          linkTo='/arbitraggi'
        />
      </Grid>
      <DialogMini
        open={openIndirizzo}
        handleClose={handleCloseIndirizzo}
        title={titles.titleIndirizzo}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={updateIndirizzo}
        dialogCambioIndirizzo={true}
      />
      <DialogMini
        open={openSpostamento}
        handleClose={handleCloseSpostamento}
        title={titles.titleSpostamentoDomanda}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={performSpostamento}
        dialogSpostamento={true}
      />
      <DialogMini
        open={openRisultato}
        handleClose={handleCloseRisultato}
        title={titles.titleRisultato}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={updateRisultato}
        dialogRisultato={true}
      />
      <DialogMini
        open={openArbitraggio}
        handleClose={handleCloseArbitraggio}
        title={titles.titleArbitraggio}
        textUndo='Chiudi'
        textConfirm='Conferma'
        triggerFunction={addArbitraggio}
        dialogRichiestaArbitraggio={true}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  iscrizioni: state.iscrizioni.iscrizioni,
  gareRisultato: state.risultato.availableGare,
  dummyUpdate: state.home.dummyUpdate,
  selectedCampionatoIscrizioniAperte:
    state.home.selectedCampionatoIscrizioniAperte,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
  selectedGare: state.calendari.selectedGare,
  presso: state.calendari.presso,
  indirizzo: state.calendari.indirizzo,
  citta: state.calendari.citta,
  spostamenti: state.spostamenti.spostamenti,
  spostamento: state.spostamenti.spostamento,
  selectedRisultato: state.risultato.selectedGara,
  risultato: state.risultato.risultato,
  arbitraggi: state.arbitraggi.arbitraggi,
  selectedGareArb: state.arbitraggi.selectedGare,
  checkPaymentArb: state.arbitraggi.checkPayment,
})

const ConnectedDashboardSocieta = connect(mapStateToProps)(DashboardSocieta)

export default ConnectedDashboardSocieta
