import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import GridCircularProgress from '../components/GridCircularProgress'
import {
  colours,
  titlesArbitraggi as titles,
  phrasesArbitraggi as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import { SET_CALENDARI } from '../container/calendari/types'
import {
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
} from '../container/arbitraggi/types'

const Arbitraggi = ({
  infoUtente,
  iscrizioniLight,
  loadingArbitraggi,
  availableCategorie,
  selectedCategoria,
  dummyUpdate,
  calendari,
  arbitraggi,
  sortingColumn,
  sortingAscending,
}) => {
  const { admin, designatore, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  document.title = 'PGS Milano - Richieste di arbitraggio'

  const { setFeedback } = useFeedback()

  let columnsArbitraggi = [
    { label: 'Richiesto in data', sortable: true, sortingField: 'timeDomanda' },
    { label: 'Gara', sortable: true, sortingField: 'numeroGara' },
    { label: 'Categoria', sortable: true, sortingField: 'categoria' },
    { label: 'Squadra in casa', sortable: false },
    { label: 'Squadra in trasferta', sortable: false },
  ]
  if (admin || designatore)
    columnsArbitraggi = [...columnsArbitraggi, { label: '', sortable: false }]

  const [filteredArbitraggi, setFilteredArbitraggi] = useState()
  const [deletingItem, setDeletingItem] = useState()
  const [openDeleteArbitraggio, setOpenDelete] = useState(false)

  const handleClickOpenDeleteArbitraggio = s => {
    setDeletingItem(s)
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
    setDeletingItem()
  }

  // filter arbitraggi
  useEffect(() => {
    setFilteredArbitraggi(
      arbitraggi?.filter(
        s =>
          s.categoria === selectedCategoria ||
          selectedCategoria === availableCategorie[0] ||
          !selectedCategoria
      )
    )
  }, [arbitraggi, availableCategorie, selectedCategoria])

  // fetch calendari
  useEffect(() => {
    const fetchCalendari = async () => {
      try {
        const calendariData = await sendRequest(
          'calendari/many',
          'POST',
          JSON.stringify({ ids: arbitraggi?.map(a => a.idGara) }),
          { 'Content-Type': 'application/json' }
        )

        if (!calendariData) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          dispatch({ type: SET_CALENDARI, payload: calendariData })
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
    }
    if (arbitraggi) {
      fetchCalendari()
    } else {
      dispatch({ type: SET_CALENDARI })
    }
  }, [arbitraggi, dispatch])

  // delete arbitraggio
  const deleteArbitraggio = async () => {
    if (deletingItem?.codiceCampionato && deletingItem?.numeroGara) {
      try {
        await sendRequest(
          `arbitraggi/campionato/${deletingItem.codiceCampionato}/numero-gara/${deletingItem.numeroGara}`,
          'DELETE',
          null,
          { Authorization: codiceUtente }
        )
        handleCloseDelete()
        setFeedback(phrases.phraseDeleteSuccess, false, titles.titleDelete)
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
        setDeletingItem()
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Impossibile procedere: richiesta non trovata!')
    }
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Richieste di arbitraggio</h2>
        </center>
      </Grid>
      <SelectionBar includeGirone={false} includeTeam={false} />
      {(loadingArbitraggi && <GridCircularProgress />) ||
        (!filteredArbitraggi && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco delle richieste di arbitraggio non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (filteredArbitraggi.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessuna richiesta di arbitraggio trovata.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={filteredArbitraggi}
                  setTable={setFilteredArbitraggi}
                  columns={columnsArbitraggi}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {filteredArbitraggi.map((s, index) => {
                    let [sData, sOra] = s.timeDomanda?.split('T')
                    sData = sData?.split('-')?.reverse().join('/')
                    sOra = sOra?.slice(0, 5)

                    const sGara = calendari?.find(c => c._id === s.idGara)
                    const sCasa = iscrizioniLight?.find(
                      i => i.id === sGara?.squadraCasaID
                    )?.nomeSquadra
                    const sTrasferta = iscrizioniLight?.find(
                      i => i.id === sGara?.squadraTrasfertaID
                    )?.nomeSquadra

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                      >
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sData}&nbsp;{sOra}
                        </TableCell>
                        <TableCell align='center'>{s.numeroGara}</TableCell>
                        <TableCell align='center'>{s.categoria}</TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sCasa}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {sTrasferta}
                        </TableCell>
                        {(admin || designatore) && (
                          <TableCell align='center'>
                            <IconButton
                              style={{ color: colours.red }}
                              onClick={() =>
                                handleClickOpenDeleteArbitraggio(s)
                              }
                            >
                              <Tooltip title='Elimina'>
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openDeleteArbitraggio}
        handleClose={handleCloseDelete}
        title={titles.titleDelete}
        textContent={phrases.phraseDelete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={deleteArbitraggio}
        colourBackground={colours.red}
        colourHover={colours.redDark}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  loadingArbitraggi: state.home.loadingArbitraggi,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  dummyUpdate: state.home.dummyUpdate,
  calendari: state.calendari.calendari,
  arbitraggi: state.arbitraggi.arbitraggi,
  sortingColumn: state.arbitraggi.sortingColumn,
  sortingAscending: state.arbitraggi.sortingAscending,
})

const ConnectedArbitraggi = connect(mapStateToProps)(Arbitraggi)

export default ConnectedArbitraggi
