import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Checkbox, Grid, IconButton, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import Button from '../components/Button'
import Select from '../components/Select'
import DialogMini from '../components/DialogMini'
import InputField from '../components/InputField'
import TimePicker from '../components/TimePicker'
import AutoComplete from '../components/AutoComplete'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { sortCodiceSocieta } from '../hooks/sort-functions'
import {
  formatIndirizzo,
  linkToGoogleMaps,
  formatBodyField,
  JSONreplacer,
  fetchAndDispatch,
} from '../hooks/utils-functions'
import {
  colours,
  titlesIscrizione as titles,
  phrasesIscrizione as phrases,
  GIORNI,
} from '../settings/settings'
import { SET_ERROR } from '../container/home/types'
import {
  SET_ISCRIZIONI,
  SET_ISCRIZIONE,
  SET_ISCRIZIONE_FIELD,
} from '../container/iscrizioni/types'

const Iscrizione = ({
  infoUtente,
  campionati,
  categorie,
  selectedCampionato,
  selectedCampionatoIscrizioniAperte,
  iscrizioni,
  iscrizione,
}) => {
  const { admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  const iDispatch = useCallback(
    (field, value, toCase) =>
      dispatch({
        type: SET_ISCRIZIONE_FIELD,
        payload: { field, value: formatBodyField(value, toCase) },
      }),
    [dispatch]
  )

  const mode = !!iscrizione?._id ? 'update' : 'add'

  document.title = `PGS Milano - ${
    mode === 'add' ? 'Nuova' : 'Modifica'
  } iscrizione`

  // set "codiceCampionato"
  useEffect(() => {
    if (!admin && mode === 'add')
      if (!iscrizione?.codiceCampionato && selectedCampionato)
        iDispatch('codiceCampionato', selectedCampionato)
  }, [admin, iDispatch, iscrizione?.codiceCampionato, mode, selectedCampionato])

  // set "codiceSocieta"
  useEffect(() => {
    if (!admin && mode === 'add')
      if (!iscrizione?.codiceSocieta && codiceUtente)
        iDispatch('codiceSocieta', codiceUtente)
  }, [admin, codiceUtente, iDispatch, iscrizione?.codiceSocieta, mode])

  // update "motivoDeroga"
  useEffect(() => {
    if (!iscrizione?.hasDeroga && iscrizione?.motivoDeroga)
      iDispatch('motivoDeroga')
  }, [iDispatch, iscrizione?.hasDeroga, iscrizione?.motivoDeroga])

  const isNotEditable = !admin && !selectedCampionatoIscrizioniAperte

  const [availableSocieta, setAvailableSocieta] = useState()
  const [gironiChangingCheck, setGironiChangingCheck] = useState(false)

  const { setFeedback } = useFeedback()

  const [openInfo, setOpenInfo] = useState(false)
  const [openDeroga, setOpenDeroga] = useState(false)

  const handleClickOpenInfo = () => setOpenInfo(true)
  const handleClickOpenDeroga = () => setOpenDeroga(true)

  const handleCloseInfo = () => setOpenInfo(false)
  const handleCloseDeroga = () => setOpenDeroga(false)

  // array equality
  const aEquality = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    )
  }

  // check gironi changing checks
  useEffect(() => {
    if (admin && mode === 'update') {
      const oldValues = iscrizioni?.find(i => i.id === iscrizione?._id)
      setGironiChangingCheck(
        !!oldValues?.girone &&
          !!oldValues?.posizione &&
          (iscrizione?.categoria !== oldValues?.categoria ||
            (iscrizione?.abbinamenti &&
              oldValues?.abbinamenti &&
              !aEquality(iscrizione.abbinamenti, oldValues.abbinamenti)) ||
            (iscrizione?.alternanze &&
              oldValues?.alternanze &&
              !aEquality(iscrizione.alternanze, oldValues.alternanze)))
      )
    }
  }, [
    admin,
    iscrizione?._id,
    iscrizione?.abbinamenti,
    iscrizione?.alternanze,
    iscrizione?.categoria,
    iscrizioni,
    mode,
  ])

  // update "senzaPalestra" variables
  useEffect(() => {
    if (iscrizione?.senzaPalestra) {
      if (iscrizione?.giorno) iDispatch('giorno')
      if (iscrizione?.orario) iDispatch('orario')
      if (iscrizione?.presso) iDispatch('presso')
      if (iscrizione?.indirizzo) iDispatch('indirizzo')
      if (iscrizione?.citta) iDispatch('citta')
      if (iscrizione?.abbinamenti) iDispatch('abbinamenti')
      if (iscrizione?.alternanze) iDispatch('alternanze')
    }
  }, [
    iDispatch,
    iscrizione?.abbinamenti,
    iscrizione?.alternanze,
    iscrizione?.citta,
    iscrizione?.giorno,
    iscrizione?.indirizzo,
    iscrizione?.orario,
    iscrizione?.presso,
    iscrizione?.senzaPalestra,
  ])

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch società
  useEffect(() => {
    const dispatchFunction = x =>
      setAvailableSocieta(
        x?.sort((a, b) => sortCodiceSocieta(a.codiceSocieta, b.codiceSocieta))
      )

    if (admin)
      fetchAndDispatchFunction({
        url: 'affiliazioni/all',
        errorText: 'le società',
        dispatchFunction,
      })
  }, [admin, fetchAndDispatchFunction])

  // fetch iscrizioni
  useEffect(() => {
    const dispatchFunction = x => dispatch({ type: SET_ISCRIZIONI, payload: x })

    if (iscrizione?.codiceCampionato && iscrizione?.codiceSocieta) {
      fetchAndDispatchFunction({
        url: `iscrizioni/campionato/${iscrizione.codiceCampionato}/societa/${iscrizione.codiceSocieta}`,
        errorText: 'le squadre iscritte',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    dispatch,
    fetchAndDispatchFunction,
    iscrizione?.codiceCampionato,
    iscrizione?.codiceSocieta,
  ])

  const addIscrizione = async () => {
    try {
      await sendRequest('iscrizioni/add', 'POST', JSON.stringify(iscrizione), {
        'Content-Type': 'application/json',
        Authorization: codiceUtente,
      })
      setFeedback(titles.titleCompleteAdd, false, '', '/iscrizioni')
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const updateIscrizione = async () => {
    try {
      await sendRequest(
        `iscrizioni/${iscrizione?._id}`,
        'PUT',
        JSON.stringify(iscrizione, JSONreplacer),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      setFeedback(titles.titleCompleteUpdate, false, '', '/iscrizioni')
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const confirmIscrizione = async () => {
    if (mode === 'add') await addIscrizione()
    if (mode === 'update') await updateIscrizione()
  }

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_ISCRIZIONE })
  }, [dispatch])

  const containerProps = {
    container: true,
    item: true,
    xs: 12,
    paddingY: 2,
    rowSpacing: 2,
    columnSpacing: 5,
  }

  return (
    <Grid container item xs={12} paddingX={3}>
      <Grid item xs={12}>
        <center>
          <h2>{`${mode === 'add' ? 'Nuova' : 'Modifica'} iscrizione`}</h2>
        </center>
      </Grid>
      {!admin && (
        <Grid item xs={12}>
          <center>
            {(!selectedCampionatoIscrizioniAperte && (
              <h4 style={{ color: colours.red }}>
                Le iscrizioni a questo campionato sono chiuse.
                <br />
                <br />
                È comunque possibile modificare i contatti dei referenti e gli
                indirizzi delle palestre.
                <br />
                In caso di modifiche, si consiglia di avvisare le squadre
                avversarie.
                <br />
                <br />
                Per altre richieste scrivere a{' '}
                <a href='mailto:iscrizioni@pgsmilano.org'>
                  iscrizioni@pgsmilano.org
                </a>
                .
              </h4>
            )) || (
              <p>
                Per domande sulle iscrizioni ai campionati scrivere a{' '}
                <a href='mailto:iscrizioni@pgsmilano.org'>
                  iscrizioni@pgsmilano.org
                </a>
                .
              </p>
            )}
          </center>
        </Grid>
      )}
      <Grid {...containerProps}>
        {admin && (
          <>
            <Grid item xs={12} md={6}>
              <Select
                id='codice-campionato'
                label='Campionato *'
                value={iscrizione?.codiceCampionato}
                options={campionati?.map(c => c.codice)}
                optionLabels={campionati?.map(c => c.nome)}
                disabled={!campionati}
                onChange={i => iDispatch('codiceCampionato', i)}
                errorFunc={() => !iscrizione?.codiceCampionato}
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AutoComplete
                id='codice-societa'
                value={availableSocieta?.find(
                  s => s.codiceSocieta === iscrizione?.codiceSocieta
                )}
                options={availableSocieta}
                getOptionLabel={op => `${op.codiceSocieta} - ${op.nomeSocieta}`}
                label='Società *'
                disabled={!availableSocieta}
                onChange={i => iDispatch('codiceSocieta', i?.codiceSocieta)}
                errorFunc={() => !iscrizione?.codiceSocieta}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <InputField
            id='nome-squadra'
            value={iscrizione?.nomeSquadra}
            onChange={i => iDispatch('nomeSquadra', i, 'upper')}
            autoFocus={!admin && selectedCampionatoIscrizioniAperte}
            label='Nome della squadra *'
            errorFunc={() => !iscrizione?.nomeSquadra}
            mode={mode}
            readOnly={isNotEditable}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            id='categoria'
            value={iscrizione?.categoria}
            options={categorie?.map(c => c.categoria)}
            optionLabels={categorie?.map(c => c.nome)}
            label='Categoria *'
            onChange={i => iDispatch('categoria', i)}
            errorFunc={() => !iscrizione?.categoria}
            mode={mode}
            readOnly={isNotEditable}
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Gare casalinghe</h4>
        </Grid>
        <Grid item xs={12}>
          Palestra non disponibile &nbsp;
          <Checkbox
            id='senza-palestra'
            checked={!!iscrizione?.senzaPalestra}
            disabled={isNotEditable}
            onChange={event => iDispatch('senzaPalestra', event.target.checked)}
            color='default'
          />
        </Grid>
        {!iscrizione?.senzaPalestra && (
          <>
            <Grid item xs={12} md={6}>
              <Select
                id='giorno'
                value={iscrizione?.giorno}
                options={GIORNI}
                label='Giorno di disputa'
                onChange={i => iDispatch('giorno', i)}
                errorFunc={() => !iscrizione?.giorno}
                mode={mode}
                readOnly={isNotEditable}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <center>
                <TimePicker
                  label='Orario di disputa'
                  value={iscrizione?.orario}
                  onChange={i => iDispatch('orario', i)}
                  error={!iscrizione?.orario}
                  readOnly={isNotEditable}
                  variant='filled'
                />
              </center>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='presso'
                value={iscrizione?.presso}
                onChange={i => iDispatch('presso', i, 'upper')}
                label='Istituto o centro sportivo'
                adornment='place'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='indirizzo'
                value={iscrizione?.indirizzo}
                onChange={i => iDispatch('indirizzo', i, 'upper')}
                label='Indirizzo'
                adornment='zone'
                errorFunc={() => !iscrizione?.indirizzo}
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='città'
                value={iscrizione?.citta}
                onChange={i => iDispatch('citta', i, 'upper')}
                label='Città'
                adornment='city'
                errorFunc={() => !iscrizione?.citta}
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {linkToGoogleMaps(formatIndirizzo(iscrizione))}
            </Grid>
          </>
        )}
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Primo referente della squadra</h4>
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='nome-referente'
            value={iscrizione?.nomeReferente}
            onChange={i => iDispatch('nomeReferente', i, 'upper')}
            label='Nome e Cognome'
            adornment='person'
            errorFunc={() => !iscrizione?.nomeReferente}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='telefono-referente'
            value={iscrizione?.telefonoReferente}
            onChange={i =>
              iDispatch('telefonoReferente', i?.replace(/\s/g, ''))
            }
            label='Telefono'
            type='tel'
            adornment='phone'
            errorFunc={() => !iscrizione?.telefonoReferente}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='mail-referente'
            value={iscrizione?.mailReferente}
            onChange={i =>
              iDispatch('mailReferente', i?.replace(/\s/g, ''), 'lower')
            }
            label='E-mail'
            type='email'
            adornment='email'
            errorFunc={() => !iscrizione?.mailReferente}
            mode={mode}
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Secondo referente della squadra (facoltativo)</h4>
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='nome-referente-2'
            value={iscrizione?.nomeReferente2}
            onChange={i => iDispatch('nomeReferente2', i, 'upper')}
            label='Nome e Cognome'
            adornment='person'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='telefono-referente-2'
            value={iscrizione?.telefonoReferente2}
            onChange={i =>
              iDispatch('telefonoReferente2', i?.replace(/\s/g, ''))
            }
            label='Telefono'
            type='tel'
            adornment='phone'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='mail-referente-2'
            value={iscrizione?.mailReferente2}
            onChange={i =>
              iDispatch('mailReferente2', i?.replace(/\s/g, ''), 'lower')
            }
            label='E-mail'
            type='email'
            adornment='email'
            mode={mode}
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>
            Preferenze di calendario (facoltativo) &nbsp;
            <IconButton
              style={{ color: colours.grey }}
              onClick={handleClickOpenInfo}
            >
              <Tooltip key='abb-alt' title='Maggiori informazioni'>
                <InfoIcon />
              </Tooltip>
            </IconButton>
          </h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoComplete
            id='abbinamenti'
            value={iscrizioni?.filter(s =>
              iscrizione?.abbinamenti?.includes(s.id)
            )}
            blurOnSelect={false}
            multiple={true}
            options={iscrizioni?.filter(
              s =>
                s.id !== iscrizione?._id &&
                !iscrizione?.alternanze?.includes(s.id) &&
                !s.senzaPalestra &&
                s.codiceSocieta ===
                  ((admin && iscrizione?.codiceSocieta) || codiceUtente)
            )}
            getOptionLabel={op => `${op.categoria} ${op.nomeSquadra}`}
            noOptionsText='Nessun abbinamento possibile'
            label='Abbinamenti'
            onChange={i =>
              iDispatch(
                'abbinamenti',
                i.map(a => a.id)
              )
            }
            mode={mode}
            disabled={iscrizione?.senzaPalestra}
            readOnly={isNotEditable}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoComplete
            id='alternanze'
            value={iscrizioni?.filter(s =>
              iscrizione?.alternanze?.includes(s.id)
            )}
            blurOnSelect={false}
            multiple={true}
            options={iscrizioni?.filter(
              s =>
                s.id !== iscrizione?._id &&
                !iscrizione?.abbinamenti?.includes(s.id) &&
                !s.senzaPalestra &&
                s.codiceSocieta ===
                  ((admin && iscrizione?.codiceSocieta) || codiceUtente)
            )}
            getOptionLabel={op => `${op.categoria} ${op.nomeSquadra}`}
            noOptionsText='Nessuna alternanza possibile'
            label='Alternanze'
            onChange={i =>
              iDispatch(
                'alternanze',
                i.map(a => a.id)
              )
            }
            mode={mode}
            disabled={iscrizione?.senzaPalestra}
            readOnly={isNotEditable}
          />
        </Grid>
        {iscrizione?.senzaPalestra && (
          <Grid item xs={12} style={{ color: colours.red, marginBottom: 30 }}>
            Le squadre senza palestra non possono inserire preferenze di
            abbinamento o alternanza perché giocano sempre fuori casa.
          </Grid>
        )}
        <Grid item xs={12}>
          <InputField
            id='note'
            value={iscrizione?.note}
            onChange={i => iDispatch('note', i)}
            multiline={true}
            label='Altre richieste particolari'
            mode={mode}
            readOnly={isNotEditable}
          />
        </Grid>
      </Grid>
      {(!admin || mode === 'add') && (
        <Grid {...containerProps}>
          <Grid item xs={12}>
            <h4>
              Legge sulla privacy e accettazione Statuto e Regolamenti della PGS
            </h4>
          </Grid>
          <Grid item xs={2} md={1}>
            <center>
              <Checkbox
                id='check-privacy'
                checked={!!iscrizione?.checkPrivacy}
                disabled={isNotEditable}
                onChange={event =>
                  iDispatch('checkPrivacy', event.target.checked)
                }
                color='default'
              />
            </center>
          </Grid>
          <Grid item xs={10} md={11}>
            <div style={{ color: colours.greyDark }}>
              Dichiaro di aver preso visione dell'
              <a
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.pgsmilano.org/pdf/privacy.pdf'
              >
                informativa
              </a>{' '}
              sul trattamento dei dati personali e di prestare il consenso al
              loro trattamento per gli scopi indicati. La società dichiara
              inoltre di accettare lo Statuto e i Regolamenti della PGS e che i
              propri atleti tesserati sono in possesso dell'idonea
              certificazione medica per l'attività sportiva.
            </div>
          </Grid>
        </Grid>
      )}
      {admin && (
        <Grid {...containerProps}>
          <Grid item xs={12}>
            <h4>
              Deroga &nbsp;
              <IconButton
                style={{ color: colours.grey }}
                onClick={handleClickOpenDeroga}
              >
                <Tooltip key='deroga' title='Maggiori informazioni'>
                  <InfoIcon />
                </Tooltip>
              </IconButton>
            </h4>
          </Grid>
          <Grid item>
            Deroga concessa &nbsp;
            <Checkbox
              id='has-deroga'
              checked={!!iscrizione?.hasDeroga}
              onChange={event => iDispatch('hasDeroga', event.target.checked)}
              color='default'
            />
          </Grid>
          {iscrizione?.hasDeroga && (
            <Grid item xs={12} md={6}>
              <InputField
                id='motivo-deroga'
                value={iscrizione?.motivoDeroga}
                onChange={i => iDispatch('motivoDeroga', i)}
                label='Motivo della deroga'
                mode={mode}
              />
            </Grid>
          )}
        </Grid>
      )}
      {gironiChangingCheck && (
        <Grid item xs={12}>
          <center>
            <p style={{ color: colours.red, margin: 20 }}>
              ATTENZIONE! Si stanno modificando alcuni campi utilizzati per la
              generazione dei gironi (categoria o richieste di
              abbinamento/alternanza).
            </p>
          </center>
        </Grid>
      )}
      <Grid {...containerProps}>
        <Grid item xs>
          <center>
            <Link to='/iscrizioni' style={{ textDecoration: 'none' }}>
              <Button error={1}>Indietro</Button>
            </Link>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <Button dark={1} onClick={confirmIscrizione}>
              Salva
            </Button>
          </center>
        </Grid>
      </Grid>
      <DialogMini
        open={openInfo}
        handleClose={handleCloseInfo}
        title={titles.titleInfo}
        textContent={phrases.phraseInfo}
        textConfirm='Ok'
        triggerFunction={handleCloseInfo}
      />
      <DialogMini
        open={openDeroga}
        handleClose={handleCloseDeroga}
        title={titles.titleDeroga}
        textContent={phrases.phraseDeroga}
        textConfirm='Ok'
        triggerFunction={handleCloseDeroga}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  campionati: state.home.campionati,
  categorie: state.home.categorie,
  selectedCampionato: state.home.selectedCampionato,
  selectedCampionatoIscrizioniAperte:
    state.home.selectedCampionatoIscrizioniAperte,
  iscrizioni: state.iscrizioni.iscrizioni,
  iscrizione: state.iscrizioni.iscrizione,
})

const ConnectedIscrizione = connect(mapStateToProps)(Iscrizione)

export default ConnectedIscrizione
