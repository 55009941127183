import React, { useRef } from 'react'
import { connect } from 'react-redux'

import Button from './Button'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { checkBackendR } from '../hooks/utils-functions'

const ButtonUpload = ({
  buttonText,
  algorithm,
  fileName,
  runScript = false,
  uploadCampionato,
  infoUtente,
}) => {
  const { codiceUtente } = infoUtente

  const uploadFileRef = useRef()

  const { setFeedback } = useFeedback()

  const uploadFile = async (algorithm, file, fileName, runScript) => {
    if (file.type !== 'text/csv') {
      setFeedback('Il file deve essere in formato CSV.')
      return
    }

    if (!algorithm) {
      setFeedback('Algoritmo non definito.')
      return
    }

    // check if backendR is available
    try {
      await checkBackendR(sendRequest, codiceUtente)
    } catch (err) {
      setFeedback(err.message)
      return
    }

    const formData = new FormData()
    formData.append('File', file)
    try {
      await sendRequest(`algoritmi/checkfolder/${algorithm}`)
      await sendRequest(
        `algoritmi/upload/${algorithm}${fileName ? `/${fileName}` : ''}`,
        'POST',
        formData,
        { 'Content-Type': 'text/csv', Authorization: codiceUtente }
      )

      setFeedback('File caricato correttamente!', false)

      if (runScript) {
        await sendRequest(
          `algoritmi/run/${algorithm}${
            algorithm === '05_UpdateCalendari' ? `/${uploadCampionato}` : ''
          }`,
          'GET',
          null,
          { Authorization: codiceUtente }
        )
      }
    } catch (err) {
      console.log(err)
      setFeedback(err.message)
    }
  }

  return (
    <center>
      <input
        id={`upload-file-${algorithm}`}
        ref={uploadFileRef}
        type='file'
        accept='text/csv'
        style={{ display: 'none' }}
        onChange={event =>
          uploadFile(algorithm, event.target.files[0], fileName, runScript)
        }
      />
      <Button
        light={1}
        disabled={algorithm === '05_UpdateCalendari' && !uploadCampionato}
        onClick={() => uploadFileRef.current.click()}
      >
        {buttonText}
      </Button>
    </center>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
})

const ConnectedButtonUpload = connect(mapStateToProps)(ButtonUpload)

export default ConnectedButtonUpload
