import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Checkbox, Grid } from '@mui/material'

import Button from '../components/Button'
import Select from '../components/Select'
import InputField from '../components/InputField'
import { titlesAffiliazione as titles } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { formatBodyField, JSONreplacer } from '../hooks/utils-functions'
import { SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_AFFILIAZIONE,
  SET_AFFILIAZIONE_FIELD,
} from '../container/affiliazioni/types'

const Affiliazione = ({ infoUtente, zone, dummyUpdate, affiliazione }) => {
  const { codiceUtente } = infoUtente

  const dispatch = useDispatch()

  const aDispatch = (field, value, toCase) =>
    dispatch({
      type: SET_AFFILIAZIONE_FIELD,
      payload: { field, value: formatBodyField(value, toCase) },
    })

  const mode = !!affiliazione?._id ? 'update' : 'add'

  document.title = `PGS Milano - ${
    mode === 'add' ? 'Nuova' : 'Modifica'
  } società`

  const { setFeedback } = useFeedback()

  const addAffiliazione = async () => {
    try {
      await sendRequest(
        'affiliazioni/add',
        'POST',
        JSON.stringify(affiliazione),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      setFeedback(titles.titleSuccessAdd, false, '', '/affiliazioni')
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const updateAffiliazione = async () => {
    try {
      await sendRequest(
        `affiliazioni/${affiliazione?._id}`,
        'PUT',
        JSON.stringify(affiliazione, JSONreplacer),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      setFeedback(titles.titleSuccessUpdate, false, '', '/affiliazioni')
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const confirmAffiliazione = async () => {
    if (mode === 'add') await addAffiliazione()
    if (mode === 'update') await updateAffiliazione()
  }

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_AFFILIAZIONE })
  }, [dispatch])

  const containerProps = {
    container: true,
    item: true,
    xs: 12,
    paddingY: 2,
    rowSpacing: 2,
    columnSpacing: 5,
  }

  return (
    <Grid container item xs={12} paddingX={3}>
      <Grid item xs={12}>
        <center>
          <h2>{`${mode === 'add' ? 'Nuova' : 'Modifica'} società`}</h2>
        </center>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12} md={6}>
          <InputField
            id='codice-societa'
            value={affiliazione?.codiceSocieta}
            onChange={i => aDispatch('codiceSocieta', i)}
            autoFocus={true}
            fullWidth
            label='Codice di affiliazione PGS *'
            errorFunc={() => !affiliazione?.codiceSocieta}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='password'
            value={affiliazione?.password}
            onChange={i => aDispatch('password', i)}
            fullWidth
            label='Password *'
            errorFunc={() => !affiliazione?.password}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='denominazione-societa'
            value={affiliazione?.denominazioneSocieta}
            onChange={i => aDispatch('denominazioneSocieta', i, 'upper')}
            fullWidth
            label='Ragione sociale *'
            errorFunc={() => !affiliazione?.denominazioneSocieta}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='nome-societa'
            value={affiliazione?.nomeSocieta}
            onChange={i => aDispatch('nomeSocieta', i, 'upper')}
            fullWidth
            label='Nome breve *'
            errorFunc={() => !affiliazione?.nomeSocieta}
            mode={mode}
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Contatti della società</h4>
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            id='referente'
            value={affiliazione?.referente}
            onChange={i => aDispatch('referente', i, 'upper')}
            fullWidth
            label='Referente'
            errorFunc={() => !affiliazione?.referente}
            adornment='person'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            id='ruolo'
            value={affiliazione?.ruolo}
            onChange={i => aDispatch('ruolo', i, 'upper')}
            fullWidth
            label='Ruolo'
            adornment='work'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            id='telefono'
            value={affiliazione?.telefono}
            onChange={i => aDispatch('telefono', i.replace(/\s/g, ''))}
            fullWidth
            label='Telefono'
            errorFunc={() => !affiliazione?.telefono}
            type='tel'
            adornment='phone'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputField
            id='mail'
            value={affiliazione?.mail}
            onChange={i => aDispatch('mail', i.replace(/\s/g, ''), 'lower')}
            fullWidth
            label='E-mail'
            errorFunc={() => !affiliazione?.mail}
            type='email'
            adornment='email'
            mode={mode}
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Altre informazioni</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            id='zona'
            value={affiliazione?.zona}
            options={zone?.map(z => z.zona)}
            optionLabels={zone?.map(z => `${z.zona} - ${z.descrizione}`)}
            label='Zona geografica'
            onChange={i => aDispatch('zona', i)}
            errorFunc={() => !affiliazione?.zona}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          Società attiva &nbsp;
          <Checkbox
            id='is-active'
            checked={affiliazione?.isActive !== false}
            onChange={event => aDispatch('isActive', event.target.checked)}
            color='default'
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs>
          <center>
            <Link to='/affiliazioni' style={{ textDecoration: 'none' }}>
              <Button error={1}>Indietro</Button>
            </Link>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <Button dark={1} onClick={confirmAffiliazione}>
              Salva
            </Button>
          </center>
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  zone: state.home.zone,
  dummyUpdate: state.home.dummyUpdate,
  affiliazione: state.affiliazioni.affiliazione,
})

const ConnectedAffiliazione = connect(mapStateToProps)(Affiliazione)

export default ConnectedAffiliazione
