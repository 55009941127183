import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { sendRequest } from './http-hook'
import {
  sortAlphabetically,
  sortCategorie,
  sortStagioni,
  sortGironi,
  sortNumeriGara,
} from './sort-functions'
import { fetchAndDispatch } from './utils-functions'
import {
  SET_AVAILABLE_STAGIONI,
  SET_SELECTED_STAGIONE,
  SET_AVAILABLE_CAMPIONATI,
  SET_SELECTED_CAMPIONATO,
  SET_AVAILABLE_CATEGORIE,
  SET_SELECTED_CATEGORIA,
  SET_AVAILABLE_GIRONI,
  SET_SELECTED_GIRONE,
  SET_AVAILABLE_TEAMS,
  SET_SELECTED_TEAM,
  SET_AVAILABLE_NUMERI_GARA,
  SET_SELECTED_NUMERO_GARA,
  SET_AVAILABLE_PERIODI,
  SET_SELECTED_PERIODO,
  SET_SELECTED_ARBITRO,
  SET_SELECTED_SOCIETA,
  SET_ERROR,
} from '../container/home/types'

export const useSelection = (
  admin,
  arbitro,
  campionati,
  availableStagioni,
  selectedStagione,
  availableCampionati,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  availableGironi,
  selectedGirone,
  availableTeams,
  selectedTeam,
  availableNumeriGara,
  selectedNumeroGara,
  availablePeriodi,
  selectedPeriodo,
  availableArbitri,
  selectedArbitro,
  availableSocieta,
  selectedSocieta,
  iscrizioniLight,
  dummyUpdate,
  includeCampionato,
  includeCategoria,
  includeGirone,
  includeTeam,
  includeNumeroGara,
  includePeriodo,
  arePubblicati
) => {
  const dispatch = useDispatch()

  const location = useLocation()

  const pageURL = location.pathname?.split('/')?.[1]

  const cateCollection = ['', 'algoritmi', 'gironi', 'squadre'].includes(
    pageURL
  )
    ? 'iscrizioni'
    : ['finali', 'provvedimenti', 'designazioni', 'arbitraggi'].includes(
        pageURL
      )
    ? 'calendari'
    : pageURL

  const fetchCampionati =
    includeCampionato &&
    ['iscrizioni', 'calendari', 'classifiche', 'spostamenti'].includes(
      cateCollection
    )

  const fetchCategorie =
    includeCategoria &&
    selectedCampionato &&
    (admin || arePubblicati || pageURL === 'squadre')

  const fetchGironi =
    includeGirone &&
    selectedCampionato &&
    selectedCategoria &&
    (admin || arePubblicati)

  const fetchTeams =
    includeTeam &&
    selectedCampionato &&
    selectedCategoria &&
    selectedCategoria !== availableCategorie[0] &&
    iscrizioniLight &&
    (admin || arePubblicati)

  const fetchNumeriGara =
    includeNumeroGara && selectedCampionato && (admin || arePubblicati)

  const fetchPeriodi = includePeriodo

  const resetSelectedSocieta = useCallback(async () => {
    localStorage.removeItem('selectedSocieta')
    dispatch({ type: SET_SELECTED_SOCIETA })
  }, [dispatch])

  const resetSelectedArbitro = useCallback(async () => {
    localStorage.removeItem('selectedArbitro')
    dispatch({ type: SET_SELECTED_ARBITRO })
  }, [dispatch])

  const resetSelectedPeriodo = useCallback(async () => {
    localStorage.removeItem('selectedPeriodo')
    dispatch({ type: SET_SELECTED_PERIODO })
  }, [dispatch])

  const resetSelectedNumeroGara = useCallback(async () => {
    localStorage.removeItem('selectedNumeroGara')
    dispatch({ type: SET_SELECTED_NUMERO_GARA })
  }, [dispatch])

  const resetSelectedTeam = useCallback(async () => {
    localStorage.removeItem('selectedTeam')
    dispatch({ type: SET_SELECTED_TEAM })
  }, [dispatch])

  const resetSelectedGirone = useCallback(async () => {
    localStorage.removeItem('selectedGirone')
    dispatch({ type: SET_SELECTED_GIRONE })
    resetSelectedTeam()
  }, [dispatch, resetSelectedTeam])

  const resetSelectedCategoria = useCallback(async () => {
    localStorage.removeItem('selectedCategoria')
    dispatch({ type: SET_SELECTED_CATEGORIA })
    resetSelectedGirone()
  }, [dispatch, resetSelectedGirone])

  const resetSelectedCampionato = useCallback(async () => {
    localStorage.removeItem('selectedCampionato')
    dispatch({ type: SET_SELECTED_CAMPIONATO })
    resetSelectedCategoria()
  }, [dispatch, resetSelectedCategoria])

  const resetSelectedStagione = useCallback(async () => {
    localStorage.removeItem('selectedStagione')
    dispatch({ type: SET_SELECTED_STAGIONE })
  }, [dispatch])

  const setSelectedStagione = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedStagione', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_STAGIONE, payload: x })
      } else {
        resetSelectedStagione()
      }
    },
    [dispatch, resetSelectedStagione]
  )

  const setSelectedCampionato = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedCampionato', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_CAMPIONATO, payload: x })
        resetSelectedCategoria()
      } else {
        resetSelectedCampionato()
      }
    },
    [dispatch, resetSelectedCampionato, resetSelectedCategoria]
  )

  const setSelectedCategoria = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedCategoria', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_CATEGORIA, payload: x })
        resetSelectedGirone()
      } else {
        resetSelectedCategoria()
      }
    },
    [dispatch, resetSelectedCategoria, resetSelectedGirone]
  )

  const setSelectedGirone = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedGirone', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_GIRONE, payload: x })
        resetSelectedTeam()
      } else {
        resetSelectedGirone()
      }
    },
    [dispatch, resetSelectedGirone, resetSelectedTeam]
  )

  const setSelectedTeam = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedTeam', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_NUMERO_GARA })
        dispatch({ type: SET_SELECTED_TEAM, payload: x })
      } else {
        resetSelectedTeam()
      }
    },
    [dispatch, resetSelectedTeam]
  )

  const setSelectedNumeroGara = useCallback(
    async x => {
      if (x?.numeroGara) {
        setSelectedCategoria(x.categoria)
        setSelectedGirone(x.girone)
        dispatch({ type: SET_SELECTED_NUMERO_GARA, payload: x.numeroGara })
      } else {
        resetSelectedNumeroGara()
      }
    },
    [dispatch, resetSelectedNumeroGara, setSelectedCategoria, setSelectedGirone]
  )

  const setSelectedPeriodo = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedPeriodo', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_PERIODO, payload: x })
      } else {
        resetSelectedPeriodo()
      }
    },
    [dispatch, resetSelectedPeriodo]
  )

  const setSelectedArbitro = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedArbitro', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_ARBITRO, payload: x })
      } else {
        resetSelectedArbitro()
      }
    },
    [dispatch, resetSelectedArbitro]
  )

  const setSelectedSocieta = useCallback(
    async x => {
      if (x) {
        localStorage.setItem('selectedSocieta', JSON.stringify(x))
        dispatch({ type: SET_SELECTED_SOCIETA, payload: x })
      } else {
        resetSelectedSocieta()
      }
    },
    [dispatch, resetSelectedSocieta]
  )

  // fetch availableStagioni
  useEffect(() => {
    dispatch({
      type: SET_AVAILABLE_STAGIONI,
      payload:
        campionati
          ?.map(c => c.stagione)
          ?.filter((v, i, a) => a.indexOf(v) === i)
          ?.sort((a, b) => sortStagioni(a, b)) || [],
    })
  }, [campionati, dispatch, dummyUpdate])

  // fetch availableCampionati
  useEffect(() => {
    if (fetchCampionati)
      dispatch({
        type: SET_AVAILABLE_CAMPIONATI,
        payload: campionati?.map(c => c.codice),
      })
  }, [campionati, dispatch, dummyUpdate, fetchCampionati])

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url => await sendRequest(url),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [dispatch]
  )

  // fetch availableCategorie
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({
        type: SET_AVAILABLE_CATEGORIE,
        payload: x?.sort((a, b) => sortCategorie(a, b)),
      })

    if (fetchCategorie) {
      fetchAndDispatchFunction({
        url: `${cateCollection}/categorie/campionato/${selectedCampionato}${
          (pageURL === 'finali' && '/finali') ||
          (pageURL === 'designazioni' && '/referee') ||
          ''
        }`,
        errorText: 'le categorie',
        dispatchFunction,
      })
    } else {
      dispatchFunction([])
    }
  }, [
    cateCollection,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
    fetchCategorie,
    pageURL,
    selectedCampionato,
  ])

  // fetch availableGironi
  useEffect(() => {
    const dispatchFunction = x => {
      dispatch({
        type: SET_AVAILABLE_GIRONI,
        payload: x?.sort((a, b) => sortGironi(a, b)),
      })
    }

    if (fetchGironi) {
      fetchAndDispatchFunction({
        url: `${cateCollection}/gironi/campionato/${selectedCampionato}/categoria/${selectedCategoria}`,
        errorText: 'i gironi',
        dispatchFunction,
      })
    } else {
      dispatchFunction([])
    }
  }, [
    cateCollection,
    dispatch,
    fetchAndDispatchFunction,
    fetchGironi,
    selectedCampionato,
    selectedCategoria,
  ])

  // fetch availableTeams
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({
        type: SET_AVAILABLE_TEAMS,
        payload: x
          ?.map(t => ({
            ...t,
            nome: iscrizioniLight?.find(i => i.id === t.id)?.nomeSquadra,
          }))
          ?.sort(
            (a, b) =>
              sortGironi(a.girone, b.girone) ||
              sortAlphabetically(a.nome, b.nome)
          ),
      })

    if (fetchTeams) {
      fetchAndDispatchFunction({
        url: `${cateCollection}/teams/campionato/${selectedCampionato}/categoria/${selectedCategoria}`,
        errorText: 'le squadre',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    cateCollection,
    dispatch,
    fetchAndDispatchFunction,
    fetchTeams,
    iscrizioniLight,
    selectedCampionato,
    selectedCategoria,
  ])

  // fetch availableNumeriGara
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({
        type: SET_AVAILABLE_NUMERI_GARA,
        payload: x?.sort((a, b) => sortNumeriGara(a.numeroGara, b.numeroGara)),
      })

    if (fetchNumeriGara) {
      fetchAndDispatchFunction({
        url: `calendari/numeri-gara/campionato/${selectedCampionato}${
          pageURL === 'designazioni' ? '/referee' : ''
        }`,
        errorText: 'i numeri gara',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    dispatch,
    fetchAndDispatchFunction,
    fetchNumeriGara,
    pageURL,
    selectedCampionato,
  ])

  // fetch availablePeriodi
  useEffect(() => {
    const fetchAvailablePeriodi = async () => {
      dispatch({
        type: SET_AVAILABLE_PERIODI,
        payload: [
          'Passate',
          'Future',
          'Prossimi 10 giorni',
          'Prossimi 30 giorni',
          'Senza data',
        ],
      })
    }
    if (fetchPeriodi) {
      fetchAvailablePeriodi()
    } else {
      dispatch({ type: SET_AVAILABLE_PERIODI, payload: [] })
    }
  }, [dispatch, fetchPeriodi])

  // check selectedStagione
  useEffect(() => {
    if (!selectedStagione && availableStagioni?.length > 1)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedStagione'))
        if (availableStagioni.includes(storedValue)) {
          dispatch({ type: SET_SELECTED_STAGIONE, payload: storedValue })
        } else {
          resetSelectedStagione()
        }
      } catch {
        resetSelectedStagione()
      }

    if (
      selectedStagione &&
      availableStagioni?.length > 1 &&
      !availableStagioni?.includes(selectedStagione)
    )
      resetSelectedStagione()
  }, [availableStagioni, dispatch, resetSelectedStagione, selectedStagione])

  // check selectedCampionato
  useEffect(() => {
    if (!selectedCampionato && availableCampionati?.length > 0)
      try {
        const storedValue = JSON.parse(
          localStorage.getItem('selectedCampionato')
        )
        if (availableCampionati.includes(storedValue)) {
          dispatch({ type: SET_SELECTED_CAMPIONATO, payload: storedValue })
        } else {
          dispatch({
            type: SET_SELECTED_CAMPIONATO,
            payload: availableCampionati[0],
          })
        }
      } catch {
        resetSelectedCampionato()
      }

    if (
      selectedCampionato &&
      availableCampionati?.length > 0 &&
      !availableCampionati?.includes(selectedCampionato)
    )
      resetSelectedCampionato()
  }, [
    availableCampionati,
    dispatch,
    resetSelectedCampionato,
    selectedCampionato,
  ])

  // check selectedCategoria
  useEffect(() => {
    if (!selectedCategoria && availableCategorie?.length > 1)
      try {
        const storedValue = JSON.parse(
          localStorage.getItem('selectedCategoria')
        )
        if (availableCategorie.includes(storedValue)) {
          dispatch({ type: SET_SELECTED_CATEGORIA, payload: storedValue })
        } else {
          if (arbitro && pageURL === 'designazioni') {
            dispatch({
              type: SET_SELECTED_CATEGORIA,
              payload: availableCategorie[0],
            })
          } else {
            resetSelectedCategoria()
          }
        }
      } catch {
        resetSelectedCategoria()
      }

    if (
      selectedCategoria &&
      availableCategorie?.length > 1 &&
      !availableCategorie?.includes(selectedCategoria)
    )
      resetSelectedCategoria()
  }, [
    arbitro,
    availableCategorie,
    dispatch,
    pageURL,
    resetSelectedCategoria,
    selectedCategoria,
  ])

  // check selectedGirone
  useEffect(() => {
    if (!selectedGirone && availableGironi?.length > 1)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedGirone'))
        if (availableGironi.includes(storedValue)) {
          dispatch({ type: SET_SELECTED_GIRONE, payload: storedValue })
        } else {
          resetSelectedGirone()
        }
      } catch {
        resetSelectedGirone()
      }

    if (
      selectedGirone &&
      availableGironi?.length > 1 &&
      !availableGironi?.includes(selectedGirone)
    )
      resetSelectedGirone()
  }, [availableGironi, dispatch, resetSelectedGirone, selectedGirone])

  // check selectedTeam
  useEffect(() => {
    if (!selectedTeam && availableTeams?.length > 0) {
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedTeam'))
        if (availableTeams.map(t => t?.id).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_TEAM, payload: storedValue })
        } else {
          resetSelectedTeam()
        }
      } catch {
        resetSelectedTeam()
      }
    }

    if (
      selectedTeam &&
      availableTeams?.length > 0 &&
      !availableTeams?.map(t => t?.id).includes(selectedTeam)
    )
      resetSelectedTeam()
  }, [availableTeams, dispatch, resetSelectedTeam, selectedTeam])

  // check selectedNumeroGara
  useEffect(() => {
    if (!selectedNumeroGara && availableNumeriGara?.length > 0) {
      try {
        const storedValue = JSON.parse(
          localStorage.getItem('selectedNumeroGara')
        )
        if (availableNumeriGara.map(t => t?.numeroGara).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_NUMERO_GARA, payload: storedValue })
        } else {
          resetSelectedNumeroGara()
        }
      } catch {
        resetSelectedNumeroGara()
      }
    }

    if (
      selectedNumeroGara &&
      availableNumeriGara?.length > 0 &&
      !availableNumeriGara?.map(t => t?.numeroGara).includes(selectedNumeroGara)
    )
      resetSelectedNumeroGara()
  }, [
    availableNumeriGara,
    dispatch,
    resetSelectedNumeroGara,
    selectedNumeroGara,
  ])

  // check selectedPeriodo
  useEffect(() => {
    if (!selectedPeriodo && availablePeriodi?.length > 1)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedPeriodo'))
        if (availablePeriodi.includes(storedValue)) {
          dispatch({ type: SET_SELECTED_PERIODO, payload: storedValue })
        } else {
          resetSelectedPeriodo()
        }
      } catch {
        resetSelectedPeriodo()
      }

    if (
      selectedPeriodo &&
      availablePeriodi?.length > 0 &&
      !availablePeriodi?.includes(selectedPeriodo)
    )
      resetSelectedPeriodo()
  }, [availablePeriodi, dispatch, resetSelectedPeriodo, selectedPeriodo])

  // check selectedArbitro
  useEffect(() => {
    if (!selectedArbitro && availableArbitri?.length > 0)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedArbitro'))
        if (availableArbitri?.map(a => a?.id).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_ARBITRO, payload: storedValue })
        } else {
          resetSelectedArbitro()
        }
      } catch {
        resetSelectedArbitro()
      }

    if (
      selectedArbitro &&
      availableArbitri?.length > 0 &&
      !availableArbitri?.map(a => a?.id)?.includes(selectedArbitro)
    )
      resetSelectedArbitro()
  }, [availableArbitri, dispatch, resetSelectedArbitro, selectedArbitro])

  // check selectedSocieta
  useEffect(() => {
    if (!selectedSocieta && availableSocieta?.length > 0)
      try {
        const storedValue = JSON.parse(localStorage.getItem('selectedSocieta'))
        if (availableSocieta?.map(a => a?.id).includes(storedValue)) {
          dispatch({ type: SET_SELECTED_SOCIETA, payload: storedValue })
        } else {
          resetSelectedSocieta()
        }
      } catch {
        resetSelectedSocieta()
      }

    if (
      selectedSocieta &&
      availableSocieta?.length > 0 &&
      !availableSocieta?.map(a => a?.id)?.includes(selectedSocieta)
    )
      resetSelectedSocieta()
  }, [availableSocieta, dispatch, resetSelectedSocieta, selectedSocieta])

  return {
    setSelectedStagione,
    setSelectedCampionato,
    setSelectedCategoria,
    setSelectedGirone,
    setSelectedTeam,
    setSelectedNumeroGara,
    setSelectedPeriodo,
    setSelectedArbitro,
    setSelectedSocieta,
    resetSelectedStagione,
    resetSelectedCampionato,
    resetSelectedCategoria,
    resetSelectedGirone,
    resetSelectedTeam,
    resetSelectedNumeroGara,
    resetSelectedPeriodo,
    resetSelectedArbitro,
    resetSelectedSocieta,
  }
}
