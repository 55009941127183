import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import SelectionBar from '../components/SelectionBar'
import { sendRequest } from '../hooks/http-hook'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR, SET_DASHBOARD_STATS } from '../container/home/types'

const DashboardArbitro = ({
  infoUtente,
  availableStagioni,
  selectedStagione,
  dashboardStats,
  designazioni,
}) => {
  const { codiceUtente, idArbitro } = infoUtente

  document.title = 'PGS Milano - Home'

  const dispatch = useDispatch()

  const [filteredDesignazioni, setFilteredDesignazioni] = useState()

  useEffect(() => {
    setFilteredDesignazioni(designazioni?.filter(d => d.isValid))
  }, [designazioni])

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch rimborsi stats
  useEffect(() => {
    const fStagione =
      selectedStagione !== availableStagioni[0]
        ? `/stagione/${selectedStagione}`
        : ''

    const dispatchFunction = x =>
      dispatch({ type: SET_DASHBOARD_STATS, payload: { rimborsiStats: x } })

    if (idArbitro && selectedStagione) {
      fetchAndDispatchFunction({
        url: `rimborsi/stats/arbitro/${idArbitro}${fStagione}`,
        errorText: 'le statistiche sui rimborsi',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    availableStagioni,
    dispatch,
    fetchAndDispatchFunction,
    idArbitro,
    selectedStagione,
  ])

  return (
    <Grid
      container
      item
      xs={12}
      marginY={2}
      rowSpacing={5}
      alignItems='stretch'
    >
      <SelectionBar
        includeStagione={true}
        includeCampionato={false}
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      <GridCard
        title='Designazioni'
        subtitle1={`${
          filteredDesignazioni?.filter(d => d.acceptedByArbitro)?.length || 0
        } ${
          filteredDesignazioni?.filter(d => d.acceptedByArbitro)?.length === 1
            ? 'accettata'
            : 'accettate'
        }`}
        subtitle2={`${
          filteredDesignazioni?.filter(
            d => ![true, false].includes(d?.acceptedByArbitro)
          )?.length || 0
        } ${
          filteredDesignazioni?.filter(
            d => ![true, false].includes(d?.acceptedByArbitro)
          )?.length === 1
            ? 'da confermare'
            : 'da confermare'
        }`}
        linkTo='/designazioni'
        isError={
          filteredDesignazioni?.filter(
            d => ![true, false].includes(d?.acceptedByArbitro)
          )?.length > 0
        }
      />
      <GridCard
        title='Rimborsi'
        subtitle1={`${dashboardStats?.rimborsiStats?.pagati || 0} ${
          dashboardStats?.rimborsiStats?.pagati === 1 ? 'ricevuto' : 'ricevuti'
        }`}
        subtitle2={`${dashboardStats?.rimborsiStats?.nonPagati || 0} ${
          dashboardStats?.rimborsiStats?.nonPagati === 1
            ? 'da ricevere'
            : 'da ricevere'
        }`}
        linkTo='/rimborsi'
        isError={dashboardStats?.rimborsiStats?.nonPagati > 0}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  availableStagioni: state.home.availableStagioni,
  selectedStagione: state.home.selectedStagione,
  dashboardStats: state.home.dashboardStats,
  designazioni: state.designazioni.designazioni,
})

const ConnectedDashboardArbitro = connect(mapStateToProps)(DashboardArbitro)

export default ConnectedDashboardArbitro
