import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/it'

import { colours } from '../settings/settings'

const MyDatePicker = ({
  value,
  onChange,
  autoFocus = false,
  disabled = false,
  disablePast = false,
  disableFuture = false,
  error = false,
  readOnly = false,
  label = '',
  textAlign = 'left',
  variant = 'filled',
}) => {
  const [validationError, setValidationError] = useState()

  const theme = useTheme()
  const errTextColor = theme.palette.error.main
  const errBackColor = colours.redError

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='it'>
      <DatePicker
        value={
          value && dayjs(value, 'DD/MM/YYYY')?.isValid()
            ? dayjs(value, 'DD/MM/YYYY')
            : null
        }
        onChange={i => onChange && onChange(i?.format('DD/MM/YYYY'))}
        onError={i => setValidationError(!!(error || i))}
        autoFocus={!!autoFocus}
        disabled={!!disabled}
        disablePast={!!disablePast}
        disableFuture={!!disableFuture}
        readOnly={!!readOnly}
        label={label}
        closeOnSelect
        format='DD/MM/YYYY'
        localeText={{
          clearButtonLabel: 'Cancella',
          cancelButtonLabel: 'Annulla',
          datePickerToolbarTitle: 'Seleziona data',
        }}
        slotProps={{
          textField: {
            variant,
            clearable: true,
            fullWidth: true,
            error: validationError,
            sx: {
              '& .MuiInputBase-input': { textAlign },
              '& .MuiInputBase-root': {
                color: validationError && errTextColor,
                backgroundColor:
                  (disabled && 'transparent') ||
                  (validationError && errBackColor),
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}

export default MyDatePicker
