import React, { useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router'
import {
  AppBar,
  Box,
  Chip,
  Divider,
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

import DialogMini from './DialogMini'
import { colours } from '../settings/settings'
import { useAuth } from '../hooks/auth-hook'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import {
  SET_IS_OPEN_DRAWER,
  SET_OLD_PASSWORD,
  SET_NEW_PASSWORD,
} from '../container/home/types'

const Header = ({
  Title = 'PGS Milano',
  Subtitle = 'Piattaforma per i campionati di pallavolo',
  infoUtente,
  isOpenDrawer,
  oldPassword,
  newPassword,
}) => {
  const {
    auth,
    admin,
    societa,
    arbitro,
    designatore,
    omologatore,
    codiceUtente,
  } = infoUtente

  const dispatch = useDispatch()

  const history = useHistory()

  const handleClickLogin = () => history.push('/')

  const { login, logout } = useAuth(auth)

  const { setFeedback } = useFeedback()

  const toggleMainMenu = () =>
    dispatch({ type: SET_IS_OPEN_DRAWER, payload: !isOpenDrawer })

  // variables for the user menù
  const [anchorEl, setAnchorEl] = useState(null)
  const openUserMenu = Boolean(anchorEl)
  const handleClickOpenUserMenu = event => setAnchorEl(event.currentTarget)
  const handleCloseUserMenu = () => setAnchorEl(null)

  const [infoSocieta, setInfoSocieta] = useState()
  const [openInfoSocieta, setOpenInfoSocieta] = useState(false)

  const handleClickOpenInfoSocieta = async () => {
    getInfoSocieta()
  }

  const handleCloseInfoSocieta = () => {
    setOpenInfoSocieta(false)
    setInfoSocieta()
  }

  // variables for the dialog to change password
  const [openCambioPassword, setOpenCambioPassword] = useState(false)

  const handleClickOpenCambioPassword = () => setOpenCambioPassword(true)
  const handleCloseCambioPassword = () => {
    setOpenCambioPassword(false)
    dispatch({ type: SET_OLD_PASSWORD, payload: '' })
    dispatch({ type: SET_NEW_PASSWORD, payload: '' })
  }

  // fetch affiliazione
  const getInfoSocieta = async () => {
    try {
      if (!societa || !codiceUtente)
        throw new Error('Errore nella ricerca delle informazioni societarie.')

      const data = await sendRequest(
        `affiliazioni/codice/${codiceUtente}`,
        'GET',
        null,
        { Authorization: codiceUtente }
      )

      if (!data)
        throw new Error('Impossibile trovare le informazioni societarie.')

      setInfoSocieta(data.data)
      setOpenInfoSocieta(true)
    } catch (err) {
      setFeedback(err.message)
      setInfoSocieta()
    }
  }

  // change password and login again
  const changePassword = async () => {
    try {
      await sendRequest(
        'impostazioni/change-password',
        'POST',
        JSON.stringify({ oldPassword, newPassword }),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      setFeedback('Password aggiornata!', false)
      handleCloseCambioPassword()
      login(codiceUtente, newPassword)
    } catch (err) {
      setFeedback(err.message)
    }
  }

  return (
    <AppBar
      position='fixed'
      open={isOpenDrawer}
      sx={{
        color: colours.blueDark,
        backgroundColor: colours.headerBack,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Box sx={{ display: { xs: 'flex' }, flexGrow: 1 }}>
          <IconButton
            style={{ color: colours.blueDark }}
            onClick={toggleMainMenu}
          >
            {isOpenDrawer ? (
              <MenuOpenIcon fontSize='large' />
            ) : (
              <MenuIcon fontSize='large' />
            )}
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexGrow: 5 }}>
          <center>
            <a
              target='_blank'
              rel='noreferrer noopener'
              href='https://www.pgsmilano.org'
              style={{ textDecoration: 'none', color: colours.blueDark }}
            >
              <h2 style={{ margin: 0 }}>{Title}</h2>
            </a>
          </center>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 10 }}>
          <center>
            <h3 style={{ margin: 0 }}>{Subtitle}</h3>
          </center>
        </Box>
        <Box sx={{ display: { xs: 'flex' }, flexGrow: 0 }}>
          <center>
            {(auth && codiceUtente && (
              <>
                <Chip
                  variant='outlined'
                  style={{
                    fontWeight: 'bold',
                    color: colours.headerBack,
                    borderColor: colours.headerBack,
                    backgroundColor: colours.blueDark,
                  }}
                  label={
                    (admin && 'Amministratore') ||
                    (arbitro && codiceUtente) ||
                    (designatore && 'Designatore') ||
                    (omologatore && 'Omologatore') ||
                    (societa && `Società ${codiceUtente}`) ||
                    `Utente ${codiceUtente}`
                  }
                  onClick={handleClickOpenUserMenu}
                />
                <Menu
                  open={openUserMenu}
                  anchorEl={anchorEl}
                  onClick={handleCloseUserMenu}
                  onClose={handleCloseUserMenu}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  PaperProps={{
                    sx: {
                      marginTop: 1.5,
                      overflow: 'visible',
                      filter: `drop-shadow(0px 2px 8px ${colours.backDrop})`,
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                >
                  {societa && (
                    <MenuItem onClick={handleClickOpenInfoSocieta}>
                      <ListItemIcon>
                        <PersonIcon fontSize='small' />
                      </ListItemIcon>
                      Info società
                    </MenuItem>
                  )}
                  {(societa || arbitro) && (
                    <MenuItem onClick={handleClickOpenCambioPassword}>
                      <ListItemIcon>
                        <LockIcon fontSize='small' />
                      </ListItemIcon>
                      Cambio password
                    </MenuItem>
                  )}
                  {(societa || arbitro) && <Divider />}
                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <LogoutIcon fontSize='small' />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )) || (
              <Chip
                variant='outlined'
                style={{
                  fontWeight: 'bold',
                  color: colours.headerBack,
                  borderColor: colours.headerBack,
                  backgroundColor: colours.blueDark,
                }}
                label='Login'
                onClick={handleClickLogin}
              />
            )}
          </center>
        </Box>
      </Toolbar>
      <DialogMini
        open={openInfoSocieta}
        handleClose={handleCloseInfoSocieta}
        title='Informazioni societarie'
        textContent={
          <span>
            Codice PGS: <b>{infoSocieta?.codiceSocieta || '??'}</b>
            <br />
            Ragione sociale: <b>{infoSocieta?.denominazioneSocieta || '??'}</b>
            <br />
            <br />
            Referente: <b>{infoSocieta?.referente || '??'}</b>
            <br />
            Ruolo: <b>{infoSocieta?.ruolo || '??'}</b>
            <br />
            Telefono: <b>{infoSocieta?.telefono || '??'}</b>
            <br />
            Mail: <b>{infoSocieta?.mail || '??'}</b>
            <br />
            <br />
            Si prega di segnalare eventuali dati mancanti o errati a{' '}
            <a href='mailto:pgsmilano1@libero.it'>pgsmilano1@libero.it</a>.
          </span>
        }
        textConfirm='Ok'
        triggerFunction={handleCloseInfoSocieta}
      />
      <DialogMini
        open={openCambioPassword}
        handleClose={handleCloseCambioPassword}
        title='Cambio password'
        textUndo='Annulla'
        textConfirm='Conferma'
        triggerFunction={changePassword}
        dialogCambioPassword={true}
      />
    </AppBar>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  isOpenDrawer: state.home.isOpenDrawer,
  oldPassword: state.home.oldPassword,
  newPassword: state.home.newPassword,
})

const ConnectedHeader = connect(mapStateToProps)(Header)

export default ConnectedHeader
