import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import DownloadIcon from '@mui/icons-material/Download'

import Button from '../components/Button'
import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import SortableTableHead from '../components/SortableTableHead'
import ButtonRunDownload from '../components/ButtonRunDownload'
import GridCircularProgress from '../components/GridCircularProgress'
import {
  colours,
  titlesRendiconto as titles,
  phrasesRendiconto as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { runAlgorithm } from '../hooks/algoritmi-functions'
import { sortCodiceSocieta, sortStagioni } from '../hooks/sort-functions'
import { sumFun, formatEuro, fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_RENDICONTI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_RENDICONTO_MODE,
  SET_RENDICONTO,
  SET_ID_GARE_ARBITRO,
  SET_ID_GARE_RITARDO,
} from '../container/rendiconti/types'

const Rendiconti = ({
  infoUtente,
  dummyUpdate,
  affiliazioniLight,
  availableStagioni,
  selectedStagione,
  selectedSocieta,
  rendiconti,
  sortingColumn,
  sortingAscending,
  rendicontoMode,
  rendiconto,
  codiceSocietaAdd,
}) => {
  const { admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  document.title = 'PGS Milano - Rendiconti'

  let columnsRendiconti = [{ label: 'Stagione', sortable: false }]
  if (admin)
    columnsRendiconti = [
      ...columnsRendiconti,
      { label: 'Codice', sortable: true, sortingField: 'codiceSocieta' },
      { label: 'Società', sortable: true, sortingField: 'nomeSocieta' },
    ]
  if (!admin)
    columnsRendiconti = [
      ...columnsRendiconti,
      { label: 'Voci', sortable: false },
    ]
  columnsRendiconti = [
    ...columnsRendiconti,
    { label: 'Totale dovuto', sortable: false },
    { label: 'Totale pagato', sortable: false },
    { label: 'Bilancio', sortable: admin, sortingField: 'balance' },
    { label: 'Opzioni', sortable: false },
  ]

  const [loadingRendiconti, setLoadingRendiconti] = useState(false)
  const [rendicontiN, setRendicontiN] = useState()
  const [openRendiconto, setOpenRendiconto] = useState(false)
  const [openRendicontoAdd, setOpenRendicontoAdd] = useState(false)

  const { setFeedback } = useFeedback()

  const handleClickOpenRendiconto = id => {
    dispatch({
      type: SET_RENDICONTO_MODE,
      payload: admin ? 'update' : 'view',
    })
    dispatch({
      type: SET_RENDICONTO,
      payload: rendiconti?.find(r => r.id === id),
    })
    dispatch({
      type: SET_ID_GARE_ARBITRO,
      payload: rendiconti
        ?.find(r => r.id === id)
        ?.voci?.filter(
          v =>
            v.tipologia === 'needsReferee' || v.tipologia === 'requiresReferee'
        )
        ?.map(v => v.idGara),
    })
    dispatch({
      type: SET_ID_GARE_RITARDO,
      payload: rendiconti
        ?.find(r => r.id === id)
        ?.voci?.filter(v => v.tipologia === 'risultato-ritardo')
        ?.map(v => v.idGara),
    })
    setOpenRendiconto(true)
  }

  const handleClickOpenRendicontoAdd = () => setOpenRendicontoAdd(true)

  const handleCloseRendiconto = () => {
    setOpenRendiconto(false)
    dispatch({ type: SET_RENDICONTO_MODE })
    dispatch({ type: SET_RENDICONTO })
  }

  const handleCloseRendicontoAdd = () => setOpenRendicontoAdd(false)

  const confirmRendiconto = async () => {
    if (rendicontoMode === 'update') await updateRendiconto()
    if (rendicontoMode === 'view') handleCloseRendiconto()
  }

  const totTotaleDovuto = () =>
    formatEuro(sumFun(rendicontiN?.map(r => r.totaleDovuto)) || 0)
  const totTotalePagato = () =>
    formatEuro(sumFun(rendicontiN?.map(r => r.totalePagato)) || 0)

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch rendiconti
  useEffect(() => {
    const urlStagione =
      selectedStagione !== availableStagioni[0]
        ? `/stagione/${selectedStagione}`
        : '/all'

    const dispatchFunction = x => dispatch({ type: SET_RENDICONTI, payload: x })

    if (codiceUtente && (!admin || selectedStagione)) {
      fetchAndDispatchFunction({
        url: `rendiconti${admin ? urlStagione : `/societa/${codiceUtente}`}`,
        errorText: 'i rendiconti',
        dispatchLoading: x => setLoadingRendiconti(x),
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    admin,
    availableStagioni,
    codiceUtente,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
    selectedStagione,
  ])

  // add rendiconto
  const addRendiconto = async () => {
    if (codiceSocietaAdd) {
      try {
        await sendRequest(
          'rendiconti/add',
          'POST',
          JSON.stringify({
            codiceSocieta: codiceSocietaAdd,
            stagione: selectedStagione,
          }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRendicontoAdd()
        setFeedback(phrases.phraseAddSuccess, false, titles.titleAddSuccess)
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Società non trovata!')
    }
  }

  // upgrade rendiconto
  const upgradeRendiconto = async id => {
    if (id) {
      try {
        await sendRequest(`rendiconti/${id}`, 'PUT', null, {
          Authorization: codiceUtente,
        })
        handleCloseRendiconto()
      } catch (err) {
        setFeedback(err.message)
      }
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } else {
      setFeedback('Informazioni non trovate!')
    }
  }

  // update rendiconto
  const updateRendiconto = async () => {
    if (rendiconto?._id && rendiconto?.voci) {
      try {
        await sendRequest(
          `rendiconti/${rendiconto._id}`,
          'PATCH',
          JSON.stringify({ voci: rendiconto.voci }),
          { 'Content-Type': 'application/json', Authorization: codiceUtente }
        )
        handleCloseRendiconto()
        setFeedback(
          phrases.phraseUpdateSuccess,
          false,
          titles.titleUpdateSuccess
        )
        dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
      } catch (err) {
        setFeedback(err.message)
      }
    } else {
      setFeedback('Informazioni non trovate!')
    }
  }

  // add "nomeSocieta" to rendiconti
  useEffect(() => {
    if (!admin || affiliazioniLight)
      setRendicontiN(
        rendiconti
          ?.filter(r =>
            admin && selectedSocieta
              ? r.codiceSocieta ===
                affiliazioniLight?.find(a => a.id === selectedSocieta)
                  ?.codiceSocieta
              : true
          )
          ?.map(r => {
            r = { ...r, balance: r.totalePagato - r.totaleDovuto }
            if (admin) {
              const rSoc = affiliazioniLight?.find(
                a => a.codiceSocieta === r?.codiceSocieta
              )
              return {
                ...r,
                nomeSocieta: rSoc?.nomeSocieta || <i>Società non trovata</i>,
                codiceSocieta: +rSoc?.codiceSocieta || '-',
              }
            } else {
              return r
            }
          })
          ?.sort((a, b) => sortStagioni(a, b) || sortCodiceSocieta(a, b))
      )
  }, [admin, affiliazioniLight, rendiconti, selectedSocieta])

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Rendiconti</h2>
        </center>
      </Grid>
      {admin && (
        <>
          <SelectionBar
            includeStagione={true}
            includeCampionato={false}
            includeCategoria={false}
            includeGirone={false}
            includeTeam={false}
            includeSocieta={true}
          />
          &nbsp;
          <Grid container item xs={12} spacing={2} alignItems='center'>
            <Grid item xs={12} md={3}>
              <center>
                <Button onClick={handleClickOpenRendicontoAdd}>
                  Nuovo rendiconto
                </Button>
              </center>
            </Grid>
            <Grid item xs={12} md={3}>
              <center>
                <ButtonRunDownload
                  buttonText='Scarica tutti'
                  algorithm='15_GenerateExcelRendiconto'
                  type='XLSX'
                />
              </center>
            </Grid>
            {rendicontiN && (
              <>
                <Grid item xs={6} md={3}>
                  <center>
                    Totale dovuto: <b>{totTotaleDovuto()}</b>
                  </center>
                </Grid>
                <Grid item xs={6} md={3}>
                  <center>
                    Totale pagato: <b>{totTotalePagato()}</b>
                  </center>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
      {(loadingRendiconti && <GridCircularProgress />) ||
        (!rendiconti && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco dei rendiconti non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (rendicontiN?.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Nessun rendiconto trovato.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontiN}
                  setTable={setRendicontiN}
                  columns={columnsRendiconti}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {rendicontiN?.map(r => {
                    const isInDebit = r.balance < 0
                    const isInCredit = r.balance > 0
                    const isBalanced = r.balance === 0

                    const cellProps = {
                      align: 'center',
                      sx: { whiteSpace: 'nowrap' },
                    }

                    return (
                      <TableRow
                        key={r.id}
                        sx={{
                          cursor: 'pointer',
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                        onClick={() => handleClickOpenRendiconto(r.id)}
                      >
                        <TableCell {...cellProps}>{r?.stagione}</TableCell>
                        {admin && (
                          <TableCell {...cellProps}>
                            {r?.codiceSocieta}
                          </TableCell>
                        )}
                        {admin && (
                          <TableCell {...cellProps}>{r?.nomeSocieta}</TableCell>
                        )}
                        {!admin && (
                          <TableCell {...cellProps}>
                            {r?.voci?.length}&nbsp;
                            {r?.voci?.length === 1 ? 'voce' : 'voci'}
                          </TableCell>
                        )}
                        <TableCell {...cellProps}>
                          {formatEuro(r?.totaleDovuto)}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {formatEuro(r?.totalePagato)}
                        </TableCell>
                        <TableCell align='center'>
                          <Chip
                            variant={isBalanced ? 'outlined' : 'filled'}
                            label={
                              (isInDebit && 'da pagare') ||
                              (isInCredit && 'in credito') ||
                              (isBalanced && 'in pari') ||
                              '??'
                            }
                            color={
                              (isInDebit && 'warning') ||
                              (isInCredit && 'info') ||
                              (isBalanced && 'success') ||
                              'error'
                            }
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <Grid container item>
                            <Grid item xs>
                              <IconButton
                                style={{ color: colours.greenExcel }}
                                onClick={e => {
                                  e.stopPropagation()
                                  runAlgorithm({
                                    algorithm: '15_GenerateExcelRendiconto',
                                    selectedRendiconto: r._id,
                                    triggerDownload: true,
                                    exactlyOne: true,
                                    type: 'XLSX',
                                    codiceUtente,
                                    setFeedback,
                                  })
                                }}
                              >
                                <Tooltip title='Scarica Excel'>
                                  <DownloadIcon />
                                </Tooltip>
                              </IconButton>
                            </Grid>
                            {admin && (
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.greyLight }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    upgradeRendiconto(r._id)
                                  }}
                                >
                                  <Tooltip title='Aggiorna'>
                                    <RefreshIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openRendiconto}
        handleClose={handleCloseRendiconto}
        title={titles.titleRendiconto}
        textUndo={rendicontoMode === 'view' || !admin ? 'Chiudi' : 'Annulla'}
        textConfirm={rendicontoMode !== 'view' && admin && 'Conferma'}
        triggerFunction={confirmRendiconto}
        dialogRendiconto={true}
      />
      <DialogMini
        open={openRendicontoAdd}
        handleClose={handleCloseRendicontoAdd}
        title={titles.titleRendiconto}
        textUndo='Annulla'
        textConfirm='Conferma'
        triggerFunction={addRendiconto}
        dialogRendicontoAdd={true}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  dummyUpdate: state.home.dummyUpdate,
  affiliazioniLight: state.home.affiliazioniLight,
  availableStagioni: state.home.availableStagioni,
  selectedStagione: state.home.selectedStagione,
  selectedSocieta: state.home.selectedSocieta,
  rendiconti: state.rendiconti.rendiconti,
  sortingColumn: state.rendiconti.sortingColumn,
  sortingAscending: state.rendiconti.sortingAscending,
  rendicontoMode: state.rendiconti.rendicontoMode,
  rendiconto: state.rendiconti.rendiconto,
  codiceSocietaAdd: state.rendiconti.codiceSocietaAdd,
})

const ConnectedRendiconti = connect(mapStateToProps)(Rendiconti)

export default ConnectedRendiconti
