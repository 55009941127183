import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'

import DatePicker from './DatePicker'
import InputField from './InputField'
import SortableTableHead from './SortableTableHead'
import GridCircularProgress from './GridCircularProgress'
import { colours } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { errorNumber0 } from '../hooks/error-functions'
import {
  sortAlphabetically,
  sortCampionati,
  sortCategorie,
  sortNumeriGara,
} from '../hooks/sort-functions'
import { sumFun, formatEuro, fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import {
  SET_RENDICONTO,
  SET_ID_GARE_ARBITRO,
  SET_ID_GARE_RITARDO,
} from '../container/rendiconti/types'

// error functions
const errorImporto = x => x && errorNumber0(x)

const DialogRendiconto = ({
  infoUtente,
  campionati,
  affiliazioniLight,
  rendicontoMode,
  rendiconto,
  idGareArbitro,
  idGareRitardo,
}) => {
  const { admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  const isView = rendicontoMode === 'view'

  let columnsQuota = [
    { label: 'Causale', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsIscrizioni = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsSpostamenti = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Gara', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsGareArbitro = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Gara', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsRisultatiRitardo = [
    { label: 'Campionato', sortable: false },
    { label: 'Categoria', sortable: false },
    { label: 'Gara', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  let columnsSanzioni = [
    { label: 'Descrizione', sortable: false },
    { label: 'Importo', sortable: false },
    { label: 'Note', sortable: false },
    { label: 'Pagamento', sortable: false },
  ]

  const codiceSocieta = rendiconto?.codiceSocieta

  const [loadingIscrizioni, setLoadingIscrizioni] = useState()
  const [loadingSpostamenti, setLoadingSpostamenti] = useState()
  const [loadingGareArbitro, setLoadingGareArbitro] = useState()
  const [loadingGareRitardo, setLoadingGareRitardo] = useState()
  const loadingSanzioni = false
  const [nomeSocieta, setNomeSocieta] = useState()
  const [rendicontoQuota, setRendicontoQuota] = useState()
  const [rendicontoIscrizioni, setRendicontoIscrizioni] = useState()
  const [rendicontoSpostamenti, setRendicontoSpostamenti] = useState()
  const [rendicontoGareArbitro, setRendicontoGareArbitro] = useState()
  const [rendicontoRisultatiRitardo, setRendicontoRisultatiRitardo] = useState()
  const [rendicontoSanzioni, setRendicontoSanzioni] = useState()
  const [societaIscrizioni, setSocietaIscrizioni] = useState()
  const [societaSpostamenti, setSocietaSpostamenti] = useState()
  const [societaGareArbitro, setSocietaGareArbitro] = useState()
  const [societaGareRitardo, setSocietaGareRitardo] = useState()
  const [newSanzione, setNewSanzione] = useState()

  const nCampionato = x => campionati?.find(c => c.codice === x)?.nome

  const totTotaleDovuto = () =>
    sumFun(rendiconto?.voci?.map(v => v.importo)) || 0
  const totTotalePagato = () =>
    sumFun(rendiconto?.voci?.filter(v => v.datePaid)?.map(v => v.importo)) || 0

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch iscrizioni
  useEffect(() => {
    const dispatchFunction = x => setSocietaIscrizioni(x)

    if (codiceSocieta) {
      fetchAndDispatchFunction({
        url: `iscrizioni/societa/${codiceSocieta}`,
        errorText: 'le iscrizioni',
        dispatchLoading: x => setLoadingIscrizioni(x),
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [codiceSocieta, fetchAndDispatchFunction])

  // fetch spostamenti
  useEffect(() => {
    const dispatchFunction = x => setSocietaSpostamenti(x)

    if (codiceSocieta) {
      fetchAndDispatchFunction({
        url: `spostamenti/societa/${codiceSocieta}`,
        errorText: 'gli spostamenti',
        dispatchLoading: x => setLoadingSpostamenti(x),
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [codiceSocieta, fetchAndDispatchFunction])

  // fetch gare arbitro
  useEffect(() => {
    const fetchGareArbitro = async () => {
      setLoadingGareArbitro(true)
      try {
        const data = await sendRequest(
          `calendari/many`,
          'POST',
          JSON.stringify({ ids: idGareArbitro }),
          { 'Content-Type': 'application/json' }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          setSocietaGareArbitro(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingGareArbitro(false)
    }
    if (idGareArbitro) {
      fetchGareArbitro()
    } else {
      setSocietaGareArbitro()
    }
  }, [dispatch, idGareArbitro])

  // fetch gare ritardo
  useEffect(() => {
    const fetchGareRitardo = async () => {
      setLoadingGareRitardo(true)
      try {
        const data = await sendRequest(
          `calendari/many`,
          'POST',
          JSON.stringify({ ids: idGareRitardo }),
          { 'Content-Type': 'application/json' }
        )

        if (!data) {
          dispatch({
            type: SET_ERROR,
            payload: 'Impossibile trovare le partite.',
          })
        } else {
          setSocietaGareRitardo(data)
        }
      } catch (err) {
        console.log(err)
        dispatch({ type: SET_ERROR, payload: err.message })
      }
      setLoadingGareRitardo(false)
    }
    if (idGareRitardo) {
      fetchGareRitardo()
    } else {
      setSocietaGareRitardo()
    }
  }, [dispatch, idGareRitardo])

  // update nomeSocieta
  useEffect(() => {
    setNomeSocieta(
      admin &&
        affiliazioniLight?.find(a => a.codiceSocieta === codiceSocieta)
          ?.nomeSocieta
    )
  }, [admin, affiliazioniLight, codiceSocieta])

  // filter quota
  useEffect(() => {
    setRendicontoQuota(
      rendiconto?.voci?.filter(v => v.tipologia === 'quota-segreteria')
    )
  }, [rendiconto])

  // filter iscrizioni
  useEffect(() => {
    setRendicontoIscrizioni(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'iscrizione')
        ?.map(v => {
          const vInfo = societaIscrizioni?.find(s => s._id === v.idSquadra)
          return {
            ...v,
            indiceCampionato: campionati?.find(
              c => c.codice === vInfo?.codiceCampionato
            )?.indice,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
          }
        })
        ?.sort(
          (a, b) =>
            sortCampionati(a.indiceCampionato, b.indiceCampionato) ||
            sortCategorie(a.categoria, b.categoria)
        )
    )
  }, [campionati, rendiconto, societaIscrizioni])

  // filter spostamenti
  useEffect(() => {
    setRendicontoSpostamenti(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'spostamento')
        ?.map(v => {
          const vInfo = societaSpostamenti?.find(s => s._id === v.idSpostamento)
          return {
            ...v,
            indiceCampionato: campionati?.find(
              c => c.codice === vInfo?.codiceCampionato
            )?.indice,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
            numeroGara: vInfo?.numeroGara,
          }
        })
        ?.sort(
          (a, b) =>
            sortCampionati(a.indiceCampionato, b.indiceCampionato) ||
            sortCategorie(a.categoria, b.categoria) ||
            sortNumeriGara(a.numeroGara, b.numeroGara)
        )
    )
  }, [campionati, rendiconto, societaSpostamenti])

  // filter gare arbitro
  useEffect(() => {
    setRendicontoGareArbitro(
      rendiconto?.voci
        ?.filter(
          v =>
            v.tipologia === 'needsReferee' || v.tipologia === 'requiresReferee'
        )
        ?.map(v => {
          const vInfo = societaGareArbitro?.find(s => s._id === v.idGara)
          return {
            ...v,
            indiceCampionato: campionati?.find(
              c => c.codice === vInfo?.codiceCampionato
            )?.indice,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
            numeroGara: vInfo?.numeroGara,
          }
        })
        ?.sort(
          (a, b) =>
            sortCampionati(a.indiceCampionato, b.indiceCampionato) ||
            sortCategorie(a.categoria, b.categoria) ||
            sortNumeriGara(a.numeroGara, b.numeroGara)
        )
    )
  }, [campionati, rendiconto, societaGareArbitro])

  // filter risultati ritardo
  useEffect(() => {
    setRendicontoRisultatiRitardo(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'risultato-ritardo')
        ?.map(v => {
          const vInfo = societaGareRitardo?.find(s => s._id === v.idGara)
          return {
            ...v,
            indiceCampionato: campionati?.find(
              c => c.codice === vInfo?.codiceCampionato
            )?.indice,
            codiceCampionato: vInfo?.codiceCampionato,
            categoria: vInfo?.categoria,
            numeroGara: vInfo?.numeroGara,
          }
        })
        ?.sort(
          (a, b) =>
            sortCampionati(a.indiceCampionato, b.indiceCampionato) ||
            sortCategorie(a.categoria, b.categoria) ||
            sortNumeriGara(a.numeroGara, b.numeroGara)
        )
    )
  }, [campionati, rendiconto, societaGareRitardo])

  // filter sanzioni
  useEffect(() => {
    setRendicontoSanzioni(
      rendiconto?.voci
        ?.filter(v => v.tipologia === 'sanzione')
        ?.sort((a, b) => sortAlphabetically(a.descrizione, b.descrizione))
    )
  }, [rendiconto])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => {
      dispatch({ type: SET_RENDICONTO })
      dispatch({ type: SET_ID_GARE_ARBITRO })
      dispatch({ type: SET_ID_GARE_RITARDO })
    }
  }, [dispatch])

  const fTitle = title => (
    <Grid item xs={12}>
      <center>
        <h4>{title}</h4>
      </center>
    </Grid>
  )

  const alignProps = { align: 'center' }
  const sxProps = { sx: { whiteSpace: 'nowrap' } }
  const fStyleProps = (x, i) => {
    return {
      style: {
        borderBottomColor:
          i < x.length - 1 &&
          x[i].codiceCampionato !== x[i + 1].codiceCampionato &&
          colours.greyVeryDark,
      },
    }
  }

  const rDispatch = (x, r) =>
    dispatch({
      type: SET_RENDICONTO,
      payload: {
        ...rendiconto,
        voci: rendiconto?.voci?.map(v =>
          v._id === r._id ? { ...v, ...x } : v
        ),
      },
    })

  const rInputField = (r, field, aDispatch, type, errorFunc) =>
    type === 'date' ? (
      isView ? (
        r[field]
      ) : (
        <DatePicker
          value={r[field]}
          onChange={x => aDispatch({ [field]: x || undefined })}
          readOnly={isView}
          textAlign='center'
          variant='standard'
        />
      )
    ) : (
      <InputField
        align='center'
        variant='standard'
        placeholder={type === 'number' ? '0.00' : ''}
        value={
          (type === 'number' &&
            (typeof r[field] === 'number' ? r.importo : undefined)) ||
          r[field]
        }
        onChange={x =>
          aDispatch({ [field]: type === 'number' ? +x?.toFixed(2) : x })
        }
        errorFunc={errorFunc}
        type={type}
        readOnly={isView}
      />
    )

  return (
    <Grid container item xs={12} spacing={3}>
      <Grid container item xs={12} spacing={2} alignItems='center'>
        <Grid item xs={12} md={6}>
          <center>
            {admin ? (
              <>
                {codiceSocieta} - <b>{nomeSocieta}</b> - Stagione{' '}
                {rendiconto?.stagione}
              </>
            ) : (
              <>Stagione {rendiconto?.stagione}</>
            )}
          </center>
        </Grid>
        <Grid item xs={12} md={3}>
          <center>
            <b>Dovuto:&nbsp;{formatEuro(totTotaleDovuto() || 0)}</b>
          </center>
        </Grid>
        <Grid item xs={12} md={3}>
          <center>
            <b>Pagato:&nbsp;{formatEuro(totTotalePagato() || 0)}</b>
          </center>
        </Grid>
      </Grid>
      &nbsp;
      {rendicontoQuota?.length > 0 && (
        <Grid container item xs={12} alignItems='center'>
          <TableContainer component={Paper}>
            <Table size='small'>
              <SortableTableHead
                table={rendicontoQuota}
                columns={columnsQuota}
              />
              <TableBody>
                {rendicontoQuota?.map((r, index) => {
                  const cellProps = {
                    align: 'center',
                    sx: { whiteSpace: 'nowrap' },
                  }

                  const aDispatch = x => rDispatch(x, r)

                  const aInputField = (field, type, errorFunc) =>
                    rInputField(r, field, aDispatch, type, errorFunc)

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          r.importo > 0 && !r.datePaid && colours.highlight,
                      }}
                    >
                      <TableCell {...cellProps}>Quota di segreteria</TableCell>
                      <TableCell {...cellProps} width={window.innerWidth * 0.1}>
                        {isView || !admin
                          ? formatEuro(r.importo)
                          : aInputField('importo', 'number', errorImporto)}
                      </TableCell>
                      <TableCell {...cellProps} width={window.innerWidth * 0.2}>
                        {isView || !admin
                          ? formatEuro(r.note)
                          : aInputField('note')}
                      </TableCell>
                      <TableCell {...cellProps} width={window.innerWidth * 0.2}>
                        {aInputField('datePaid', 'date')}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {(loadingIscrizioni && <GridCircularProgress />) ||
        (rendicontoIscrizioni?.length > 0 && (
          <Grid container item xs={12} alignItems='center'>
            {fTitle('Iscrizioni')}
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontoIscrizioni}
                  columns={columnsIscrizioni}
                />
                <TableBody>
                  {rendicontoIscrizioni?.map((r, index) => {
                    const cellProps = {
                      ...alignProps,
                      ...sxProps,
                      ...fStyleProps(rendicontoIscrizioni, index),
                    }

                    const aDispatch = x => rDispatch(x, r)

                    const aInputField = (field, type, errorFunc) =>
                      rInputField(r, field, aDispatch, type, errorFunc)

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            r.importo > 0 && !r.datePaid && colours.highlight,
                        }}
                      >
                        <TableCell {...cellProps}>
                          {nCampionato(r.codiceCampionato) || '??'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {r.categoria || '??'}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.1}
                        >
                          {isView || !admin
                            ? formatEuro(r.importo)
                            : aInputField('importo', 'number', errorImporto)}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {isView || !admin
                            ? formatEuro(r.note)
                            : aInputField('note')}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {aInputField('datePaid', 'date')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      {(loadingSpostamenti && <GridCircularProgress />) ||
        (rendicontoSpostamenti?.length > 0 && (
          <Grid container item xs={12} alignItems='center'>
            {fTitle('Spostamenti')}
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontoSpostamenti}
                  columns={columnsSpostamenti}
                />
                <TableBody>
                  {rendicontoSpostamenti?.map((r, index) => {
                    const cellProps = {
                      ...alignProps,
                      ...sxProps,
                      ...fStyleProps(rendicontoSpostamenti, index),
                    }

                    const aDispatch = x => rDispatch(x, r)

                    const aInputField = (field, type, errorFunc) =>
                      rInputField(r, field, aDispatch, type, errorFunc)

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            r.importo > 0 && !r.datePaid && colours.highlight,
                        }}
                      >
                        <TableCell {...cellProps}>
                          {nCampionato(r.codiceCampionato) || '??'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {r.categoria || '??'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {r.numeroGara || '??'}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.1}
                        >
                          {isView || !admin
                            ? formatEuro(r.importo)
                            : aInputField('importo', 'number', errorImporto)}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {isView || !admin
                            ? formatEuro(r.note)
                            : aInputField('note')}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {aInputField('datePaid', 'date')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      {(loadingGareArbitro && <GridCircularProgress />) ||
        (rendicontoGareArbitro?.length > 0 && (
          <Grid container item xs={12} alignItems='center'>
            {fTitle('Tasse gara')}
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontoGareArbitro}
                  columns={columnsGareArbitro}
                />
                <TableBody>
                  {rendicontoGareArbitro?.map((r, index) => {
                    const cellProps = {
                      ...alignProps,
                      ...sxProps,
                      ...fStyleProps(rendicontoGareArbitro, index),
                    }

                    const aDispatch = x => rDispatch(x, r)

                    const aInputField = (field, type, errorFunc) =>
                      rInputField(r, field, aDispatch, type, errorFunc)

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            r.importo > 0 && !r.datePaid && colours.highlight,
                        }}
                      >
                        <TableCell {...cellProps}>
                          {nCampionato(r.codiceCampionato) || '??'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {r.categoria || '??'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {r.numeroGara || '??'}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.1}
                        >
                          {isView || !admin
                            ? formatEuro(r.importo)
                            : aInputField('importo', 'number', errorImporto)}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {isView || !admin
                            ? formatEuro(r.note)
                            : aInputField('note')}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {aInputField('datePaid', 'date')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      {(loadingGareRitardo && <GridCircularProgress />) ||
        (rendicontoRisultatiRitardo?.length > 0 && (
          <Grid container item xs={12} alignItems='center'>
            {fTitle('Sanzioni per risultati inviati in ritardo')}
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontoRisultatiRitardo}
                  columns={columnsRisultatiRitardo}
                />
                <TableBody>
                  {rendicontoRisultatiRitardo?.map((r, index) => {
                    const cellProps = {
                      ...alignProps,
                      ...sxProps,
                      ...fStyleProps(rendicontoRisultatiRitardo, index),
                    }

                    const aDispatch = x => rDispatch(x, r)

                    const aInputField = (field, type, errorFunc) =>
                      rInputField(r, field, aDispatch, type, errorFunc)

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            r.importo > 0 && !r.datePaid && colours.highlight,
                        }}
                      >
                        <TableCell {...cellProps}>
                          {nCampionato(r.codiceCampionato) || '??'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {r.categoria || '??'}
                        </TableCell>
                        <TableCell {...cellProps}>
                          {r.numeroGara || '??'}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.1}
                        >
                          {isView || !admin
                            ? formatEuro(r.importo)
                            : aInputField('importo', 'number', errorImporto)}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {isView || !admin
                            ? formatEuro(r.note)
                            : aInputField('note')}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {aInputField('datePaid', 'date')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      {(loadingSanzioni && <GridCircularProgress />) ||
        (rendicontoSanzioni?.length > 0 && (
          <Grid container item xs={12} alignItems='center'>
            {fTitle('Sanzioni')}
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={rendicontoSanzioni}
                  columns={columnsSanzioni}
                />
                <TableBody>
                  {rendicontoSanzioni?.map((r, index) => {
                    const cellProps = { ...alignProps, ...sxProps }

                    const aDispatch = x => rDispatch(x, r)

                    const aInputField = (field, type, errorFunc) =>
                      rInputField(r, field, aDispatch, type, errorFunc)

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            r.importo > 0 && !r.datePaid && colours.highlight,
                        }}
                      >
                        <TableCell {...cellProps}>
                          {r.descrizione || '??'}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.1}
                        >
                          {isView || !admin
                            ? formatEuro(r.importo)
                            : aInputField('importo', 'number', errorImporto)}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {isView || !admin
                            ? formatEuro(r.note)
                            : aInputField('note')}
                        </TableCell>
                        <TableCell
                          {...cellProps}
                          width={window.innerWidth * 0.2}
                        >
                          {aInputField('datePaid', 'date')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      {admin && (
        <Grid container item xs={12} alignItems='center'>
          {newSanzione ? (
            <>
              <Grid item xs={10}>
                <center>
                  <InputField
                    id='descrizione'
                    value={newSanzione}
                    onChange={setNewSanzione}
                    label={columnsSanzioni?.[0].label}
                  />
                </center>
              </Grid>
              <Grid item xs={1}>
                <center>
                  <IconButton
                    style={{ color: colours.blueDark }}
                    onClick={() => {
                      dispatch({
                        type: SET_RENDICONTO,
                        payload: {
                          ...rendiconto,
                          voci: [
                            ...rendiconto?.voci,
                            {
                              _id: new Date().getTime(), // temporary id, will be reset by the backend
                              tipologia: 'sanzione',
                              descrizione: newSanzione,
                            },
                          ],
                        },
                      })
                      setNewSanzione()
                    }}
                  >
                    <Tooltip key='Salva' title='Salva'>
                      <SaveIcon />
                    </Tooltip>
                  </IconButton>
                </center>
              </Grid>
              <Grid item xs={1}>
                <center>
                  <IconButton
                    style={{ color: colours.red }}
                    onClick={() => setNewSanzione()}
                  >
                    <Tooltip key='Annulla' title='Annulla'>
                      <ClearIcon />
                    </Tooltip>
                  </IconButton>
                </center>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <center>
                <IconButton
                  size='small'
                  style={{ color: colours.black }}
                  onClick={() =>
                    setNewSanzione(
                      'Comunicato XX del DD/MM/YYYY, provvedimento NN'
                    )
                  }
                >
                  <AddIcon />
                  &nbsp; Aggiungi sanzione
                </IconButton>
              </center>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  campionati: state.home.campionati,
  affiliazioniLight: state.home.affiliazioniLight,
  rendicontoMode: state.rendiconti.rendicontoMode,
  rendiconto: state.rendiconti.rendiconto,
  idGareArbitro: state.rendiconti.idGareArbitro,
  idGareRitardo: state.rendiconti.idGareRitardo,
})

const ConnectedDialogRendiconto = connect(mapStateToProps)(DialogRendiconto)

export default ConnectedDialogRendiconto
