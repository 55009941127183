import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { sendRequest } from '../hooks/http-hook'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import { SET_DELETE_ALSO_ISCRIZIONI } from '../container/affiliazioni/types'

const DialogDeleteAffiliazione = ({
  affiliazione,
  infoUtente,
  deleteAlsoIscrizioni,
}) => {
  const { admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  const [numeroIscrizioni, setNumeroIscrizioni] = useState()

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch numero iscrizioni
  useEffect(() => {
    const dispatchFunction = x => setNumeroIscrizioni(x?.length)

    if (admin && affiliazione?.codiceSocieta) {
      fetchAndDispatchFunction({
        url: `iscrizioni/societa/${affiliazione.codiceSocieta}`,
        errorText: 'le iscrizioni',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [admin, affiliazione, fetchAndDispatchFunction])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_DELETE_ALSO_ISCRIZIONI })
  }, [dispatch])

  return !admin ? null : (
    <>
      &nbsp;
      {(!affiliazione && (
        <Grid item xs={12}>
          Nessuna società selezionata.
        </Grid>
      )) ||
        (numeroIscrizioni === 0 && (
          <Grid item xs={12}>
            La società selezionata non ha ancora iscritto nessuna squadra.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              {numeroIscrizioni === 1
                ? `C'è ${numeroIscrizioni} iscrizione effettuata`
                : `Ci sono ${numeroIscrizioni} iscrizioni effettuate`}{' '}
              da questa società. Selezionare la seguente casella se si intendono
              eliminare dalla piattaforma anche tutte le iscrizioni e le
              relative partite e spostamenti; lasciarla vuota se invece si
              intendono mantenere.
            </Grid>
            &nbsp;
            {!!affiliazione && (
              <Grid
                container
                item
                xs={12}
                spacing={3}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>
                  <center>Eliminare anche le iscrizioni?</center>
                </Grid>
                <Grid item>
                  <center>
                    <Checkbox
                      id='deleteAlsoIscrizioni'
                      checked={!!deleteAlsoIscrizioni}
                      onChange={event =>
                        dispatch({
                          type: SET_DELETE_ALSO_ISCRIZIONI,
                          payload: event.target.checked,
                        })
                      }
                    />
                  </center>
                </Grid>
              </Grid>
            )}
            &nbsp;
          </Grid>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  deleteAlsoIscrizioni: state.affiliazioni.deleteAlsoIscrizioni,
})

const ConnectedDialogDeleteAffiliazione = connect(mapStateToProps)(
  DialogDeleteAffiliazione
)

export default ConnectedDialogDeleteAffiliazione
