import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { sendRequest } from '../hooks/http-hook'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import { SET_DELETE_ALSO_DESIGNAZIONI } from '../container/arbitri/types'

const DialogDeleteArbitro = ({
  idArbitro,
  infoUtente,
  deleteAlsoDesignazioni,
}) => {
  const { admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  const [numeroDesignazioni, setNumeroDesignazioni] = useState()

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch numero designazioni
  useEffect(() => {
    const dispatchFunction = x => setNumeroDesignazioni(x?.length)

    if (admin && codiceUtente && idArbitro) {
      fetchAndDispatchFunction({
        url: `designazioni/arbitro/${idArbitro}`,
        errorText: 'le designazioni',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [admin, codiceUtente, fetchAndDispatchFunction, idArbitro])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_DELETE_ALSO_DESIGNAZIONI })
  }, [dispatch])

  return !admin ? null : (
    <>
      &nbsp;
      {(!idArbitro && (
        <Grid item xs={12}>
          Nessun arbitro selezionato.
        </Grid>
      )) ||
        (numeroDesignazioni === 0 && (
          <Grid item xs={12}>
            L'arbitro selezionato non ha ancora ricevuto nessuna designazione.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              {numeroDesignazioni === 1
                ? `C'è ${numeroDesignazioni} designazione legata`
                : `Ci sono ${numeroDesignazioni} designazioni legate`}{' '}
              a questo arbitro. Selezionare la seguente casella se si intendono
              eliminare dalla piattaforma anche tutte le designazioni; lasciarla
              vuota se invece si intendono mantenere.
              <br />
              <br />
              ATTENZIONE: eliminando questo arbitro, i suoi eventuali rimborsi
              non saranno più riconducibili a lui e mostreranno l'avviso
              "Arbitro non trovato".
            </Grid>
            &nbsp;
            {idArbitro && (
              <Grid
                container
                item
                xs={12}
                spacing={3}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>
                  <center>Eliminare anche le designazioni?</center>
                </Grid>
                <Grid item>
                  <center>
                    <Checkbox
                      id='deleteAlsoDesignazioni'
                      checked={!!deleteAlsoDesignazioni}
                      onChange={event =>
                        dispatch({
                          type: SET_DELETE_ALSO_DESIGNAZIONI,
                          payload: event.target.checked,
                        })
                      }
                    />
                  </center>
                </Grid>
              </Grid>
            )}
            &nbsp;
          </Grid>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  deleteAlsoDesignazioni: state.arbitri.deleteAlsoDesignazioni,
})

const ConnectedDialogDeleteArbitro =
  connect(mapStateToProps)(DialogDeleteArbitro)

export default ConnectedDialogDeleteArbitro
