import React, { useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import GridCard from '../components/GridCard'
import SelectionBar from '../components/SelectionBar'
import { sendRequest } from '../hooks/http-hook'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR, SET_DASHBOARD_STATS } from '../container/home/types'

const DashboardAdmin = ({
  infoUtente,
  campionati,
  dashboardStats,
  selectedCampionatoGironiPubblicati,
  selectedCampionatoCalendariPubblicati,
  selectedCampionato,
}) => {
  const { codiceUtente } = infoUtente

  const dispatch = useDispatch()

  document.title = 'PGS Milano - Home'

  const fetchStats = useCallback(
    async (url, errorText, key, onlyLength) => {
      fetchAndDispatch({
        url,
        errorText: `le statistiche ${errorText}`,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
        dispatchFunction: x =>
          dispatch({
            type: SET_DASHBOARD_STATS,
            payload: { [key]: onlyLength ? x?.length : x },
          }),
      })
    },
    [codiceUtente, dispatch]
  )

  // fetch all stats
  useEffect(() => {
    if (codiceUtente) {
      fetchStats('affiliazioni/stats', 'sulle società', 'affiliazioniStats')
      fetchStats('arbitri/stats', 'sugli arbitri', 'arbitriStats')
      fetchStats('rendiconti/stats', 'sui rendiconti', 'rendicontiStats')

      if (selectedCampionato) {
        fetchStats(
          `iscrizioni/stats/campionato/${selectedCampionato}`,
          'sulle iscrizioni',
          'iscrizioniStats'
        )
        fetchStats(
          `spostamenti/stats/campionato/${selectedCampionato}`,
          'sugli spostamenti',
          'spostamentiStats'
        )
        fetchStats(
          `designazioni/stats/campionato/${selectedCampionato}`,
          'sulle designazioni',
          'designazioniStats'
        )
        fetchStats(
          `calendari/stats/campionato/${selectedCampionato}`,
          'sulle partite',
          'partiteStats'
        )
        fetchStats(
          `calendari/categorie/campionato/${selectedCampionato}/finali`,
          'sulle fasi finali',
          'tabelloniStats'
        )
        fetchStats(
          `calendari/risultati-mancanti/campionato/${selectedCampionato}`,
          'sui risultati mancanti',
          'risultatiMancanti',
          true
        )
        fetchStats(
          `calendari/referti-mancanti/campionato/${selectedCampionato}`,
          'sui referti mancanti',
          'refertiMancanti',
          true
        )
        fetchStats(
          `arbitraggi/stats/campionato/${selectedCampionato}`,
          'sugli arbitraggi',
          'arbitraggiStats'
        )

        if (campionati) {
          const selectedStagione = campionati?.find(
            c => c.codice === selectedCampionato
          )?.stagione

          fetchStats(
            `rimborsi/stats/stagione/${selectedStagione}`,
            'sui rimborsi',
            'rimborsiStats'
          )
        }
      }
    }
  }, [campionati, codiceUtente, fetchStats, selectedCampionato])

  return (
    <Grid
      container
      item
      xs={12}
      marginY={2}
      rowSpacing={5}
      alignItems='stretch'
    >
      <SelectionBar
        includeCategoria={false}
        includeGirone={false}
        includeTeam={false}
      />
      <GridCard
        title='Società'
        subtitle1={`${dashboardStats?.affiliazioniStats?.complete || 0} ${
          dashboardStats?.affiliazioniStats?.complete === 1
            ? 'completa'
            : 'complete'
        }`}
        subtitle2={`${dashboardStats?.affiliazioniStats?.incomplete || 0} ${
          dashboardStats?.affiliazioniStats?.incomplete === 1
            ? 'da completare'
            : 'da completare'
        }`}
        linkTo='/affiliazioni'
        isError={dashboardStats?.affiliazioniStats?.incomplete > 0}
      />
      <GridCard
        title='Arbitri'
        subtitle1={`${dashboardStats?.arbitriStats?.complete || 0} ${
          dashboardStats?.arbitriStats?.complete === 1 ? 'completo' : 'completi'
        }`}
        subtitle2={`${dashboardStats?.arbitriStats?.incomplete || 0} ${
          dashboardStats?.arbitriStats?.incomplete === 1
            ? 'da completare'
            : 'da completare'
        }`}
        linkTo='/arbitri'
        isError={dashboardStats?.arbitriStats?.incomplete > 0}
      />
      <GridCard
        title='Iscrizioni'
        subtitle1={`${dashboardStats?.iscrizioniStats?.complete || 0} ${
          dashboardStats?.iscrizioniStats?.complete === 1
            ? 'completa'
            : 'complete'
        }`}
        subtitle2={`${dashboardStats?.iscrizioniStats?.incomplete || 0} ${
          dashboardStats?.iscrizioniStats?.incomplete === 1
            ? 'da completare'
            : 'da completare'
        }`}
        linkTo='/iscrizioni'
        isError={dashboardStats?.iscrizioniStats?.incomplete > 0}
      />
      <GridCard
        title='Spostamenti'
        subtitle1={`${dashboardStats?.spostamentiStats?.accepted || 0} ${
          dashboardStats?.spostamentiStats?.accepted === 1
            ? 'accettato'
            : 'accettati'
        }`}
        subtitle2={`${dashboardStats?.spostamentiStats?.waiting || 0} ${
          dashboardStats?.spostamentiStats?.waiting === 1
            ? 'in sospeso'
            : 'in sospeso'
        }`}
        linkTo='/spostamenti'
        isError={dashboardStats?.spostamentiStats?.waiting > 0}
      />
      <GridCard
        title='Designazioni'
        subtitle1={`${dashboardStats?.designazioniStats?.missing10days || 0} ${
          dashboardStats?.designazioniStats?.missing10days === 1
            ? 'mancante (10 giorni)'
            : 'mancanti (10 giorni)'
        }`}
        subtitle2={`${dashboardStats?.designazioniStats?.waiting || 0} ${
          dashboardStats?.designazioniStats?.waiting === 1
            ? 'in sospeso'
            : 'in sospeso'
        }`}
        linkTo='/designazioni'
        isError={
          dashboardStats?.designazioniStats?.waiting +
            dashboardStats?.designazioniStats?.missing10days >
          0
        }
      />
      <GridCard
        title='Gironi'
        subtitle1={`${
          selectedCampionatoGironiPubblicati
            ? 'Pubblicati'
            : 'Non ancora pubblicati'
        }`}
        subtitle2={`${dashboardStats?.iscrizioniStats?.gironi || 0} ${
          dashboardStats?.iscrizioniStats?.gironi === 1
            ? 'girone creato'
            : 'gironi creati'
        }`}
        linkTo='/gironi'
        isError={!selectedCampionatoGironiPubblicati}
      />
      <GridCard
        title='Calendari'
        subtitle1={`${
          selectedCampionatoCalendariPubblicati
            ? `${dashboardStats?.partiteStats?.noDateNoForfeit || 0} senza data`
            : 'Non ancora pubblicati'
        }`}
        subtitle2={`${dashboardStats?.partiteStats?.all || 0} totali`}
        linkTo='/calendari'
        isError={
          !selectedCampionatoCalendariPubblicati ||
          dashboardStats?.partiteStats?.noDateNoForfeit
        }
      />
      <GridCard
        title='Classifiche'
        subtitle1={`${
          selectedCampionatoCalendariPubblicati
            ? 'Pubblicate'
            : 'Non ancora pubblicate'
        }`}
        subtitle2={`${dashboardStats?.partiteStats?.withResult || 0} ${
          dashboardStats?.partiteStats?.withResult === 1
            ? 'risultato inserito'
            : 'risultati inseriti'
        }`}
        linkTo='/classifiche'
        isError={!selectedCampionatoCalendariPubblicati}
      />
      <GridCard
        title='Fasi finali'
        subtitle1={`${
          dashboardStats?.tabelloniStats?.length > 0
            ? 'Pubblicati'
            : 'Non ancora creati'
        }`}
        subtitle2={`${dashboardStats?.tabelloniStats?.length || 0} ${
          dashboardStats?.tabelloniStats?.length === 1
            ? 'tabellone creato'
            : 'tabelloni creati'
        }`}
        linkTo='/finali'
        isError={!dashboardStats?.tabelloniStats}
      />
      <GridCard
        title='Provvedimenti'
        subtitle1={`${dashboardStats?.risultatiMancanti || 0} ${
          dashboardStats?.risultatiMancanti === 1
            ? 'risultato mancante'
            : 'risultati mancanti'
        }`}
        subtitle2={`${dashboardStats?.refertiMancanti || 0} ${
          dashboardStats?.refertiMancanti === 1
            ? 'referto mancante'
            : 'referti mancanti'
        }`}
        linkTo='/provvedimenti'
        isError={
          dashboardStats?.risultatiMancanti > 0 ||
          dashboardStats?.refertiMancanti > 0
        }
      />
      <GridCard
        title='Rimborsi'
        subtitle1={`${dashboardStats?.rimborsiStats?.nonPagati || 0} da pagare`}
        subtitle2={`${dashboardStats?.rimborsiStats?.pagati || 0} ${
          dashboardStats?.rimborsiStats?.pagati === 1 ? 'pagato' : 'pagati'
        }`}
        linkTo='/rimborsi'
        isError={dashboardStats?.rimborsiStats?.nonPagati > 0}
      />
      <GridCard
        title='Rendiconti'
        subtitle1={`${dashboardStats?.rendicontiStats?.all || 0} ${
          dashboardStats?.rendicontiStats?.all === 1 ? 'totale' : 'totali'
        }`}
        subtitle2={`${dashboardStats?.rendicontiStats?.paid || 0} ${
          dashboardStats?.rendicontiStats?.paid === 1 ? 'pagato' : 'pagati'
        }`}
        linkTo='/rendiconti'
        isError={
          dashboardStats?.rendicontiStats?.all >
          dashboardStats?.rendicontiStats?.paid
        }
      />
      <GridCard
        title='Arbitraggi'
        subtitle1={`${dashboardStats?.arbitraggiStats?.nRequired || 0} ${
          dashboardStats?.arbitraggiStats?.nRequired === 1
            ? 'richiesta'
            : 'richieste'
        }`}
        linkTo='/arbitraggi'
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  campionati: state.home.campionati,
  dashboardStats: state.home.dashboardStats,
  selectedCampionatoGironiPubblicati:
    state.home.selectedCampionatoGironiPubblicati,
  selectedCampionatoCalendariPubblicati:
    state.home.selectedCampionatoCalendariPubblicati,
  selectedCampionato: state.home.selectedCampionato,
})

const ConnectedDashboardAdmin = connect(mapStateToProps)(DashboardAdmin)

export default ConnectedDashboardAdmin
