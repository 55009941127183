import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid, IconButton, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import Select from './Select'
import DatePicker from './DatePicker'
import TimePicker from './TimePicker'
import DialogMini from './DialogMini'
import ButtonGroup from './ButtonGroup'
import AutoComplete from './AutoComplete'
import { colours, phrasesSpostamento as phrases } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { sortNumeriGara } from '../hooks/sort-functions'
import {
  fromStringToDecimalHours,
  formatBodyField,
  fetchAndDispatch,
} from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import { SET_SPOSTAMENTO } from '../container/spostamenti/types'
import { SET_ISCRIZIONI } from '../container/iscrizioni/types'
import GridCircularProgress from './GridCircularProgress'

const availableFunctions = ['sposta', 'sposta-inverti', 'inverti', 'rimanda']
const availableFunctionsLabels = [
  'Cambia data/ora',
  'Cambia data/ora e inverti le squadre',
  'Inverti le squadre',
  'Rimanda a data da destinarsi',
]

const DialogSpostamento = ({
  infoUtente,
  iscrizioniLight,
  affiliazioniLight,
  selectedCampionato,
  selectedCampionatoSpostamentiGratuiti,
  iscrizioni,
  spostamento,
}) => {
  const { admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  const sDispatch = useCallback(
    (field, value) =>
      dispatch({
        type: SET_SPOSTAMENTO,
        payload: { ...spostamento, [field]: formatBodyField(value) },
      }),
    [dispatch, spostamento]
  )

  const [isLoadingGare, setIsLoadingGare] = useState()
  const [isLoadingIscrizioni, setIsLoadingIscrizioni] = useState()

  const [availableCategorie, setAvailableCategorie] = useState()
  const [availableSquadre, setAvailableSquadre] = useState()
  const [availableGare, setAvailableGare] = useState()
  const [availableGareTeam, setAvailableGareTeam] = useState()
  const [availableGareCategoria, setAvailableGareCategoria] = useState()
  const [availableAffiliazioni, setAvailableAffiliazioni] = useState()
  const [selectedCategoria, setSelectedCategoria] = useState()
  const [selectedSquadra, setSelectedSquadra] = useState()
  const [selectedGaraInfo, setSelectedGaraInfo] = useState()

  const [squadraCasa, setSquadraCasa] = useState()
  const [squadraTrasferta, setSquadraTrasferta] = useState()

  const [openSposta, setOpenSposta] = useState(false)
  const [openSpostaInverti, setOpenSpostaInverti] = useState(false)
  const [openInverti, setOpenInverti] = useState(false)
  const [openRimanda, setOpenRimanda] = useState(false)

  const handleClickOpenSposta = () => setOpenSposta(true)
  const handleClickOpenSpostaInverti = () => setOpenSpostaInverti(true)
  const handleClickOpenInverti = () => setOpenInverti(true)
  const handleClickOpenRimanda = () => setOpenRimanda(true)

  const handleCloseSposta = () => setOpenSposta(false)
  const handleCloseSpostaInverti = () => setOpenSpostaInverti(false)
  const handleCloseInverti = () => setOpenInverti(false)
  const handleCloseRimanda = () => setOpenRimanda(false)

  // Set categorie
  useEffect(() => {
    setAvailableCategorie(
      availableGare
        ?.map(i => i.categoria)
        ?.filter((v, i, s) => s.indexOf(v) === i)
        ?.sort()
    )
  }, [availableGare])

  // Set categoria
  useEffect(() => {
    setSelectedCategoria(
      availableCategorie?.length === 1 ? availableCategorie[0] : undefined
    )
  }, [availableCategorie])

  // Set gare
  useEffect(() => {
    setAvailableGareCategoria(
      availableGare
        ?.filter(a => a.categoria === selectedCategoria)
        ?.sort((a, b) => sortNumeriGara(a.numeroGara, b.numeroGara))
    )
  }, [availableGare, selectedCategoria])

  // Set squadre
  useEffect(() => {
    setAvailableSquadre(
      iscrizioni
        ?.filter(
          i =>
            (availableGare?.map(a => a.squadraCasaID)?.includes(i.id) ||
              availableGare?.map(a => a.squadraTrasfertaID)?.includes(i.id)) &&
            i.categoria === selectedCategoria
        )
        ?.map(i => i.id)
    )
  }, [availableGare, iscrizioni, selectedCategoria])

  // Set squadra
  useEffect(() => {
    setSelectedSquadra(
      availableSquadre?.length === 1 ? availableSquadre[0] : undefined
    )
  }, [availableSquadre])

  // Set gare
  useEffect(() => {
    setAvailableGareTeam(
      availableGare
        ?.filter(
          a =>
            a.squadraCasaID === selectedSquadra ||
            a.squadraTrasfertaID === selectedSquadra
        )
        ?.sort((a, b) => sortNumeriGara(a.numeroGara, b.numeroGara))
    )
  }, [availableGare, selectedSquadra])

  // Set gara
  useEffect(() => {
    if (!admin) {
      if (!spostamento?.selectedGara && availableGareTeam?.length === 1)
        sDispatch('selectedGara', availableGareTeam[0]._id)
      if (
        spostamento?.selectedGara &&
        !availableGareTeam?.map(i => i._id)?.includes(spostamento?.selectedGara)
      )
        sDispatch('selectedGara')
    }
  }, [admin, availableGareTeam, sDispatch, spostamento])

  // Update gara info
  useEffect(() => {
    setSelectedGaraInfo(
      availableGare?.find(c => c._id === spostamento?.selectedGara)
    )
  }, [availableGare, spostamento])

  // Set team names
  useEffect(() => {
    if (selectedGaraInfo?.squadraCasaID)
      setSquadraCasa(
        iscrizioniLight?.find(i => i.id === selectedGaraInfo.squadraCasaID)
      )
    if (selectedGaraInfo?.squadraTrasfertaID)
      setSquadraTrasferta(
        iscrizioniLight?.find(i => i.id === selectedGaraInfo.squadraTrasfertaID)
      )
  }, [iscrizioniLight, selectedGaraInfo])

  // Update affiliazioni
  useEffect(() => {
    setAvailableAffiliazioni(
      affiliazioniLight?.filter(
        i =>
          i.codiceSocieta === squadraCasa?.codiceSocieta ||
          i.codiceSocieta === squadraTrasferta?.codiceSocieta
      )
    )
  }, [affiliazioniLight, squadraCasa, squadraTrasferta])

  // Set function
  useEffect(() => {
    if (spostamento?.selectedGara && !spostamento?.selectedFunction)
      sDispatch('selectedFunction', availableFunctions[0])
  }, [sDispatch, spostamento])

  // Reset inputs
  useEffect(() => {
    if (!spostamento?.selectedGara) {
      if (spostamento?.newData) sDispatch('newData')
      if (spostamento?.newOra) sDispatch('newOra')
    }
  }, [sDispatch, spostamento])

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch available gare spostamento
  useEffect(() => {
    const dispatchFunction = x => setAvailableGare(admin ? x : x?.data)

    if (selectedCampionato && codiceUtente) {
      fetchAndDispatchFunction({
        url: `calendari/campionato/${selectedCampionato}${
          admin ? '' : `/societa/${codiceUtente}/spostamento`
        }`,
        errorText: 'le partite di cui chiedere lo spostamento',
        dispatchLoading: x => setIsLoadingGare(x),
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [admin, codiceUtente, fetchAndDispatchFunction, selectedCampionato])

  // fetch iscrizioni
  useEffect(() => {
    const dispatchFunction = x => dispatch({ type: SET_ISCRIZIONI, payload: x })

    if (admin) {
      if (selectedCampionato) {
        fetchAndDispatchFunction({
          url: `iscrizioni/campionato/${selectedCampionato}`,
          errorText: 'le squadre iscritte',
          dispatchLoading: x => setIsLoadingIscrizioni(x),
          dispatchFunction,
        })
      } else {
        dispatchFunction()
      }
    }
  }, [admin, dispatch, fetchAndDispatchFunction, selectedCampionato])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_SPOSTAMENTO })
  }, [dispatch])

  return (
    <>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={12}>
          {(admin && (
            <>
              Il presente modulo permette di richiedere uno spostamento per
              conto di una società.
              <br />
              Questa funzionalità è pensata per <b>casi eccezionali</b> in cui
              la società richiedente non può inserire lo spostamento da sè.
              <br />
              La compilazione del modulo genera una mail alla squadra
              avversaria, che dovrà accettare o rifiutare la richiesta.
            </>
          )) || (
            <>
              Il presente modulo permette di richiedere lo spostamento di una
              gara (cambio di data/orario, inversione di campo o rinvio a data
              da destinarsi).
              <br />
              La compilazione del modulo genera una mail alla squadra
              avversaria, che dovrà accettare o rifiutare la richiesta. Lo
              spostamento avrà luogo solo se la squadra avversaria accetta.
              <br />
              <br />
              <b>
                La richiesta va compilata almeno 3 giorni (72 ore) prima
                dell'orario di inizio gara.
              </b>
              <br />
              <br />
              Per domande o chiarimenti scrivere a{' '}
              <a href='mailto:spostamenti@pgsmilano.org'>
                spostamenti@pgsmilano.org
              </a>
              .
            </>
          )}
        </Grid>
        {(isLoadingIscrizioni && <GridCircularProgress />) ||
          ((!iscrizioni || iscrizioni.length === 0) && (
            <Grid item xs={12}>
              Nessuna squadra iscritta a questo campionato.
            </Grid>
          )) ||
          (isLoadingGare && <GridCircularProgress />) ||
          (!availableGare?.length && (
            <Grid item xs={12}>
              <center>
                <b>
                  <i>Nessuno spostamento possibile.</i>
                </b>
              </center>
            </Grid>
          )) || (
            <>
              {admin && (
                <Grid container item xs={12} spacing={2} alignItems='center'>
                  <Grid item xs={12} md={3}>
                    <Select
                      id='categoria'
                      label='Categoria'
                      value={selectedCategoria}
                      options={availableCategorie}
                      onChange={setSelectedCategoria}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <AutoComplete
                      id='gara'
                      label='Gara'
                      value={spostamento?.selectedGara}
                      options={availableGareCategoria?.map(i => i._id)}
                      getOptionLabel={i =>
                        availableGareCategoria?.find(a => a._id === i)
                          ?.numeroGara
                      }
                      onChange={i => sDispatch('selectedGara', i)}
                      disabled={!selectedCategoria}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      id='codiceSocietaDomanda'
                      label='Società richiedente'
                      value={spostamento?.codiceSocietaDomanda}
                      options={availableAffiliazioni?.map(i => i.codiceSocieta)}
                      optionLabels={availableAffiliazioni?.map(
                        a => `${a.codiceSocieta} - ${a.nomeSocieta}`
                      )}
                      onChange={i => sDispatch('codiceSocietaDomanda', i)}
                      disabled={!spostamento?.selectedGara}
                    />
                  </Grid>
                </Grid>
              )}
              {!admin && (
                <>
                  <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={5} md={3}>
                      <center>
                        <b>Categoria</b>
                      </center>
                    </Grid>
                    <Grid item xs={7} md={9}>
                      {availableCategorie?.length > 0 ? (
                        <ButtonGroup
                          list={availableCategorie}
                          value={selectedCategoria}
                          func={setSelectedCategoria}
                        />
                      ) : (
                        'Nessuna categoria trovata.'
                      )}
                    </Grid>
                  </Grid>
                  {!selectedCategoria ? (
                    <Grid item xs={12}>
                      <center>
                        <b>
                          <i>Selezionare una categoria.</i>
                        </b>
                      </center>
                    </Grid>
                  ) : (
                    <>
                      <Grid container item xs={12} alignItems='center'>
                        <Grid item xs={5} md={3}>
                          <center>
                            <b>Squadra</b>
                          </center>
                        </Grid>
                        <Grid item xs={7} md={9}>
                          {availableSquadre?.length > 0 ? (
                            <ButtonGroup
                              list={availableSquadre}
                              labels={index =>
                                iscrizioni?.find(
                                  i => i.id === availableSquadre[index]
                                )?.nomeSquadra
                              }
                              value={selectedSquadra}
                              func={setSelectedSquadra}
                            />
                          ) : (
                            'Nessuna squadra trovata.'
                          )}
                        </Grid>
                      </Grid>
                      {!selectedSquadra ? (
                        <Grid item xs={12}>
                          <center>
                            <b>
                              <i>Selezionare una squadra.</i>
                            </b>
                          </center>
                        </Grid>
                      ) : (
                        <>
                          <Grid container item xs={12} alignItems='center'>
                            <Grid item xs={5} md={3}>
                              <center>
                                <b>Gara</b>
                              </center>
                            </Grid>
                            <Grid item xs={7} md={9}>
                              {availableGare?.length > 0 ? (
                                <ButtonGroup
                                  list={availableGareTeam?.map(i => i._id)}
                                  labels={index =>
                                    availableGareTeam[index]?.numeroGara
                                  }
                                  value={spostamento?.selectedGara}
                                  func={i => sDispatch('selectedGara', i)}
                                />
                              ) : (
                                'Nessuna gara trovata.'
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  &nbsp;
                </>
              )}
              {selectedSquadra && !spostamento?.selectedGara && (
                <Grid item xs={12}>
                  <center>
                    <b>
                      <i>Selezionare una gara.</i>
                    </b>
                  </center>
                </Grid>
              )}
              {spostamento?.selectedGara && (
                <Grid container item xs={12} spacing={3} alignItems='center'>
                  <Grid item xs={12} md={6}>
                    <center>
                      <b>
                        {squadraCasa?.nomeSquadra ||
                          selectedGaraInfo?.squadraCasaID}
                      </b>
                      &nbsp;-&nbsp;
                      <b>
                        {squadraTrasferta?.nomeSquadra ||
                          selectedGaraInfo?.squadraTrasfertaID}
                      </b>
                    </center>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <center>
                      {selectedGaraInfo?.data || 'Data da definire'}
                    </center>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <center>
                      {selectedGaraInfo?.ora || 'Ora da definire'}
                    </center>
                  </Grid>
                </Grid>
              )}
              &nbsp;
              {!admin &&
                !selectedCampionatoSpostamentiGratuiti &&
                spostamento?.selectedGara &&
                ['sposta', 'sposta-inverti'].includes(
                  spostamento?.selectedFunction
                ) &&
                (!!selectedGaraInfo?.needsReferee ||
                  !!selectedGaraInfo?.requiresReferee) &&
                selectedGaraInfo?.girone !== 'finali' &&
                (!selectedGaraInfo?.data ||
                  !selectedGaraInfo?.ora ||
                  selectedGaraInfo?.data !== spostamento?.newData ||
                  Math.abs(
                    fromStringToDecimalHours(selectedGaraInfo?.ora) -
                      fromStringToDecimalHours(spostamento?.newOra)
                  ) > 3) && (
                  <Grid container item xs={12} alignItems='center'>
                    <Grid item xs={2} md={1}>
                      <center>
                        <Checkbox
                          id='check-payment'
                          checked={!!spostamento?.checkPayment}
                          onChange={event =>
                            sDispatch('checkPayment', event.target.checked)
                          }
                        />
                      </center>
                    </Grid>
                    <Grid item xs={10} md={11}>
                      <b>
                        La società si impegna a versare la tassa per lo
                        spostamento.
                      </b>
                    </Grid>
                  </Grid>
                )}
              {!!spostamento?.selectedGara && (
                <Grid container item xs={12} spacing={2} alignItems='center'>
                  <Grid item xs={10} md={5}>
                    <Select
                      id='tipologia'
                      value={spostamento?.selectedFunction}
                      options={availableFunctions}
                      optionLabels={availableFunctionsLabels}
                      label='Tipologia'
                      onChange={value => sDispatch('selectedFunction', value)}
                    />
                  </Grid>
                  <Grid item xs={2} md={1}>
                    <center>
                      <IconButton
                        style={{ color: colours.grey }}
                        onClick={
                          spostamento?.selectedFunction ===
                          availableFunctions[0]
                            ? handleClickOpenSposta
                            : spostamento?.selectedFunction ===
                              availableFunctions[1]
                            ? handleClickOpenSpostaInverti
                            : spostamento?.selectedFunction ===
                              availableFunctions[2]
                            ? handleClickOpenInverti
                            : spostamento?.selectedFunction ===
                              availableFunctions[3]
                            ? handleClickOpenRimanda
                            : null
                        }
                      >
                        <Tooltip title='Maggiori informazioni'>
                          <InfoIcon />
                        </Tooltip>
                      </IconButton>
                    </center>
                  </Grid>
                  {[availableFunctions[0], availableFunctions[1]].includes(
                    spostamento?.selectedFunction
                  ) && (
                    <>
                      <Grid item xs={12} sm={6} md={3}>
                        <center>
                          <DatePicker
                            label='Nuova data'
                            value={spostamento?.newData}
                            onChange={i => sDispatch('newData', i)}
                            disablePast={!admin}
                          />
                        </center>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <center>
                          <TimePicker
                            label='Nuovo orario'
                            value={spostamento?.newOra}
                            onChange={i => sDispatch('newOra', i)}
                          />
                        </center>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </>
          )}
      </Grid>
      <DialogMini
        open={openSposta}
        handleClose={handleCloseSposta}
        title={availableFunctionsLabels[0]}
        textContent={phrases.phraseSposta}
        textConfirm='Ok'
        triggerFunction={handleCloseSposta}
      />
      <DialogMini
        open={openSpostaInverti}
        handleClose={handleCloseSpostaInverti}
        title={availableFunctionsLabels[1]}
        textContent={phrases.phraseSpostaInverti}
        textConfirm='Ok'
        triggerFunction={handleCloseSpostaInverti}
      />
      <DialogMini
        open={openInverti}
        handleClose={handleCloseInverti}
        title={availableFunctionsLabels[2]}
        textContent={phrases.phraseInverti}
        textConfirm='Ok'
        triggerFunction={handleCloseInverti}
      />
      <DialogMini
        open={openRimanda}
        handleClose={handleCloseRimanda}
        title={availableFunctionsLabels[3]}
        textContent={phrases.phraseRimanda}
        textConfirm='Ok'
        triggerFunction={handleCloseRimanda}
      />
    </>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  iscrizioniLight: state.home.iscrizioniLight,
  affiliazioniLight: state.home.affiliazioniLight,
  selectedCampionato: state.home.selectedCampionato,
  selectedCampionatoSpostamentiGratuiti:
    state.home.selectedCampionatoSpostamentiGratuiti,
  iscrizioni: state.iscrizioni.iscrizioni,
  spostamento: state.spostamenti.spostamento,
})

const ConnectedDialogSpostamento = connect(mapStateToProps)(DialogSpostamento)

export default ConnectedDialogSpostamento
