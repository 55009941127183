import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { Toolbar } from '@mui/material'

import Home from './pages/Home'
import Gironi from './pages/Gironi'
import Squadre from './pages/Squadre'
import Arbitri from './pages/Arbitri'
import Arbitro from './pages/Arbitro'
import Calendari from './pages/Calendari'
import DashboardSocieta from './pages/DashboardSocieta'
import Algoritmi from './pages/Algoritmi'
import Tabelloni from './pages/Tabelloni'
import Iscrizioni from './pages/Iscrizioni'
import Iscrizione from './pages/Iscrizione'
import Classifiche from './pages/Classifiche'
import Spostamenti from './pages/Spostamenti'
import Designazioni from './pages/Designazioni'
import Affiliazioni from './pages/Affiliazioni'
import Affiliazione from './pages/Affiliazione'
import Impostazioni from './pages/Impostazioni'
import Provvedimenti from './pages/Provvedimenti'
import DashboardAdmin from './pages/DashboardAdmin'
import DashboardArbitro from './pages/DashboardArbitro'
import DashboardDesignatore from './pages/DashboardDesignatore'
import DashboardOmologatore from './pages/DashboardOmologatore'
import Rimborsi from './pages/Rimborsi'
import Rendiconti from './pages/Rendiconti'
import Arbitraggi from './pages/Arbitraggi'
import Header from './components/Header'
import Drawer from './components/Drawer'
import SnackBar from './components/SnackBar'
import DialogFeedback from './components/DialogFeedback'
import { useFetch } from './hooks/fetch-hook'

const App = ({
  infoUtente,
  campionati,
  selectedStagione,
  selectedCampionato,
  dummyUpdate,
}) => {
  const { auth, admin, societa, arbitro, designatore, omologatore } = infoUtente

  useFetch(
    infoUtente,
    campionati,
    selectedStagione,
    selectedCampionato,
    dummyUpdate
  )

  const DynamicHome = () =>
    auth ? (
      (admin && <DashboardAdmin />) ||
      (arbitro && <DashboardArbitro />) ||
      (designatore && <DashboardDesignatore />) ||
      (omologatore && <DashboardOmologatore />) ||
      (societa && <DashboardSocieta />) || <Home />
    ) : (
      <Home />
    )

  return (
    <>
      <Router>
        <DialogFeedback />
        <SnackBar />
        <Header />
        <Toolbar />
        <div style={{ display: 'flex' }}>
          <Drawer />
          <Switch>
            <Route path='/' exact>
              <DynamicHome />
            </Route>
            <Route path='/squadre' exact>
              <Squadre />
            </Route>
            <Route path='/gironi' exact>
              <Gironi />
            </Route>
            <Route path='/calendari' exact>
              <Calendari />
            </Route>
            <Route path='/classifiche' exact>
              <Classifiche />
            </Route>
            <Route path='/finali' exact>
              <Tabelloni />
            </Route>
            {auth === undefined ? (
              <DynamicHome />
            ) : auth === false ? (
              <Redirect to='/' />
            ) : (
              <Switch>
                <Route path='/iscrizione' exact>
                  {admin === undefined || societa === undefined ? (
                    <DynamicHome />
                  ) : admin || societa ? (
                    <Iscrizione />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/iscrizioni' exact>
                  {admin === undefined || societa === undefined ? (
                    <DynamicHome />
                  ) : admin || societa ? (
                    <Iscrizioni />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/spostamenti' exact>
                  {admin === undefined || societa === undefined ? (
                    <DynamicHome />
                  ) : admin || societa ? (
                    <Spostamenti />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/arbitraggi' exact>
                  {admin === undefined ||
                  societa === undefined ||
                  designatore === undefined ? (
                    <DynamicHome />
                  ) : admin || societa || designatore ? (
                    <Arbitraggi />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/arbitri' exact>
                  {admin === undefined || designatore === undefined ? (
                    <DynamicHome />
                  ) : admin || designatore ? (
                    <Arbitri />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/arbitro' exact>
                  {admin === undefined ||
                  arbitro === undefined ||
                  designatore === undefined ? (
                    <DynamicHome />
                  ) : admin || arbitro || designatore ? (
                    <Arbitro />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/designazioni' exact>
                  {admin === undefined ||
                  arbitro === undefined ||
                  designatore === undefined ? (
                    <DynamicHome />
                  ) : admin || arbitro || designatore ? (
                    <Designazioni />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/rimborsi' exact>
                  {admin === undefined || arbitro === undefined ? (
                    <DynamicHome />
                  ) : admin || arbitro ? (
                    <Rimborsi />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                <Route path='/rendiconti' exact>
                  {admin === undefined || societa === undefined ? (
                    <DynamicHome />
                  ) : admin || societa ? (
                    <Rendiconti />
                  ) : (
                    <Redirect to='/' />
                  )}
                </Route>
                {admin === undefined ? (
                  <DynamicHome />
                ) : !admin ? (
                  <Redirect to='/' />
                ) : (
                  <Switch>
                    <Route path='/affiliazione' exact>
                      <Affiliazione />
                    </Route>
                    <Route path='/affiliazioni' exact>
                      <Affiliazioni />
                    </Route>
                    <Route path='/provvedimenti' exact>
                      <Provvedimenti />
                    </Route>
                    <Route path='/algoritmi' exact>
                      <Algoritmi />
                    </Route>
                    <Route path='/impostazioni' exact>
                      <Impostazioni />
                    </Route>
                    <Redirect to='/' />
                  </Switch>
                )}
                <Redirect to='/' />
              </Switch>
            )}
            <Redirect to='/' />
          </Switch>
        </div>
        &nbsp;
      </Router>
    </>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  campionati: state.home.campionati,
  selectedStagione: state.home.selectedStagione,
  selectedCampionato: state.home.selectedCampionato,
  dummyUpdate: state.home.dummyUpdate,
})

const ConnectedApp = connect(mapStateToProps)(App)

export default ConnectedApp
