import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'

import { sendRequest } from '../hooks/http-hook'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR } from '../container/home/types'
import { SET_DELETE_ALSO_GARE } from '../container/iscrizioni/types'

const DialogDeleteIscrizione = ({ team, infoUtente, deleteAlsoGare }) => {
  const { admin } = infoUtente

  const dispatch = useDispatch()

  const [numeroGare, setNumeroGare] = useState()

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url => await sendRequest(url),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [dispatch]
  )

  // fetch numero gare
  useEffect(() => {
    const dispatchFunction = x => setNumeroGare(x?.length)

    if (admin && team?.id) {
      fetchAndDispatchFunction({
        url: `calendari/team/${team.id}`,
        errorText: 'le gare',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [admin, fetchAndDispatchFunction, team])

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_DELETE_ALSO_GARE })
  }, [dispatch])

  return !admin ? null : (
    <>
      &nbsp;
      {((!team || team.length === 0) && (
        <Grid item xs={12}>
          Nessuna squadra selezionata.
        </Grid>
      )) ||
        (numeroGare === 0 && (
          <Grid item xs={12}>
            La squadra selezionata non era ancora coinvolta in nessuna partita.
          </Grid>
        )) || (
          <Grid container spacing={3} direction='column'>
            <Grid item xs={12}>
              {numeroGare === 1
                ? `È stata trovata ${numeroGare} partita`
                : `Sono state trovate ${numeroGare} partite`}{' '}
              in cui questa squadra è coinvolta. Selezionare la seguente casella
              se si intendono eliminare dalla piattaforma anche le partite;
              lasciarla vuota se invece si intendono mantenere le partite.
            </Grid>
            &nbsp;
            {!!team && (
              <Grid
                container
                item
                xs={12}
                spacing={3}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>
                  <center>Eliminare anche le partite?</center>
                </Grid>
                <Grid item>
                  <center>
                    <Checkbox
                      id='deleteAlsoGare'
                      checked={!!deleteAlsoGare}
                      onChange={event =>
                        dispatch({
                          type: SET_DELETE_ALSO_GARE,
                          payload: event.target.checked,
                        })
                      }
                    />
                  </center>
                </Grid>
              </Grid>
            )}
            &nbsp;
          </Grid>
        )}
    </>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  deleteAlsoGare: state.iscrizioni.deleteAlsoGare,
})

const ConnectedDialogDeleteIscrizione = connect(mapStateToProps)(
  DialogDeleteIscrizione
)

export default ConnectedDialogDeleteIscrizione
