import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import { Checkbox, Grid, IconButton, Tooltip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/Info'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import DateRangeIcon from '@mui/icons-material/DateRange'

import Button from '../components/Button'
import Select from '../components/Select'
import InputField from '../components/InputField'
import TimePicker from '../components/TimePicker'
import DialogMini from '../components/DialogMini'
import AutoComplete from '../components/AutoComplete'
import {
  colours,
  GIORNI,
  WEEK_DAYS,
  MONTHS,
  titlesArbitro as titles,
  phrasesArbitro as phrases,
} from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { sortAlphabetically } from '../hooks/sort-functions'
import {
  fromDateToString,
  fromStringToDate,
  formatIndirizzo,
  linkToGoogleMaps,
  formatBodyField,
  JSONreplacer,
  fetchAndDispatch,
} from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import { SET_ARBITRO, SET_ARBITRO_FIELD } from '../container/arbitri/types'

const Arbitro = ({
  infoUtente,
  affiliazioniLight,
  dummyUpdate,
  isMobile,
  Arbitro,
}) => {
  const { admin, arbitro, designatore, codiceUtente, idArbitro } = infoUtente

  const dispatch = useDispatch()

  const aDispatch = useCallback(
    (field, value, toCase) =>
      dispatch({
        type: SET_ARBITRO_FIELD,
        payload: { field, value: formatBodyField(value, toCase) },
      }),
    [dispatch]
  )

  const mode = !!Arbitro?._id ? 'update' : 'add'

  const { setFeedback } = useFeedback()

  document.title = `PGS Milano - ${
    mode === 'add' ? 'Nuovo' : 'Modifica'
  } arbitro`

  const [domicilioResidenza, setDomicilioResidenza] = useState()

  // parameters for disponibilità
  const [addingDisponibilita, setAddingDisponibilita] = useState(false)
  const [newGiorno, setNewGiorno] = useState()
  const [newDalle, setNewDalle] = useState()
  const [newAlle, setNewAlle] = useState()

  // parameters for indisponibilità
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // get current season range
  useEffect(() => {
    const dispatchFunction = x => {
      if (x?.minDate) setMinDate(new Date(x.minDate))
      if (x?.maxDate) setMaxDate(new Date(x.maxDate))
    }

    fetchAndDispatchFunction({
      url: 'campionati/range/stagione/corrente',
      errorText: 'le date della stagione corrente',
      dispatchFunction,
    })
  }, [fetchAndDispatchFunction])

  // filter indisponibilitaPuntuali inside/outside current season range
  const fIndPun = interval =>
    Arbitro?.indisponibilitaPuntuali
      ?.map(i => fromStringToDate(i))
      ?.filter(i => {
        if (!minDate || !maxDate) return interval === 'in'
        if (interval === 'in') return i >= minDate && i <= maxDate
        if (interval === 'out') return i < minDate || i > maxDate
        return true
      }) || []

  const setIndisponibilitaPuntuali = i =>
    aDispatch(
      'indisponibilitaPuntuali',
      [...fIndPun('out'), ...(i || [])]
        ?.sort((a, b) => a - b)
        ?.map(x => fromDateToString(new Date(x))) || []
    )

  const [openDisponibilita, setOpenDisponibilita] = useState(false)
  const [openSocieta, setOpenSocieta] = useState(false)
  const [openIndisp, setOpenIndisp] = useState(false)
  const [openIndispTyping, setOpenIndispTyping] = useState(false)
  const [openActive, setOpenActive] = useState(false)

  const handleClickOpenDisponibilita = () => setOpenDisponibilita(true)
  const handleClickOpenSocieta = () => setOpenSocieta(true)
  const handleClickOpenIndisp = () => setOpenIndisp(true)
  const handleClickOpenIndispTyping = () => setOpenIndispTyping(true)
  const handleClickOpenActive = () => setOpenActive(true)

  const handleCloseDisponibilita = () => setOpenDisponibilita(false)
  const handleCloseSocieta = () => setOpenSocieta(false)
  const handleCloseIndisp = () => setOpenIndisp(false)
  const handleCloseIndispTyping = () => setOpenIndispTyping(false)
  const handleCloseActive = () => setOpenActive(false)

  // update domicilioResidenza
  useEffect(() => {
    if (typeof domicilioResidenza !== 'boolean')
      setDomicilioResidenza(
        Arbitro?._id &&
          Arbitro?.indirizzoDomicilio === Arbitro?.indirizzoResidenza &&
          Arbitro?.cittaDomicilio === Arbitro?.cittaResidenza &&
          Arbitro?.capDomicilio === Arbitro?.capResidenza
      )
  }, [
    Arbitro?._id,
    Arbitro?.capDomicilio,
    Arbitro?.capResidenza,
    Arbitro?.cittaDomicilio,
    Arbitro?.cittaResidenza,
    Arbitro?.indirizzoDomicilio,
    Arbitro?.indirizzoResidenza,
    domicilioResidenza,
  ])

  // update indirizzoDomicilio
  useEffect(() => {
    if (
      domicilioResidenza &&
      (Arbitro?.indirizzoDomicilio !== Arbitro?.indirizzoResidenza ||
        Arbitro?.cittaDomicilio !== Arbitro?.cittaResidenza ||
        Arbitro?.capDomicilio !== Arbitro?.capResidenza)
    ) {
      aDispatch('indirizzoDomicilio', Arbitro?.indirizzoResidenza)
      aDispatch('cittaDomicilio', Arbitro?.cittaResidenza)
      aDispatch('capDomicilio', Arbitro?.capResidenza)
    }
  }, [
    aDispatch,
    Arbitro?.capDomicilio,
    Arbitro?.capResidenza,
    Arbitro?.cittaDomicilio,
    Arbitro?.cittaResidenza,
    Arbitro?.indirizzoDomicilio,
    Arbitro?.indirizzoResidenza,
    domicilioResidenza,
  ])

  // fetch arbitro
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({ type: SET_ARBITRO, payload: x?.data })

    if (arbitro && idArbitro)
      fetchAndDispatchFunction({
        url: `arbitri/${idArbitro}`,
        errorText: "l'arbitro",
        dispatchFunction,
      })
  }, [arbitro, dispatch, fetchAndDispatchFunction, idArbitro])

  const addArbitro = async () => {
    try {
      await sendRequest('arbitri/add', 'POST', JSON.stringify(Arbitro), {
        'Content-Type': 'application/json',
        Authorization: codiceUtente,
      })
      setFeedback(titles.titleSuccessAdd, false, '', '/arbitri')
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const updateArbitro = async () => {
    try {
      await sendRequest(
        `arbitri/${Arbitro?._id}`,
        'PUT',
        JSON.stringify(Arbitro, JSONreplacer),
        { 'Content-Type': 'application/json', Authorization: codiceUtente }
      )
      setFeedback(titles.titleSuccessUpdate, false, '', '/arbitri')
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  // clear disponibilità
  const clearNewDisponibilita = () => {
    setAddingDisponibilita(false)
    setNewGiorno()
    setNewDalle()
    setNewAlle()
  }

  const confirmArbitro = async () => {
    if (mode === 'add') await addArbitro()
    if (mode === 'update') await updateArbitro()
  }

  // trigger functions onComponentUnmounts
  useEffect(() => {
    return () => dispatch({ type: SET_ARBITRO })
  }, [dispatch])

  const containerProps = {
    container: true,
    item: true,
    xs: 12,
    paddingY: 2,
    rowSpacing: 2,
    columnSpacing: 5,
  }

  return (
    <Grid container item xs={12} paddingX={3}>
      <Grid item xs={12}>
        <center>
          <h2>{`${mode === 'add' ? 'Nuovo' : 'Modifica'} arbitro`}</h2>
        </center>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12} md={6}>
          <InputField
            id='nome'
            value={Arbitro?.nome}
            onChange={i => aDispatch('nome', i, 'upper')}
            autoFocus={true}
            fullWidth
            label='Nome *'
            errorFunc={() => !Arbitro?.nome}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='cognome'
            value={Arbitro?.cognome}
            onChange={i => aDispatch('cognome', i, 'upper')}
            fullWidth
            label='Cognome *'
            errorFunc={() => !Arbitro?.cognome}
            mode={mode}
          />
        </Grid>
        {admin && (
          <Grid item xs={12} md={6}>
            <InputField
              id='codice-fiscale'
              value={Arbitro?.codiceFiscale}
              onChange={i =>
                aDispatch('codiceFiscale', i.replace(/\W/g, ''), 'upper')
              }
              fullWidth
              label='Codice fiscale *'
              errorFunc={() => !Arbitro?.codiceFiscale}
              mode={mode}
              readOnly={!admin}
            />
          </Grid>
        )}
        {admin && (
          <Grid item xs={12} md={6}>
            <InputField
              id='password'
              value={Arbitro?.password}
              onChange={i => aDispatch('password', i)}
              fullWidth
              label='Password *'
              errorFunc={() => !Arbitro?.password}
              mode={mode}
              readOnly={!admin}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <InputField
            id='data-nascita'
            value={Arbitro?.dataNascita}
            onChange={i => aDispatch('dataNascita', i)}
            fullWidth
            label='Data di nascita'
            errorFunc={() => !Arbitro?.dataNascita}
            adornment='date'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='luogo-nascita'
            value={Arbitro?.luogoNascita}
            onChange={i => aDispatch('luogoNascita', i, 'upper')}
            fullWidth
            label='Luogo di nascita'
            errorFunc={() => !Arbitro?.luogoNascita}
            adornment='city'
            mode={mode}
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Residenza</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='indirizzo-residenza'
            value={Arbitro?.indirizzoResidenza}
            onChange={i => aDispatch('indirizzoResidenza', i, 'upper')}
            fullWidth
            label='Indirizzo'
            errorFunc={() => !Arbitro?.indirizzoResidenza}
            adornment='zone'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField
            id='citta-residenza'
            value={Arbitro?.cittaResidenza}
            onChange={i => aDispatch('cittaResidenza', i, 'upper')}
            fullWidth
            label='Città'
            errorFunc={() => !Arbitro?.cittaResidenza}
            adornment='city'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputField
            id='cap-residenza'
            value={Arbitro?.capResidenza}
            onChange={i => aDispatch('capResidenza', i)}
            fullWidth
            label='CAP'
            errorFunc={() => !Arbitro?.capResidenza}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {linkToGoogleMaps(
            formatIndirizzo({
              presso: Arbitro?.pressoResidenza,
              indirizzo: Arbitro?.indirizzoResidenza,
              citta: Arbitro?.cittaResidenza,
            })
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          Domicilio uguale a residenza &nbsp;
          <Checkbox
            id='domicilio-residenza'
            checked={!!domicilioResidenza}
            onChange={event => setDomicilioResidenza(event.target.checked)}
            color='default'
          />
        </Grid>
        {!domicilioResidenza && (
          <>
            <Grid item xs={12}>
              <h4>Domicilio</h4>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                id='indirizzo-domicilio'
                value={Arbitro?.indirizzoDomicilio}
                onChange={i => aDispatch('indirizzoDomicilio', i, 'upper')}
                fullWidth
                label='Indirizzo'
                errorFunc={() => !Arbitro?.indirizzoDomicilio}
                adornment='zone'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputField
                id='citta-domicilio'
                value={Arbitro?.cittaDomicilio}
                onChange={i => aDispatch('cittaDomicilio', i, 'upper')}
                fullWidth
                label='Città'
                errorFunc={() => !Arbitro?.cittaDomicilio}
                adornment='city'
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputField
                id='cap-domicilio'
                value={Arbitro?.capDomicilio}
                onChange={i => aDispatch('capDomicilio', i)}
                fullWidth
                label='CAP'
                errorFunc={() => !Arbitro?.capDomicilio}
                mode={mode}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {linkToGoogleMaps(
                formatIndirizzo({
                  presso: Arbitro?.pressoDomicilio,
                  indirizzo: Arbitro?.indirizzoDomicilio,
                  citta: Arbitro?.cittaDomicilio,
                })
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Contatti</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='telefono'
            value={Arbitro?.telefono}
            onChange={i => aDispatch('telefono', i.replace(/\s/g, ''))}
            fullWidth
            label='Telefono'
            errorFunc={() => !Arbitro?.telefono}
            type='tel'
            adornment='phone'
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='mail'
            value={Arbitro?.mail}
            onChange={i => aDispatch('mail', i.replace(/\s/g, ''), 'lower')}
            fullWidth
            label='E-mail'
            errorFunc={() => !Arbitro?.mail}
            type='email'
            adornment='email'
            mode={mode}
          />
        </Grid>
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>Coordinate bancarie</h4>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            id='codiceIBAN'
            value={Arbitro?.codiceIBAN}
            onChange={i =>
              aDispatch('codiceIBAN', i.replace(/\W/g, ''), 'upper')
            }
            fullWidth
            label='Codice IBAN'
            errorFunc={() => !Arbitro?.codiceIBAN}
            mode={mode}
          />
        </Grid>
        <Grid item xs={12} md={admin || designatore ? 4 : 6}>
          <InputField
            id='nome-banca'
            value={Arbitro?.nomeBanca}
            onChange={i => aDispatch('nomeBanca', i, 'upper')}
            fullWidth
            label='Nome banca'
            errorFunc={() => !Arbitro?.nomeBanca}
            mode={mode}
          />
        </Grid>
        {(admin || designatore) && (
          <Grid item xs={12} md={2}>
            <InputField
              id='costo-km'
              value={Arbitro?.costoKm}
              onChange={i => aDispatch('costoKm', i)}
              fullWidth
              label='Costo €/km'
              type='number'
              mode={mode}
            />
          </Grid>
        )}
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>
            Disponibilità settimanali &nbsp;
            <IconButton
              style={{ color: colours.grey }}
              onClick={handleClickOpenDisponibilita}
            >
              <Tooltip key='disponibilita' title='Maggiori informazioni'>
                <InfoIcon />
              </Tooltip>
            </IconButton>
          </h4>
        </Grid>
        <Grid item xs={12}>
          {(!Arbitro?.disponibilitaSettimanali?.length && (
            <p>Nessuna disponibilità inserita.</p>
          )) ||
            Arbitro?.disponibilitaSettimanali.map((x, index) => {
              return (
                <div key={index}>
                  {x.giorno} dalle {x.dalle} alle {x.alle}&nbsp;
                  <IconButton
                    style={{ color: colours.red }}
                    onClick={() =>
                      aDispatch('disponibilitaSettimanali', [
                        ...Arbitro?.disponibilitaSettimanali.slice(0, index),
                        ...Arbitro?.disponibilitaSettimanali.slice(index + 1),
                      ])
                    }
                  >
                    <Tooltip key='Elimina' title='Elimina'>
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              )
            })}
        </Grid>
        {(addingDisponibilita && (
          <Grid container item xs={12} spacing={3} alignItems='center'>
            <Grid item xs={12} md>
              <Select
                id='giorno'
                label='Giorno'
                value={newGiorno}
                options={GIORNI}
                onChange={setNewGiorno}
              />
            </Grid>
            <Grid item xs={12} md>
              <center>
                <TimePicker
                  label='Dalle (inizio gara)'
                  value={newDalle}
                  onChange={setNewDalle}
                />
              </center>
            </Grid>
            <Grid item xs={12} md>
              <center>
                <TimePicker
                  label='Alle (inizio gara)'
                  value={newAlle}
                  onChange={setNewAlle}
                />
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton
                  style={{ color: colours.blueDark }}
                  onClick={() => {
                    aDispatch(
                      'disponibilitaSettimanali',
                      (Arbitro?.disponibilitaSettimanali || [])
                        .concat({
                          giorno: newGiorno,
                          dalle: newDalle,
                          alle: newAlle,
                        })
                        .sort(
                          (a, b) =>
                            GIORNI.findIndex(d => d === a.giorno) -
                              GIORNI.findIndex(d => d === b.giorno) ||
                            sortAlphabetically(a.dalle, b.dalle)
                        )
                    )
                    clearNewDisponibilita()
                  }}
                  disabled={!newGiorno || !newDalle || !newAlle}
                >
                  <Tooltip key='Salva' title='Salva'>
                    <SaveIcon />
                  </Tooltip>
                </IconButton>
              </center>
            </Grid>
            <Grid item xs={6} md={1}>
              <center>
                <IconButton
                  style={{ color: colours.red }}
                  onClick={clearNewDisponibilita}
                >
                  <Tooltip key='Annulla' title='Annulla'>
                    <ClearIcon />
                  </Tooltip>
                </IconButton>
              </center>
            </Grid>
          </Grid>
        )) || (
          <Grid item xs={12}>
            <IconButton
              size='small'
              style={{ color: colours.black }}
              onClick={() => {
                setAddingDisponibilita(true)
                setNewGiorno()
                setNewDalle()
                setNewAlle()
              }}
            >
              <AddIcon />
              &nbsp; Nuova disponibilità
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid {...containerProps}>
        <Grid item xs={12}>
          <h4>
            Indisponibilità puntuali &nbsp;
            <IconButton
              style={{ color: colours.grey }}
              onClick={handleClickOpenIndisp}
            >
              <Tooltip key='info' title='Maggiori informazioni'>
                <InfoIcon />
              </Tooltip>
            </IconButton>
          </h4>
        </Grid>
        <Grid container item xs={12} alignItems='center'>
          <Grid item xs={10} md={11}>
            <AutoComplete
              id='indisponibilita-puntuali'
              label='Indisponibilità puntuali'
              value={fIndPun('in')}
              options={[]}
              onChange={setIndisponibilitaPuntuali}
              onInputChange={(_, __, reason) => {
                if (reason === 'input') handleClickOpenIndispTyping()
              }}
              getOptionLabel={op => fromDateToString(op)}
              multiple={true}
              openable={false}
              optional={true}
              showPopupIcon={false}
              mode={mode}
            />
          </Grid>
          <Grid item xs={2} md={1} sx={{ cursor: 'pointer' }}>
            <center>
              <DatePicker
                id='date-picker'
                value={fIndPun('in')}
                onChange={setIndisponibilitaPuntuali}
                disabled={false}
                disableYearPicker={true}
                disableMonthPicker={true}
                fullYear={false}
                hideOnScroll={true}
                highlightToday={false}
                multiple={true}
                readOnly={false}
                sort={true}
                calendarPosition='left'
                format='DD/MM/YYYY'
                numberOfMonths={1}
                weekStartDayIndex={1}
                months={MONTHS}
                weekDays={WEEK_DAYS}
                minDate={minDate}
                maxDate={maxDate}
                currentDate={new DateObject()}
                className={isMobile && 'rmdp-mobile'}
                mobileLabels={{ OK: 'OK', CANCEL: 'ANNULLA' }}
                render={(_, openCalendar) => (
                  <DateRangeIcon onClick={openCalendar} />
                )}
              />
            </center>
          </Grid>
        </Grid>
      </Grid>
      {(admin || designatore) && (
        <Grid {...containerProps}>
          <Grid item xs={12}>
            <h4>
              Società incompatibili &nbsp;
              <IconButton
                style={{ color: colours.grey }}
                onClick={handleClickOpenSocieta}
              >
                <Tooltip key='info' title='Maggiori informazioni'>
                  <InfoIcon />
                </Tooltip>
              </IconButton>
            </h4>
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              id='codici-societa-incompatibili'
              value={affiliazioniLight?.filter(a =>
                Arbitro?.codiciSocietaIncompatibili?.includes(a.id)
              )}
              multiple={true}
              optional={true}
              options={affiliazioniLight}
              getOptionLabel={op => `${op.codiceSocieta} - ${op.nomeSocieta}`}
              noOptionsText='Nessuna società trovata'
              label='Società incompatibili'
              onChange={i =>
                aDispatch(
                  'codiciSocietaIncompatibili',
                  i.map(a => a.id)
                )
              }
              mode={mode}
            />
          </Grid>
        </Grid>
      )}
      {(admin || designatore) && (
        <Grid {...containerProps}>
          <Grid item xs={12}>
            <h4>
              Arbitro in attività &nbsp;
              <IconButton
                style={{ color: colours.grey }}
                onClick={handleClickOpenActive}
              >
                <Tooltip key='active' title='Maggiori informazioni'>
                  <InfoIcon />
                </Tooltip>
              </IconButton>
            </h4>
          </Grid>
          <Grid item xs={12}>
            Arbitro attivo &nbsp;
            <Checkbox
              id='is-active'
              checked={Arbitro?.isActive !== false}
              onChange={event => aDispatch('isActive', event.target.checked)}
              color='default'
            />
          </Grid>
        </Grid>
      )}
      &nbsp;
      {(admin || designatore) && (
        <Grid item xs={12}>
          <InputField
            id='note'
            value={Arbitro?.note}
            onChange={i => aDispatch('note', i)}
            fullWidth
            label='Note del designatore'
            mode={mode}
          />
        </Grid>
      )}
      <Grid {...containerProps}>
        <Grid item xs>
          <center>
            <Link to='/arbitri' style={{ textDecoration: 'none' }}>
              <Button error={1}>Indietro</Button>
            </Link>
          </center>
        </Grid>
        <Grid item xs>
          <center>
            <Button dark={1} onClick={confirmArbitro}>
              Salva
            </Button>
          </center>
        </Grid>
      </Grid>
      <DialogMini
        open={openDisponibilita}
        handleClose={handleCloseDisponibilita}
        title={titles.titleDisponibilita}
        textContent={phrases.phraseDisponibilita}
        textConfirm='Ok'
        triggerFunction={handleCloseDisponibilita}
      />
      <DialogMini
        open={openSocieta}
        handleClose={handleCloseSocieta}
        title={titles.titleSocieta}
        textContent={phrases.phraseSocieta}
        textConfirm='Ok'
        triggerFunction={handleCloseSocieta}
      />
      <DialogMini
        open={openIndisp}
        handleClose={handleCloseIndisp}
        title={titles.titleIndisp}
        textContent={phrases.phraseIndisp}
        textConfirm='Ok'
        triggerFunction={handleCloseIndisp}
      />
      <DialogMini
        open={openIndispTyping}
        handleClose={handleCloseIndispTyping}
        title={titles.titleIndispTyping}
        textContent={phrases.phraseIndispTyping}
        textConfirm='Ok'
        triggerFunction={handleCloseIndispTyping}
      />
      <DialogMini
        open={openActive}
        handleClose={handleCloseActive}
        title={titles.titleActive}
        textContent={phrases.phraseActive}
        textConfirm='Ok'
        triggerFunction={handleCloseActive}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  affiliazioniLight: state.home.affiliazioniLight,
  dummyUpdate: state.home.dummyUpdate,
  isMobile: state.home.isMobile,
  Arbitro: state.arbitri.arbitro,
})

const ConnectedArbitro = connect(mapStateToProps)(Arbitro)

export default ConnectedArbitro
