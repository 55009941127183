import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { sendRequest } from './http-hook'
import { fromStringsToDateTime, fetchAndDispatch } from './utils-functions'
import {
  SET_AFFILIAZIONI_LIGHT,
  SET_ISCRIZIONI_LIGHT,
  SET_ARBITRI_LIGHT,
  SET_IMPOSTAZIONI,
  SET_ZONE,
  SET_CAMPIONATI,
  SET_CATEGORIE,
  SET_ERROR,
  SET_LOADING_SPOSTAMENTI,
  SET_LOADING_ARBITRI,
  SET_LOADING_DESIGNAZIONI,
  SET_LOADING_ARBITRAGGI,
  SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE,
  SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI,
  SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI,
  SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI,
} from '../container/home/types'
import { SET_ARBITRI } from '../container/arbitri/types'
import { SET_SPOSTAMENTI } from '../container/spostamenti/types'
import { SET_DESIGNAZIONI } from '../container/designazioni/types'
import { SET_ARBITRAGGI } from '../container/arbitraggi/types'

export const useFetch = (
  infoUtente,
  campionati,
  selectedStagione,
  selectedCampionato,
  dummyUpdate
) => {
  const { admin, societa, arbitro, designatore, idArbitro, codiceUtente } =
    infoUtente

  const dispatch = useDispatch()

  const fetchArbitriAll = (admin || designatore) && codiceUtente // serve in "Arbitri", "Designazioni" e "Rimborsi" a utenti "admin" e "designatore"
  const fetchArbitriLight = arbitro && codiceUtente // serve in "DialogDesignazione" a utenti "arbitro"

  const campionato = campionati?.find(c => c.codice === selectedCampionato)

  dispatch({
    type: SET_SELECTED_CAMPIONATO_ISCRIZIONI_APERTE,
    payload: !!campionato?.iscrizioniAperte,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_GIRONI_PROVVISORI,
    payload: !!campionato?.gironiProvvisori,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_GIRONI_PUBBLICATI,
    payload: !!campionato?.gironiPubblicati,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_CALENDARI_PROVVISORI,
    payload: !!campionato?.calendariProvvisori,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_CALENDARI_PUBBLICATI,
    payload: !!campionato?.calendariPubblicati,
  })

  dispatch({
    type: SET_SELECTED_CAMPIONATO_SPOSTAMENTI_GRATUITI,
    payload:
      fromStringsToDateTime(campionato?.termineSpostamentiGratuiti, '23:59') >
      new Date(),
  })

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch affiliazioni light
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({ type: SET_AFFILIAZIONI_LIGHT, payload: x })

    if ((admin || arbitro || designatore) && codiceUtente) {
      fetchAndDispatchFunction({
        url: 'affiliazioni/light',
        errorText: 'le società "light"',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    admin,
    arbitro,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
  ])

  // fetch iscrizioni light
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({ type: SET_ISCRIZIONI_LIGHT, payload: x })

    if (selectedCampionato) {
      fetchAndDispatchFunction({
        url: `iscrizioni/campionato/${selectedCampionato}/light`,
        errorText: 'le iscrizioni "light"',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [dispatch, fetchAndDispatchFunction, selectedCampionato])

  // fetch arbitri
  useEffect(() => {
    const dispatchFunction = x => dispatch({ type: SET_ARBITRI, payload: x })

    const dispatchLoading = x =>
      dispatch({ type: SET_LOADING_ARBITRI, payload: x })

    if (fetchArbitriAll) {
      fetchAndDispatchFunction({
        url: 'arbitri/all',
        errorText: 'gli arbitri',
        dispatchLoading,
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [dispatch, dummyUpdate, fetchAndDispatchFunction, fetchArbitriAll])

  // fetch arbitri "light"
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({ type: SET_ARBITRI_LIGHT, payload: x })

    const dispatchLoading = x =>
      dispatch({ type: SET_LOADING_ARBITRI, payload: x })

    if (fetchArbitriLight) {
      fetchAndDispatchFunction({
        url: 'arbitri/light',
        errorText: 'gli arbitri "light"',
        dispatchLoading,
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [dispatch, fetchAndDispatchFunction, fetchArbitriLight])

  // fetch impostazioni
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({ type: SET_IMPOSTAZIONI, payload: x?.[0] })

    if (admin)
      fetchAndDispatchFunction({
        url: 'impostazioni/all',
        errorText: 'le impostazioni',
        dispatchFunction,
      })
  }, [admin, dispatch, dummyUpdate, fetchAndDispatchFunction])

  // fetch zone
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({
        type: SET_ZONE,
        payload: x?.sort((a, b) => (a.zona > b.zona ? 1 : -1)),
      })

    if (admin && codiceUtente) {
      fetchAndDispatchFunction({
        url: 'zone/all',
        errorText: 'le zone',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [admin, codiceUtente, dispatch, dummyUpdate, fetchAndDispatchFunction])

  // fetch campionati
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({
        type: SET_CAMPIONATI,
        payload: x?.sort((a, b) => b.indice - a.indice),
      })

    fetchAndDispatchFunction({
      url: 'campionati/all',
      errorText: 'i campionati',
      dispatchFunction,
    })
  }, [dispatch, dummyUpdate, fetchAndDispatchFunction])

  // fetch categorie
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({
        type: SET_CATEGORIE,
        payload: x?.sort((a, b) => (a.nome > b.nome ? 1 : -1)),
      })

    fetchAndDispatchFunction({
      url: 'categorie/all',
      errorText: 'le categorie',
      dispatchFunction,
    })
  }, [dispatch, dummyUpdate, fetchAndDispatchFunction])

  // fetch spostamenti
  useEffect(() => {
    const url = `spostamenti/campionato/${selectedCampionato}${
      admin ? '' : `/societa/${codiceUtente}`
    }`

    const dispatchFunction = x =>
      dispatch({ type: SET_SPOSTAMENTI, payload: x?.reverse() })

    const dispatchLoading = x =>
      dispatch({ type: SET_LOADING_SPOSTAMENTI, payload: x })

    if ((admin || societa) && codiceUtente && selectedCampionato) {
      fetchAndDispatchFunction({
        url,
        errorText: 'gli spostamenti',
        dispatchLoading,
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    admin,
    codiceUtente,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
    selectedCampionato,
    societa,
  ])

  // fetch designazioni
  useEffect(() => {
    const fStagione =
      selectedStagione !== 'Tutte' ? `/stagione/${selectedStagione}` : '' // "maximum update depth exceeded" with availableStagioni[0]

    const url = `designazioni${
      admin || designatore ? '/all' : `${fStagione}/arbitro/${idArbitro}` // get all to correctly check overlaps
    }`

    const dispatchFunction = x =>
      dispatch({ type: SET_DESIGNAZIONI, payload: x?.reverse() })

    const dispatchLoading = x =>
      dispatch({ type: SET_LOADING_DESIGNAZIONI, payload: x })

    if (
      (admin || designatore || (arbitro && selectedStagione && idArbitro)) &&
      codiceUtente
    ) {
      fetchAndDispatchFunction({
        url,
        errorText: 'le designazioni',
        dispatchLoading,
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    admin,
    arbitro,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
    idArbitro,
    selectedStagione,
  ])

  // fetch arbitraggi
  useEffect(() => {
    const url = `arbitraggi/campionato/${selectedCampionato}${
      admin || designatore ? '' : `/societa/${codiceUtente}`
    }`

    const dispatchFunction = x =>
      dispatch({ type: SET_ARBITRAGGI, payload: x?.reverse() })

    const dispatchLoading = x =>
      dispatch({ type: SET_LOADING_ARBITRAGGI, payload: x })

    if (
      (admin || societa || designatore) &&
      codiceUtente &&
      selectedCampionato
    ) {
      fetchAndDispatchFunction({
        url,
        errorText: 'gli arbitraggi',
        dispatchLoading,
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    admin,
    codiceUtente,
    designatore,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
    selectedCampionato,
    societa,
  ])
}
