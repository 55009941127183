import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import Button from '../components/Button'
import DialogMini from '../components/DialogMini'
import SortableTableHead from '../components/SortableTableHead'
import ButtonRunDownload from '../components/ButtonRunDownload'
import GridCircularProgress from '../components/GridCircularProgress'
import { colours, titlesArbitro as titles } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import { SET_DUMMY_UPDATE } from '../container/home/types'
import {
  SET_ARBITRI,
  SET_SORTING_COLUMN,
  SET_SORTING_ASCENDING,
  SET_ARBITRO,
} from '../container/arbitri/types'

const Arbitri = ({
  infoUtente,
  dummyUpdate,
  loadingArbitri,
  arbitri,
  sortingColumn,
  sortingAscending,
  deleteAlsoDesignazioni,
}) => {
  const { admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  const history = useHistory()

  document.title = 'PGS Milano - Arbitri'

  let columnsArbitri = [
    { label: 'Codice fiscale', sortable: true, sortingField: 'codiceFiscale' },
    { label: 'Cognome', sortable: true, sortingField: 'cognome' },
    { label: 'Nome', sortable: true, sortingField: 'nome' },
    { label: 'Domicilio', sortable: true, sortingField: 'cittaDomicilio' },
    { label: 'Stato', sortable: true, sortingField: 'status' },
  ]

  if (admin)
    columnsArbitri = [...columnsArbitri, { label: '', sortable: false }]

  const { setFeedback } = useFeedback()

  // state to manage the delete dialog
  const [openDeleteArbitro, setOpenDeleteArbitro] = useState(false)
  const [deletingArbitroId, setDeletingArbitroId] = useState()

  const handleClickOpenDeleteArbitro = id => {
    setDeletingArbitroId(id)
    setOpenDeleteArbitro(true)
  }

  const handleCloseDeleteArbitro = () => {
    setOpenDeleteArbitro(false)
    setDeletingArbitroId()
  }

  const editArbitro = async id => {
    if (!id) {
      setFeedback('Arbitro non trovato.')
      return
    }

    try {
      const data = await sendRequest(`arbitri/${id}`, 'GET', null, {
        Authorization: codiceUtente,
      })

      if (!data || !data.data) {
        setFeedback('Arbitro non trovato.')
      } else {
        dispatch({ type: SET_ARBITRO, payload: data.data })
        history.push('/arbitro')
      }
    } catch (err) {
      setFeedback(err.message)
    }
  }

  const deleteArbitro = async () => {
    if (!deletingArbitroId) {
      setFeedback('Arbitro non trovato.')
      return
    }

    try {
      if (admin && deleteAlsoDesignazioni) {
        await sendRequest(
          `designazioni/arbitro/${deletingArbitroId}`,
          'DELETE',
          null,
          { Authorization: codiceUtente }
        )
      }

      await sendRequest(`arbitri/${deletingArbitroId}`, 'DELETE', null, {
        Authorization: codiceUtente,
      })
      setFeedback('Arbitro eliminato correttamente.', false)
      handleCloseDeleteArbitro()
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      setFeedback(err.message)
    }
  }

  return (
    <Grid container item paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>Arbitri</h2>
        </center>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} md={3} />
        <Grid item xs={12} md={6}>
          {admin && (
            <center>
              <Link
                to='/arbitro'
                style={{ textDecoration: 'none' }}
                onClick={() => dispatch({ type: SET_ARBITRO })}
              >
                <Button>Nuovo arbitro</Button>
              </Link>
            </center>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <ButtonRunDownload
            buttonText='Scarica Excel'
            algorithm='10_GenerateExcelArbitri'
            type='XLSX'
          />
        </Grid>
      </Grid>
      &nbsp;
      {(loadingArbitri && <GridCircularProgress />) ||
        (!arbitri && (
          <Grid item xs={12}>
            <center>
              <h4>Elenco degli arbitri non trovato.</h4>
            </center>
          </Grid>
        )) ||
        (arbitri.length === 0 && (
          <Grid item xs={12}>
            <center>
              <h4>Non è ancora stato creato nessun arbitro.</h4>
            </center>
          </Grid>
        )) || (
          <Grid container padding={3}>
            <TableContainer component={Paper}>
              <Table size='small'>
                <SortableTableHead
                  table={arbitri.map(a => {
                    return { ...a, status: a.isActive ? a.status : 'inactive' }
                  })}
                  setTable={t => dispatch({ type: SET_ARBITRI, payload: t })}
                  columns={columnsArbitri}
                  sortingColumn={sortingColumn}
                  setSortingColumn={SET_SORTING_COLUMN}
                  sortingAscending={sortingAscending}
                  setSortingAscending={SET_SORTING_ASCENDING}
                />
                <TableBody>
                  {arbitri.map((a, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: colours.white,
                          ':hover': { backgroundColor: colours.greyVeryLight },
                        }}
                        onClick={() => editArbitro(a.id)}
                      >
                        <TableCell align='center'>{a.codiceFiscale}</TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {a.cognome}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {a.nome}
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          {a.cittaDomicilio}
                        </TableCell>
                        <TableCell align='center'>
                          <Chip
                            variant={
                              a.status === 'complete' || !a.isActive
                                ? 'outlined'
                                : 'filled'
                            }
                            label={
                              (!a.isActive && 'inattivo') ||
                              (a.status === 'complete' && 'completo') ||
                              (a.status === 'incomplete' && 'da completare') ||
                              'sconosciuto'
                            }
                            color={
                              (!a.isActive && 'error') ||
                              (a.status === 'complete' && 'success') ||
                              (a.status === 'incomplete' && 'warning') ||
                              'default'
                            }
                          />
                        </TableCell>
                        {admin && (
                          <TableCell align='center'>
                            <Grid container item>
                              <Grid item xs>
                                <IconButton
                                  style={{ color: colours.red }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    handleClickOpenDeleteArbitro(a._id)
                                  }}
                                >
                                  <Tooltip title='Elimina'>
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      <DialogMini
        open={openDeleteArbitro}
        handleClose={handleCloseDeleteArbitro}
        title={titles.titleDelete}
        textUndo='Annulla'
        textConfirm='Ok'
        triggerFunction={deleteArbitro}
        colourBackground={colours.red}
        colourHover={colours.redDark}
        dialogDeleteArbitro={true}
        idArbitro={deletingArbitroId}
      />
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  dummyUpdate: state.home.dummyUpdate,
  loadingArbitri: state.home.loadingArbitri,
  arbitri: state.arbitri.arbitri,
  sortingColumn: state.arbitri.sortingColumn,
  sortingAscending: state.arbitri.sortingAscending,
  deleteAlsoDesignazioni: state.arbitri.deleteAlsoDesignazioni,
})

const ConnectedArbitri = connect(mapStateToProps)(Arbitri)

export default ConnectedArbitri
