import React from 'react'

import { REACT_HOST } from '../hooks/env-variables'

let colours = {
  white: 'rgb(255, 255, 255)',
  greyVeryLight: 'rgb(233, 236, 239)',
  greyLight: 'rgb(171, 177, 182)',
  grey: 'rgb(108, 117, 125)',
  greyDark: 'rgb(57, 62, 68)',
  greyVeryDark: 'rgb(26, 29, 32)',
  black: 'rgb(0, 0, 0)',
  backDrop: 'rgba(0, 0, 0, 0.6)',
  highlight: 'rgb(255, 255, 220)',
  highlightBlue: 'rgb(210, 235, 255)',
  highlightGreen: 'rgb(220, 255, 220)',
  goldenSet: 'rgb(255,235,50)',
  green: 'rgb(0, 155, 0)',
  greenExcel: 'rgb(29, 111, 66)',
  greenExcelHover: 'rgba(29, 111, 66, 0.88)',
  blueLight: 'rgb(65, 130, 225)',
  blue: 'rgb(55, 110, 210)',
  blueDark: 'rgb(45, 90, 195)',
  blueVeryDark: 'rgb(35, 70, 180)',
  redLight: 'rgb(230, 36, 41)',
  red: 'rgb(210, 23, 40)',
  redDark: 'rgb(180, 10, 39)',
  redError: 'rgba(210, 23, 40, 0.15)',
  blueBack: 'rgba(55, 110, 210, 0.3)',
  drawerText: 'rgb(255, 255, 255)',
  headerBack: 'rgb(233, 236, 239)',
  gold: 'rgb(255, 215, 0)',
  bronze: 'rgb(205, 127, 50)',
}

const today = new Date()
const [day, month, year] = [
  today.getDate(),
  today.getMonth() + 1,
  today.getFullYear(),
]

// Halloween theme!
if ((day >= 26 && month === 10) || (day <= 3 && month === 11))
  colours = {
    ...colours,
    blueLight: 'rgb(108, 117, 125)',
    blue: 'rgb(57, 62, 68)',
    blueDark: 'rgb(26, 29, 32)',
    blueVeryDark: 'rgb(0, 0, 0)',
    redLight: 'rgb(255, 166, 77)',
    red: 'rgb(255, 140, 26)',
    redDark: 'rgb(230, 115, 0)',
    redError: 'rgba(255, 166, 77, 0.3)',
    blueBack: 'rgba(171, 177, 182, 0.3)',
    drawerText: 'rgb(255, 166, 77)',
    headerBack: 'rgb(255, 166, 77)',
  }

// Christmas theme!
if ((month === 12 && day >= 20) || (month === 1 && day <= 6))
  colours = {
    ...colours,
    blueLight: 'rgb(150, 225, 150)',
    blue: 'rgb(130, 220, 120)',
    blueDark: 'rgb(100, 215, 90)',
    blueVeryDark: 'rgb(70, 210, 60)',
    blueBack: 'rgba(150, 225, 150, 0.3)',
    drawerText: 'rgb(0, 40, 0)',
    headerBack: 'rgb(0, 40, 0)',
  }

// Easter theme!
if (year === 2024 && ((month === 3 && day >= 27) || (month === 4 && day <= 3)))
  colours = {
    ...colours,
    blueLight: 'rgb(150, 225, 150)',
    blue: 'rgb(130, 220, 120)',
    blueDark: 'rgb(100, 215, 90)',
    blueVeryDark: 'rgb(70, 210, 60)',
    redLight: 'rgb(255, 175, 200)',
    red: 'rgb(255, 150, 180)',
    redDark: 'rgb(255, 125, 160)',
    redError: 'rgba(255, 175, 200, 0.3)',
    blueBack: 'rgba(150, 225, 150, 0.3)',
    drawerText: 'rgb(26, 29, 32)',
    headerBack: 'rgb(26, 29, 32)',
  }

// Development theme!
if (REACT_HOST === 'localhost')
  colours = {
    ...colours,
    drawerText: 'rgb(0, 0, 0)',
    headerBack: 'rgb(0, 0, 0)',
  }

export { colours }

export const titlesIscrizioni = {
  titleDelete: 'Eliminare la squadra?',
  titleIndirizzo: 'Cambio di indirizzo',
  titleSpostamentoInfo: 'Spostamento gara',
  titleSpostamentoDomanda: 'Richiesta di spostamento gara',
  titleSpostamentoAccetta: 'Spostamento accettato',
  titleSpostamentoRifiuta: 'Spostamento rifiutato',
  titleRisultato: 'Invia risultato',
  titleArbitraggio: 'Richiesta di arbitraggio',
}

export const titlesIscrizione = {
  titleCompleteAdd: 'Iscrizione effettuata correttamente',
  titleCompleteUpdate: 'Iscrizione aggiornata correttamente',
  titleIncomplete: 'Salvataggio effettuato correttamente',
  titleInfo: 'Abbinamenti e alternanze',
  titleDeroga: 'Deroga',
}

export const titlesAffiliazione = {
  titleSuccessAdd: 'Società creata correttamente',
  titleSuccessUpdate: 'Società aggiornata correttamente',
  titleDelete: 'Eliminare la società?',
}

export const titlesArbitri = {
  titleRimborso: 'Generazione rimborso spese',
}

export const titlesArbitro = {
  titleSuccessAdd: 'Arbitro creato correttamente',
  titleSuccessUpdate: 'Profilo aggiornato correttamente',
  titleDelete: "Eliminare l'arbitro?",
  titleDisponibilita: 'Disponibilità settimanali',
  titleSocieta: 'Società incompatibili',
  titleIndisp: 'Indisponibilità puntuali',
  titleIndispTyping: 'Digitazione disabilitata',
  titleActive: 'Arbitro attivo o inattivo',
}

export const titlesImpostazioni = {
  titleCheck: 'Salvare?',
  titleComplete: 'Impostazioni aggiornate correttamente',
  titleIncomplete: 'Impostazioni aggiornate correttamente',
  titleUndo: 'Annullare?',
  titleClear: 'Svuotare?',
  titleZone: 'Zone',
  titleDomeniche: 'Giornate',
  titleAccoppiamenti: 'Accoppiamenti',
  titleCampionati: 'Campionati',
  titleCategorie: 'Categorie',
  titleTabelloni: 'Formule tabelloni',
  titleTabellone: 'Nuovo tabellone',
}

export const titlesGironi = {
  titleChange: 'Spostamento',
}

export const titlesCalendari = {
  titleEditCalendario: 'Spostamento gara',
  titleEditRisultato: 'Inserimento risultato',
  titleEditPenalita: 'Inserimento penalità',
  titleEditOmologa: 'Omologa gara',
  titleEditArbitraggio: 'Arbitraggio ufficiale',
  titleEditRipescaggio: 'Ripescaggio',
  titleDeleteGara: 'Eliminazione gara',
}

export const titlesProvvedimenti = {
  titleEditSanzioneRisultato: 'Sanzione per risultato inviato in ritardo',
  titleEditSanzioneReferto: 'Sanzione per referto inviato in ritardo',
}

export const titlesDesignazioni = {
  titleDesignazione: 'Designazione',
  titleDesignazione1: 'Designazione come 1° arbitro',
  titleDesignazione2: 'Designazione come 2° arbitro',
  titleDesignazioneDomanda: 'Designazione',
  titleDesignazioneAccetta: 'Designazione accettata',
  titleDesignazioneRifiuta: 'Designazione rifiutata',
}

export const titlesRimborso = {
  titleRimborso: 'Rimborso',
  titleAddSuccess: 'Rimborso creato',
  titleUpdateSuccess: 'Rimborso aggiornato',
  titlePagato: 'Pagamento',
  titlePagatoSuccess: 'Stato del pagamento aggiornato',
  titleDelete: 'Eliminare il rimborso?',
  titleDeleteSuccess: 'Rimborso eliminato',
}

export const titlesRendiconto = {
  titleRendiconto: 'Rendiconto',
  titleAddSuccess: 'Rendiconto creato',
  titleUpdateSuccess: 'Rendiconto aggiornato',
}

export const titlesArbitraggi = {
  titleDelete: 'Eliminazione richiesta di arbitraggio',
}

export const phrasesIscrizioni = {
  phrasesIndirizzoSuccess: "L'indirizzo è stato aggiornato correttamente.",
  phraseSpostamentoDomandaSuccess:
    'La richiesta di spostamento è stata presa in carico. Non appena la società avversaria accetterà lo spostamento, le variazioni saranno visibili nel calendario.',
  phraseSpostamentoAccetta:
    'Lo spostamento è stato accettato, le variazioni sono già visibili nel calendario.',
  phraseSpostamentoRifiuta:
    'Lo spostamento è stato rifiutato, la calendarizzazione della gara rimane invariata.',
  phraseRisultatoSuccess:
    'Il risultato è stato inviato correttamente ed è già visibile nel calendario.',
  phraseArbitraggioSuccess: "L'arbitraggio è stato richiesto correttamente.",
}

export const phrasesIscrizione = {
  phraseInfo: (
    <span>
      Per <b>abbinamento</b> si intende una squadra (della propria società) con
      cui si vorrebbe giocare in casa nelle stesse giornate.
      <br />
      Per <b>alternanza</b> si intende una squadra (della propria società) con
      cui si vorrebbe giocare in casa in giornate diverse.
      <br />
      <br />
      <b>
        Affinchè una squadra possa essere abbinata o alternata all'iscrizione
        corrente, bisogna che sia già stata iscritta precedentemente (senza
        preferenze di abbinamento o alternanza, che saranno gestite in
        automatico).
      </b>
      <br />
      <br />
      Per esempio, per richiedere che le squadre A e B giochino in casa nelle
      stesse giornate, bisognerà prima iscrivere la squadra A senza specificare
      alcun abbinamento e poi iscrivere la squadra B specificando che dovrà
      essere abbinata alla squadra A. Fatto questo, il sistema aggiornerà
      automaticamente anche i dati della squadra A abbinandola alla squadra B.
      <br />
      <br />
      Un esempio più complesso e abbastanza comune è il seguente: ci sono 4
      squadre (A, B, C, D) che devono essere abbinate e alternate a coppie, cioè
      con A e B che giocano in casa nelle stesse giornate ma in giornate diverse
      rispetto a C e D. In questo caso bisognerà inserire per ciascuna squadra 1
      abbinamento (con la squadra che dovrà giocare in casa nelle stesse
      giornate) e 2 alternanze (con le due squadre che dovranno giocare in casa
      in giornate diverse). Quindi A dovrà essere abbinata a B e alternata a C e
      D, e così via.
    </span>
  ),
  phraseDeroga:
    'Una squadra con deroga partecipa normalmente alla fase a gironi del campionato ma non può qualificarsi per le fasi finali,' +
    ' perciò è contrassegnata da un punto esclamativo rosso nella pagina Classifiche.',
}

export const phrasesArbitro = {
  phraseDisponibilita:
    'Le disponibilità settimanali sono i giorni della settimana e i relativi orari in cui ci si dichiara disponibili ad arbitrare le gare PGS.' +
    ' Per aggiungere una nuova disponibilità, cliccare su "Nuova disponibilità" poi scegliere un giorno della settimana e infine gli orari, intesi come orari di inizio gara.',
  phraseSocieta:
    'Le società incompatibili sono le società che non si possono arbitrare o che si preferisce non arbitrare.' +
    ' Le ragioni di tale incompatibilità vanno sempre e comunque discusse con il designatore.',
  phraseIndisp:
    'Le indisponibilità puntuali sono le date in cui non si riesce ad arbitrare anche se rientrano' +
    ' nelle proprie disponibilità settimanali, per esempio a causa di vacanze o altri impegni personali.',
  phraseIndispTyping:
    "Cliccare sull'icona del calendario a destra per selezionare le date.",
  phraseActive:
    'Un arbitro "inattivo" viene nascosto durante il processo di designazione, così che non venga designato per nessuna gara, ma rimane comunque presente in piattaforma.' +
    ' Questa funzionalità è pensata per non dover eliminare gli arbitri che hanno sospeso o terminato la loro attività.',
}

export const phrasesImpostazioni = {
  phraseComplete:
    'Tutti i campi sono stati completati correttamente. È possibile salvare le impostazioni.',
  phraseIncomplete:
    'Alcuni campi non sono stati completati correttamente. È comunque possibile salvare le impostazioni.',
  phraseInvalid:
    'Inserire il codice del campionato e lo stato delle iscrizioni.',
  phraseCompleteSuccess:
    "L'aggiornamento delle impostazioni è avvenuto correttamente!",
  phraseIncompleteSuccess:
    "L'aggiornamento delle impostazioni è avvenuto correttamente. Riempire i campi mancanti per completare le impostazioni.",
  phraseUndo: 'Le modifiche effettuate andranno perse.',
  phraseClear: 'Svuotare tutti i campi e ripartire da capo?',
}

export const phrasesCalendari = {
  phraseCalendarioSuccess: 'Lo spostamento è stato registrato.',
  phraseRisultatoSuccess: 'Il risultato è stato aggiornato correttamente.',
  phrasePenalitaSuccess: 'Le penalità sono state aggiornate correttamente.',
  phraseOmologaSuccess: "L'omologa è stata aggiornata correttamente.",
  phraseArbitraggioSuccess: "L'arbitraggio è stato aggiornato correttamente.",
  phraseRipescaggioSuccess: 'Il ripescaggio è stato effettuato correttamente.',
  phraseDeleteGara:
    'Eliminando questa gara, verranno eliminati anche eventuali relativi spostamenti, designazioni e richieste di arbitraggio.',
  phraseDeleteGaraSuccess:
    "L'eliminazione della gara e di eventuali spostamenti, designazioni e richieste di arbitraggio è stata effettuata correttamente.",
}

export const phrasesSpostamento = {
  phraseSposta: "Cambiano la data e l'ora di disputa della gara.",
  phraseSpostaInverti:
    "Cambiano la data e l'ora di disputa della gara, inoltre la squadra in casa e la squadra in trasferta vengono scambiate.",
  phraseInverti:
    'La squadra in casa e la squadra in trasferta vengono scambiate.',
  phraseRimanda:
    "Data e ora vengono cancellate dal calendario. Le due squadre dovranno accordarsi per trovare un'altra calendarizzazione, da ufficializzare tramite nuovo spostamento.",
}

export const phrasesDesignazione = {
  phraseDesignazioneDomandaSuccess:
    'La proposta di designazione è stata inviata.',
  phraseDesignazioneAccetta: 'La designazione è stata accettata.',
  phraseDesignazioneRifiuta: 'La designazione è stata rifiutata.',
}

export const phrasesRimborso = {
  phraseAddSuccess:
    'Le informazioni sono state salvate correttamente, è ora possibile scaricare il file Excel.',
  phraseUpdateSuccess:
    'Le informazioni sono state salvate correttamente, è ora possibile scaricare il file Excel aggiornato.',
  phrasePagatoSuccess:
    'Lo stato del pagamento è stato aggiornato correttamente.',
  phraseDelete:
    'Eliminando questo rimborso, verranno cancellate anche tutte le informazioni inserite.' +
    ' Sarà poi possibile generarne uno nuovo con le stesse partite.',
  phraseDeleteSuccess: 'Il rimborso è stato eliminato correttamente.',
}

export const phrasesRendiconto = {
  phraseAddSuccess: 'Il rendiconto è stato creato correttamente.',
  phraseUpdateSuccess: 'Il rendiconto è stato aggiornato correttamente.',
}

export const phrasesArbitraggi = {
  phraseDelete: 'Eliminare la richiesta di arbitraggio?',
  phraseDeleteSuccess:
    'La richiesta di arbitraggio è stata eliminata correttamente.',
}

export const GIORNI = [
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
  'Domenica',
]

export const WEEK_DAYS = ['DO', 'LU', 'MA', 'ME', 'GI', 'VE', 'SA']

export const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]
