import React, { useState, useEffect, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Grid,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material'
import ShuffleRoundedIcon from '@mui/icons-material/ShuffleRounded'

import DialogMini from '../components/DialogMini'
import SelectionBar from '../components/SelectionBar'
import ButtonUpload from '../components/ButtonUpload'
import ButtonDownload from '../components/ButtonDownload'
import ButtonRunDownload from '../components/ButtonRunDownload'
import SortableTableHead from '../components/SortableTableHead'
import { colours, titlesGironi as titles } from '../settings/settings'
import { sendRequest } from '../hooks/http-hook'
import { useFeedback } from '../hooks/feedback-hook'
import {
  sortNumerically,
  sortCategorie,
  sortGironi,
} from '../hooks/sort-functions'
import { fetchAndDispatch } from '../hooks/utils-functions'
import { SET_ERROR, SET_DUMMY_UPDATE } from '../container/home/types'
import { SET_ISCRIZIONI_GIRONI } from '../container/gironi/types'

const Gironi = ({
  infoUtente,
  affiliazioniLight,
  dummyUpdate,
  selectedCampionatoGironiProvvisori,
  selectedCampionatoGironiPubblicati,
  selectedCampionato,
  availableCategorie,
  selectedCategoria,
  newTeam,
  newGirone,
  newPosizione,
  newGironeAdd,
  newPosizioneAdd,
  availableFunctions,
  selectedFunction,
  availableGironi,
  selectedGirone,
  selectedTeam,
  iscrizioniGironi,
}) => {
  const { auth, admin, codiceUtente } = infoUtente

  const dispatch = useDispatch()

  document.title = 'PGS Milano - Gironi'

  const { setFeedback } = useFeedback()

  let columnsGironi = [
    { label: 'Squadra', sortable: false },
    { label: 'Gioca in casa', sortable: false },
  ]
  if (auth) {
    if (!admin) {
      columnsGironi = [
        ...columnsGironi,
        { label: 'Referente', sortable: false },
        { label: 'Telefono', sortable: false },
        { label: 'Mail', sortable: false },
      ]
    }
    if (admin) {
      columnsGironi = [
        { label: 'Posizione', sortable: false },
        ...columnsGironi,
        { label: 'Zona', sortable: false },
        { label: 'Abbinamenti', sortable: false },
        { label: 'Alternanze', sortable: false },
        { label: 'Note', sortable: false },
        { label: 'Opzioni', sortable: false },
      ]
    }
  }

  // state to manage the change girone dialog
  const [openChangeGirone, setOpenChangeGirone] = useState(false)
  const [changeGironeIscrizione, setChangeGironeIscrizione] = useState()

  const handleClickOpenChangeGirone = id => {
    setChangeGironeIscrizione(iscrizioniGironi.find(i => i.id === id))
    setOpenChangeGirone(true)
  }

  const handleCloseChangeGirone = () => {
    setOpenChangeGirone(false)
    setChangeGironeIscrizione()
  }

  const fetchAndDispatchFunction = useCallback(
    params =>
      fetchAndDispatch({
        ...params,
        getRequest: async url =>
          await sendRequest(url, 'GET', null, { Authorization: codiceUtente }),
        dispatchError: x => dispatch({ type: SET_ERROR, payload: x }),
      }),
    [codiceUtente, dispatch]
  )

  // fetch iscrizioni gironi
  useEffect(() => {
    const dispatchFunction = x =>
      dispatch({ type: SET_ISCRIZIONI_GIRONI, payload: x })

    if (selectedCampionato && (admin || selectedCampionatoGironiPubblicati)) {
      fetchAndDispatchFunction({
        url: `iscrizioni/campionato/${selectedCampionato}${
          auth ? '' : '/gironi'
        }`,
        errorText: 'i gironi',
        dispatchFunction,
      })
    } else {
      dispatchFunction()
    }
  }, [
    admin,
    auth,
    dispatch,
    dummyUpdate,
    fetchAndDispatchFunction,
    selectedCampionato,
    selectedCampionatoGironiPubblicati,
  ])

  // change iscrizione girone and posizione
  const changeGirone = async () => {
    if (!changeGironeIscrizione?.id) {
      setFeedback('Nessuna squadra trovata!')
      return
    }

    if (!availableFunctions.includes(selectedFunction)) {
      setFeedback('Funzionalità non trovata!')
      return
    }

    const patchRequest = async (url, body) =>
      await sendRequest(url, 'PATCH', body, {
        'Content-Type': 'application/json',
        Authorization: codiceUtente,
      })

    try {
      // 'sposta' => swap teams by newGirone and newPosizione
      if (selectedFunction === availableFunctions[0]) {
        if (!newGirone || !newPosizione) {
          setFeedback('Mancano delle informazioni!')
          return
        }

        await patchRequest(
          `iscrizioni/${changeGironeIscrizione.id}/girone`,
          JSON.stringify({ girone: newGirone })
        )
        await patchRequest(
          `iscrizioni/${changeGironeIscrizione.id}/posizione`,
          JSON.stringify({ posizione: newPosizione })
        )

        const newTeamTempID = iscrizioniGironi.find(
          i =>
            i.codiceCampionato === changeGironeIscrizione.codiceCampionato &&
            i.categoria === changeGironeIscrizione.categoria &&
            i.girone === newGirone &&
            i.posizione === newPosizione
        )?._id

        if (newTeamTempID) {
          await patchRequest(
            `iscrizioni/${newTeamTempID}/girone`,
            JSON.stringify({ girone: changeGironeIscrizione.girone })
          )
          await patchRequest(
            `iscrizioni/${newTeamTempID}/posizione`,
            JSON.stringify({ posizione: changeGironeIscrizione.posizione })
          )
        }
      }

      // 'scambia' => swap teams by ID
      if (selectedFunction === availableFunctions[1]) {
        if (!newTeam?.id) {
          setFeedback('Mancano delle informazioni!')
          return
        }

        await patchRequest(
          `iscrizioni/${changeGironeIscrizione.id}/girone`,
          JSON.stringify({ girone: newTeam.girone })
        )
        await patchRequest(
          `iscrizioni/${changeGironeIscrizione.id}/posizione`,
          JSON.stringify({ posizione: newTeam.posizione })
        )
        await patchRequest(
          `iscrizioni/${newTeam.id}/girone`,
          JSON.stringify({ girone: changeGironeIscrizione.girone })
        )
        await patchRequest(
          `iscrizioni/${newTeam.id}/posizione`,
          JSON.stringify({ posizione: changeGironeIscrizione.posizione })
        )
      }

      // 'metti' => put team into a new girone
      if (selectedFunction === availableFunctions[2]) {
        if (!newGironeAdd || !newPosizioneAdd) {
          setFeedback('Mancano delle informazioni!')
          return
        }

        await patchRequest(
          `iscrizioni/${changeGironeIscrizione.id}/girone`,
          JSON.stringify({ girone: newGironeAdd })
        )
        await patchRequest(
          `iscrizioni/${changeGironeIscrizione.id}/posizione`,
          JSON.stringify({ posizione: newPosizioneAdd })
        )
      }

      // 'togli' => remove team from gironi
      if (selectedFunction === availableFunctions[3]) {
        await sendRequest(
          `iscrizioni/${changeGironeIscrizione.id}/girone`,
          'PATCH',
          null,
          { Authorization: codiceUtente }
        )
        await sendRequest(
          `iscrizioni/${changeGironeIscrizione.id}/posizione`,
          'PATCH',
          null,
          { Authorization: codiceUtente }
        )
      }

      handleCloseChangeGirone()
      dispatch({ type: SET_DUMMY_UPDATE, payload: !dummyUpdate })
    } catch (err) {
      console.log(err)
      setFeedback(err.message)
    }
  }

  const GironiTable = ({ categoria, girone }) => {
    const gironeTable = iscrizioniGironi
      .filter(
        i =>
          i.categoria === categoria &&
          (!!girone ? i.girone === girone : !i.girone)
      )
      .sort((a, b) => sortNumerically(a.posizione, b.posizione))

    return (
      <>
        &nbsp;
        <h4 style={{ paddingLeft: 20 }}>
          Categoria {categoria} -{' '}
          {!!girone ? `Girone ${girone}` : 'Senza girone'}
        </h4>
        <TableContainer component={Paper}>
          <Table size='small'>
            <SortableTableHead table={gironeTable} columns={columnsGironi} />
            <TableBody>
              {gironeTable.map((a, index) => {
                return (
                  <TableRow
                    key={index}
                    style={{ backgroundColor: colours.white }}
                  >
                    {admin && (
                      <TableCell align='center'>{a.posizione}</TableCell>
                    )}
                    <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                      {a.id === selectedTeam ? (
                        <b>{a.nomeSquadra}</b>
                      ) : (
                        a.nomeSquadra
                      )}
                    </TableCell>
                    <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                      {!!a.senzaPalestra
                        ? 'no'
                        : (a.giorno &&
                            a.orario &&
                            `${a.giorno} alle ${a.orario}`) ||
                          a.giorno ||
                          (a.orario && `alle ${a.orario}`) ||
                          ''}
                    </TableCell>
                    {auth && !admin && (
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        {a.nomeReferente}
                      </TableCell>
                    )}
                    {auth && !admin && (
                      <TableCell align='center'>
                        {a.telefonoReferente}
                      </TableCell>
                    )}
                    {auth && !admin && (
                      <TableCell align='center'>
                        <a
                          href={`mailto:${a.mailReferente}?subject=Richiesta per campionato PGS Milano`}
                        >
                          {a.mailReferente}
                        </a>
                      </TableCell>
                    )}
                    {admin && (
                      <TableCell align='center'>
                        {
                          affiliazioniLight?.filter(
                            aff => aff.codiceSocieta === a.codiceSocieta
                          )?.[0]?.zona
                        }
                      </TableCell>
                    )}
                    {admin && (
                      <TableCell align='center'>
                        {a.abbinamenti?.length}
                      </TableCell>
                    )}
                    {admin && (
                      <TableCell align='center'>
                        {a.alternanze?.length}
                      </TableCell>
                    )}
                    {admin && (
                      <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                        {a.note}
                      </TableCell>
                    )}
                    {admin && (
                      <TableCell align='center'>
                        <Grid item xs>
                          <IconButton
                            style={{ color: colours.blueDark }}
                            onClick={() => handleClickOpenChangeGirone(a.id)}
                          >
                            <Tooltip title='Sposta'>
                              <ShuffleRoundedIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  return (
    <Grid container paddingY={2}>
      <Grid item xs={12}>
        <center>
          <h2>{`Gironi${
            selectedCampionatoGironiProvvisori ? ' provvisori' : ''
          }`}</h2>
        </center>
      </Grid>
      <SelectionBar />
      &nbsp;
      {(!admin && selectedCampionato && !selectedCampionatoGironiPubblicati && (
        <Grid item xs={12}>
          <center>
            <h4>Gironi non ancora pubblicati.</h4>
          </center>
        </Grid>
      )) || (
        <>
          {admin && (
            <Grid container item xs={12} rowSpacing={3}>
              <Grid item xs={12} md={3}>
                <ButtonUpload
                  buttonText='Carica Gironi'
                  algorithm='04_UpdateGironi'
                  fileName='Input'
                  runScript={true}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ButtonDownload algorithm='04_UpdateGironi' type='TXT' />
              </Grid>
              <Grid item xs={12} md={3}>
                <ButtonRunDownload
                  buttonText='Scarica CSV'
                  algorithm='01_GenerateTable'
                  type='CSV'
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ButtonRunDownload
                  buttonText='Scarica Excel'
                  algorithm='07_GenerateExcelGironi'
                  type='XLSX'
                />
              </Grid>
            </Grid>
          )}
          {!!selectedCategoria &&
            ((!iscrizioniGironi?.length && (
              <Grid item xs={12}>
                <center>
                  <h4>Nessun girone da visualizzare.</h4>
                </center>
              </Grid>
            )) || (
              <Grid container>
                {iscrizioniGironi
                  .filter(i =>
                    !!selectedCategoria &&
                    selectedCategoria !== availableCategorie[0]
                      ? i.categoria === selectedCategoria
                      : true
                  )
                  .map(i => i.categoria)
                  .filter((v, i, s) => s.indexOf(v) === i)
                  .filter(categoria => !!categoria)
                  .sort((a, b) => sortCategorie(a, b))
                  .map(categoria =>
                    iscrizioniGironi
                      .filter(
                        i =>
                          i.categoria === categoria &&
                          (selectedGirone === availableGironi[0]
                            ? true
                            : i.girone === selectedGirone)
                      )
                      .map(i => i.girone || undefined)
                      .filter((v, i, s) => s.indexOf(v) === i)
                      .sort((a, b) => sortGironi(a, b))
                      .map(girone => (
                        <Grid
                          item
                          xs={12}
                          paddingX={3}
                          key={`${categoria}-${girone}`}
                        >
                          <GironiTable categoria={categoria} girone={girone} />
                        </Grid>
                      ))
                  )}
              </Grid>
            ))}
          <DialogMini
            open={openChangeGirone}
            handleClose={handleCloseChangeGirone}
            title={titles.titleChange}
            textUndo='Annulla'
            textConfirm='Ok'
            triggerFunction={changeGirone}
            dialogGirone={true}
            team={changeGironeIscrizione}
          />
        </>
      )}
    </Grid>
  )
}

const mapStateToProps = state => ({
  infoUtente: state.home.infoUtente,
  affiliazioniLight: state.home.affiliazioniLight,
  dummyUpdate: state.home.dummyUpdate,
  selectedCampionatoGironiProvvisori:
    state.home.selectedCampionatoGironiProvvisori,
  selectedCampionatoGironiPubblicati:
    state.home.selectedCampionatoGironiPubblicati,
  selectedCampionato: state.home.selectedCampionato,
  availableCategorie: state.home.availableCategorie,
  selectedCategoria: state.home.selectedCategoria,
  newTeam: state.gironi.newTeam,
  newGirone: state.gironi.newGirone,
  newPosizione: state.gironi.newPosizione,
  newGironeAdd: state.gironi.newGironeAdd,
  newPosizioneAdd: state.gironi.newPosizioneAdd,
  availableFunctions: state.gironi.availableFunctions,
  selectedFunction: state.gironi.selectedFunction,
  availableGironi: state.home.availableGironi,
  selectedGirone: state.home.selectedGirone,
  selectedTeam: state.home.selectedTeam,
  iscrizioniGironi: state.gironi.iscrizioniGironi,
})

const ConnectedGironi = connect(mapStateToProps)(Gironi)

export default ConnectedGironi
